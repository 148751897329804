import Quill from 'quill';
import Delta from 'quill-delta';

import { getParentElementWithTag } from 'helpers';

const Block = Quill.import('blots/block');
const Break = Quill.import('blots/break');
const Container = Quill.import('blots/container');

const CELL_IDENTITY_KEYS = ['table', 'row', 'cell'];
const CELL_ATTRIBUTES = ['rowspan', 'colspan'];
const CELL_DEFAULT = {
  rowspan: 1,
  colspan: 1
};
const RANGE_LIMIT = 1;

export class TableCellLine extends Block {
  static create(value) {
    const properties = JSON.parse(value);
    const node = super.create(properties);

    CELL_IDENTITY_KEYS.forEach(key => {
      const identity = properties[key + 'Id'];
      node.setAttribute(`data-${key}`, identity);
    });

    CELL_ATTRIBUTES.forEach(attrName => {
      node.setAttribute(`${attrName}`, properties[attrName] || CELL_DEFAULT[attrName]);
    });

    return node;
  }

  optimize(context) {
    const tableId = this.domNode.getAttribute('data-table');
    const rowId = this.domNode.getAttribute('data-row');
    const cellId = this.domNode.getAttribute('data-cell');
    const rowspan = this.domNode.getAttribute('rowspan');
    const colspan = this.domNode.getAttribute('colspan');
    const style = this.domNode.getAttribute('style');

    const idx = indexOf(this.parent.children, this);
    const prevBlot = findItem(this.parent.children, idx - 1);
    if (
      prevBlot?.statics.blotName === 'table-cell' &&
      prevBlot.domNode.getAttribute('data-table') === tableId &&
      prevBlot.domNode.getAttribute('data-row') === rowId &&
      prevBlot.domNode.getAttribute('data-cell') === cellId
    ) {
      prevBlot.appendChild(this);
    } else if (
      this.statics.requiredContainer &&
      !(this.parent instanceof this.statics.requiredContainer)
    ) {
      this.wrap('table-cell', {
        table: tableId,
        row: rowId,
        cell: cellId,
        rowspan,
        colspan,
        style
      });
    }

    super.optimize(context);
  }

  static formats(domNode) {
    return JSON.stringify({
      tableId: domNode.getAttribute('data-table') || undefined,
      rowId: domNode.getAttribute('data-row') || undefined,
      cellId: domNode.getAttribute('data-cell') || undefined,
      rowspan: domNode.getAttribute('rowspan') || 1,
      colspan: domNode.getAttribute('colspan') || 1
    });
  }

  format(attName, value) {
    if (CELL_ATTRIBUTES.indexOf(attName) > -1 && value) {
      this.domNode.setAttribute(attName, value);
    } else if (CELL_IDENTITY_KEYS.indexOf(attName) > -1) {
      this.domNode.setAttribute(`data-${attName}`, value);
    }
  }
}
TableCellLine.blotName = 'table-cell-line';
TableCellLine.className = 'qlbt-cell-line';
TableCellLine.tagName = 'P';

export class InsertedTableCellLine extends TableCellLine {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('style', 'background-color: #DFE3E8;');
    return node;
  }
}

InsertedTableCellLine.blotName = 'inserted-table-cell-line';
InsertedTableCellLine.className = 'qlbt-cell-line';
InsertedTableCellLine.tagName = 'P';

export class DeletedTableCellLine extends TableCellLine {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('style', 'background-color: #FCE6F4;');
    return node;
  }
}

DeletedTableCellLine.blotName = 'deleted-table-cell-line';
DeletedTableCellLine.className = 'qlbt-cell-line';
DeletedTableCellLine.tagName = 'P';

export class TableCell extends Container {
  static create(properties) {
    const node = super.create(properties);

    CELL_IDENTITY_KEYS.forEach(key => {
      const identity = properties[key];
      node.setAttribute(`data-${key}`, identity);
    });

    CELL_ATTRIBUTES.forEach(attrName => {
      node.setAttribute(`${attrName}`, properties[attrName] || CELL_DEFAULT[attrName]);
    });

    if (properties['style']) {
      node.setAttribute('style', properties['style']);
    }
    return node;
  }

  optimize(context) {
    const tableId = this.domNode.getAttribute('data-table');
    const rowId = this.domNode.getAttribute('data-row');

    const idx = indexOf(this.parent.children, this);
    const prevBlot = findItem(this.parent.children, idx - 1);
    if (
      prevBlot?.statics.blotName === 'table-row' &&
      prevBlot.domNode.getAttribute('data-table') === tableId &&
      prevBlot.domNode.getAttribute('data-row') === rowId
    ) {
      prevBlot.appendChild(this);
    } else if (
      this.statics.requiredContainer &&
      !(this.parent instanceof this.statics.requiredContainer)
    ) {
      this.wrap('table-row', {
        table: tableId,
        row: rowId
      });
    }

    super.optimize(context);
  }

  formats() {
    const formats = {};

    if (this.domNode.hasAttribute('data-row')) {
      formats['row'] = this.domNode.getAttribute('data-row');
    }

    if (this.domNode.hasAttribute('data-cell')) {
      formats['cell'] = this.domNode.getAttribute('data-cell');
    }

    if (this.domNode.hasAttribute('rowspan')) {
      formats['rowspan'] = this.domNode.getAttribute('rowspan');
    }

    if (this.domNode.hasAttribute('colspan')) {
      formats['colspan'] = this.domNode.getAttribute('colspan');
    }

    return formats;
  }

  format(attName, value) {
    if (CELL_ATTRIBUTES.indexOf(attName) > -1 && value) {
      this.domNode.setAttribute(attName, value);
      this.children.forEach(child => {
        child.format(attName, value);
      });
    } else if (CELL_IDENTITY_KEYS.indexOf(attName) > -1) {
      this.domNode.setAttribute(`data-${attName}`, value);
      this.children.forEach(child => {
        child.format(attName, value);
      });
    }
  }
}
TableCell.blotName = 'table-cell';
TableCell.tagName = 'TD';
TableCell.scope = 15;

TableCell.allowedChildren = [TableCellLine];
TableCellLine.requiredContainer = TableCell;

export class TableRow extends Container {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('data-table', value.table);
    node.setAttribute('data-row', value.row);
    return node;
  }

  optimize(context) {
    const tableId = this.domNode.getAttribute('data-table');

    const idx = indexOf(this.parent.children, this);
    const prevBlot = findItem(this.parent.children, idx - 1);
    if (
      prevBlot?.statics.blotName === 'table' &&
      prevBlot.domNode.getAttribute('data-table') === tableId
    ) {
      prevBlot.appendChild(this);
    } else if (
      this.statics.requiredContainer &&
      !(this.parent instanceof this.statics.requiredContainer)
    ) {
      this.wrap(this.statics.requiredContainer.blotName, {
        table: tableId
      });
    }

    super.optimize(context);
  }

  formats() {
    return ['row'].reduce((formats, attrName) => {
      if (this.domNode.hasAttribute(`data-${attrName}`)) {
        formats[attrName] = this.domNode.getAttribute(`data-${attrName}`);
      }
      return formats;
    }, {});
  }
}

TableRow.blotName = 'table-row';
TableRow.tagName = 'TR';
TableRow.scope = 15;

TableRow.allowedChildren = [TableCell];
TableCell.requiredContainer = TableRow;

export class TableBody extends Container {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('data-table', value.table);
    return node;
  }

  formats() {
    const formats = {};

    if (this.domNode.hasAttribute('data-table')) {
      formats['table'] = this.domNode.getAttribute('data-table');
    }

    return formats;
  }
}
TableBody.blotName = 'table';
TableBody.tagName = 'TABLE';
TableBody.scope = 15;

TableBody.allowedChildren = [TableRow];
TableRow.requiredContainer = TableBody;

const indexOf = (list, node) => {
  let cur = list.head;
  let index = 0;
  while (cur) {
    if (cur === node) {
      return index;
    }
    index++;
    cur = cur.next;
  }
  return -1;
};

const findItem = (list, index) => {
  let curr = list.head;
  while (index > 0) {
    curr = curr.next;
    index--;
  }

  return curr;
};

export const setTableBindings = editor => {
  // delete character using 'backspace' key
  editor.keyboard.bindings[8].unshift({
    key: 8,
    format: ['table-cell-line'],
    collapsed: true,
    offset: 0,
    handler: () => {
      const range = editor.getSelection();
      const rangeFormat = editor.getFormat(range);
      const newRangeFormat = editor.getFormat({
        index: range.index - 1,
        length: 0
      });
      if (rangeFormat['table-cell-line'] === newRangeFormat['table-cell-line']) {
        editor.deleteText(range.index - 1, 1, 'user');
      }
    }
  });
  // delete character using 'delete' key
  editor.keyboard.bindings[46].unshift({
    key: 46,
    format: ['table-cell-line'],
    collapsed: true,
    suffix: /^$/,
    handler: range => {
      const rangeFormat = editor.getFormat(range);
      const newRangeFormat = editor.getFormat({
        index: range.index + 1,
        length: 0
      });
      if (rangeFormat['table-cell-line'] === newRangeFormat['table-cell-line']) {
        editor.deleteText(range.index, 1, 'user');
      }
    }
  });
  // delete selection using 'backspace' key
  editor.keyboard.bindings[8].unshift({
    key: 8,
    collapsed: false,
    handler: () => {
      const selection = editor.getSelection();
      const operations = editor.getContents(selection).ops;
      const ops = formatDeleteDelta(operations, selection);

      editor.updateContents({ ops: ops }, 'user');
    }
  });
  // delete selection using 'delete' key
  editor.keyboard.bindings[46].unshift({
    key: 46,
    collapsed: false,
    handler: () => {
      const selection = editor.getSelection();
      const operations = editor.getContents(selection).ops;
      const ops = formatDeleteDelta(operations, selection);

      editor.updateContents({ ops: ops }, 'user');
    }
  });
};

const isTableCellLine = attributes => attributes && Object.hasOwn(attributes, 'table-cell-line');

const handleTableCellLineOperation = (operation, ops, deletedLines) => {
  const tableCellLine = operation.attributes['table-cell-line'];
  const insertLength = operation.insert.length;

  // Check if a line from the same cell is already deleted
  // If it is, the current line can be deleted completely (including the newline character)
  // Else, keep that operation - newline character (every table cell should contain at least one line, otherwise it will be removed)
  if (deletedLines.includes(tableCellLine)) {
    setDeleteOperation(ops, insertLength > 0, insertLength);
  } else {
    ops.push({
      retain: insertLength
    });

    // Add deleted line to the array
    deletedLines.push(tableCellLine);
  }
};

// A method for formatting delta for delete operation
// Skip deleting operations that represent table cell lines, to keep the table's shape
// Skip deleting operation which represents the last paragraph before the table, so the paragraph had not become part of the table
const formatDeleteDelta = (operations, selection) => {
  const ops = [];
  const deletedLines = [];

  // Add retain operation only in case when the selection index is greater than 0, otherwise the quill will throw an error.
  selection.index > 0 && ops.push({ retain: selection.index });

  for (let i = 0; i < operations.length; i++) {
    const operation = operations[i];
    const insert = operation.insert;
    const insertLength = insert.length;

    // Check if the deleted element is a table cell line
    if (isTableCellLine(operation.attributes)) {
      handleTableCellLineOperation(operation, ops, deletedLines);
      continue;
    }

    // Check if the operation contains newline character
    if (typeof operation.insert === 'string' && operation.insert?.includes('\n')) {
      const nextNewline = operations.find((op, index) => index > i && op.insert.includes('\n'));

      // Check if the next operation is with the new line character and contains table-cell-attribute
      // If it is, the current operation represents a paragraph(s) before a table
      // The last newline character from the operation shouldn't be deleted, otherwise, the last paragraph will become a part of the table
      if (nextNewline?.attributes && Object.hasOwn(nextNewline.attributes, 'table-cell-line')) {
        const lastNewlineCharacter = insert.lastIndexOf('\n');

        // Delete everything before the last newline character
        const partBeforeLastNewline = insert.slice(0, lastNewlineCharacter).length;
        setDeleteOperation(ops, partBeforeLastNewline > 0, partBeforeLastNewline);

        // Keep the last newline character
        ops.push({
          retain: 1
        });

        // Delete everything after the last newline character
        const partAfterLastNewline = insert.slice(lastNewlineCharacter + 1).length;
        setDeleteOperation(ops, partAfterLastNewline > 0, partAfterLastNewline);

        continue;
      }
    }

    setDeleteOperation(ops, insertLength > 0, insertLength);
  }

  return ops;
};

const setDeleteOperation = (ops, condition, value) => {
  if (condition) {
    ops.push({
      delete: value
    });
  }
};

const getTableIdForInsert = quillEditor => {
  const inserts = quillEditor.editor.delta.ops;
  const tableIds = [];
  for (const insert of inserts) {
    if (insert.attributes?.['table-cell-line']) {
      const attObject = JSON.parse(insert.attributes['table-cell-line']);
      if (attObject?.['tableId']) {
        const tableId = attObject['tableId'];
        tableIds.push(+tableId);
      }
    }
  }

  if (tableIds.length > 0) {
    return Math.max(...tableIds) + 1;
  }

  return 1;
};

export const insertTable = (quillEditor, quillSelection, rowNumber, columnNumber) => {
  const newTableId = getTableIdForInsert(quillEditor);
  const ops = [];
  for (let row = 1; row <= rowNumber; row++) {
    for (let column = 1; column <= columnNumber; column++) {
      const tableAtt = JSON.stringify({
        tableId: newTableId,
        rowId: row,
        cellId: column,
        rowspan: 1,
        colspan: 1
      });
      const insert = {
        attributes: {
          'table-cell-line': tableAtt
        },
        insert: '\n'
      };
      ops.push(insert);
    }
  }

  const delta = new Delta().retain(quillSelection.index).delete(quillSelection.length);
  ops.forEach(op => delta.push(op));
  quillEditor.updateContents(delta, 'user');
};

//Method which determine position of text element (table cell) in the quill editor.
//Position is defined with folowing values:
// x - distance of cell left edge from the left edge of the editor,
// x1 - distance of cell right edge from the left edge of the editor,
// y - distance of cell top edge from the top edge of the editor,
// y1 - distance of cell bottom edge from the top edge of the editor.
const getRelativeRect = (targetRect, container) => {
  const containerRect = container.root.getBoundingClientRect();

  return {
    x: targetRect.x - containerRect.x,
    y: targetRect.y - containerRect.y,
    x1: targetRect.x - containerRect.x + targetRect.width,
    y1: targetRect.y - containerRect.y + targetRect.height,
    width: targetRect.width,
    height: targetRect.height
  };
};

const generateRowId = tableCells => {
  const usedRowIds = [];

  tableCells.forEach(cell => {
    const rowId = cell.formats().row;
    usedRowIds.push(+rowId);
  });

  return Math.max(...usedRowIds) + 1;
};

const generateCellId = tableCells => {
  const usedCellIds = [];

  tableCells.forEach(cell => {
    const cellId = cell.formats().cell;
    usedCellIds.push(+cellId);
  });

  return Math.max(...usedCellIds) + 1;
};

export const insertRow = (addBelow, editor) => {
  const selectionRange = editor.selection.getNativeRange();
  if (!selectionRange) {
    return;
  }
  const selectedNode = selectionRange.start.node;
  const currentCell = getParentElementWithTag(selectedNode, 'TD');
  if (!currentCell) {
    return;
  }

  const table = getParentElementWithTag(selectedNode, 'TABLE');
  const tableContainer = Quill.find(table);
  const tableCells = tableContainer.descendants(TableCell);
  const tableId = tableContainer.formats().table;

  const rowId = generateRowId(tableCells);
  const newRow = new TableRow(
    TableRow.create({
      table: tableId,
      row: rowId
    })
  );

  const selectedCellRect = getRelativeRect(currentCell.getBoundingClientRect(), editor);

  const affectedCells = getInsertAndModifiedCellsForRowInsert(
    tableCells,
    selectedCellRect,
    editor,
    addBelow
  );
  const cellsToAdd = affectedCells.cellsToAdd;
  const modifiedCells = affectedCells.modifiedCells;

  let cellId = 1;

  cellsToAdd.forEach(cell => {
    const cellFormat = cell.formats();

    const tableCell = new TableCell(
      TableCell.create({
        row: rowId,
        cell: cellId,
        table: tableId,
        rowspan: 1,
        colspan: cellFormat.colspan
      })
    );

    const tableCellLineObj = {
      tableId: tableId,
      rowId: rowId,
      cellId: cellId,
      colspan: cellFormat.colspan
    };
    const tableCellLine = new TableCellLine(TableCellLine.create(JSON.stringify(tableCellLineObj)));
    const emptyLine = new Break(Break.create());

    tableCellLine.appendChild(emptyLine);
    tableCell.appendChild(tableCellLine);
    newRow.appendChild(tableCell);

    cellId++;
  });

  modifiedCells.forEach(cell => {
    const cellRowspan = +cell.formats().rowspan;
    cell.format('rowspan', cellRowspan + 1);
  });

  //Determing the reference row used for inserting the new row relative to that one.
  //New row will be inserted before the reference row.
  const refRow = tableContainer.descendants(TableRow).find(row => {
    const rowRect = getRelativeRect(row.domNode.getBoundingClientRect(), editor);
    if (addBelow) {
      return Math.abs(rowRect.y - selectedCellRect.y - selectedCellRect.height) < RANGE_LIMIT;
    } else {
      return Math.abs(rowRect.y - selectedCellRect.y) < RANGE_LIMIT;
    }
  });

  tableContainer.insertBefore(newRow, refRow);
  editor.update();
};

//Method used to determine which cells need to be inserted and which cells need to be modified, for
//purpose of inserting new row.
//Cells which will be inserted in the new row will be indentical to the cells of the current row, except
//for the case when current row contains cell(s) which spans over the multiple rows (else if conditions).
//Those cells are cells which needs to be modified, that is, to increase the rowspan attribute by one.
const getInsertAndModifiedCellsForRowInsert = (tableCells, selectedCellRect, editor, addBelow) => {
  const cellsToAdd = [];
  const modifiedCells = [];

  tableCells.forEach(cell => {
    const cellRect = getRelativeRect(cell.domNode.getBoundingClientRect(), editor);

    if (addBelow) {
      if (Math.abs(cellRect.y1 - selectedCellRect.y1) < RANGE_LIMIT) {
        cellsToAdd.push(cell);
      } else if (
        selectedCellRect.y1 - cellRect.y > RANGE_LIMIT &&
        selectedCellRect.y1 - cellRect.y1 < -RANGE_LIMIT
      ) {
        modifiedCells.push(cell);
      }
    } else if (Math.abs(cellRect.y - selectedCellRect.y) < RANGE_LIMIT) {
      cellsToAdd.push(cell);
    } else if (
      selectedCellRect.y - cellRect.y > RANGE_LIMIT &&
      selectedCellRect.y - cellRect.y1 < -RANGE_LIMIT
    ) {
      modifiedCells.push(cell);
    }
  });

  return {
    cellsToAdd,
    modifiedCells
  };
};

export const insertColumn = (insertRight, editor) => {
  const selectionRange = editor.selection.getNativeRange();
  if (!selectionRange) {
    return;
  }

  const selectedNode = editor.selection.getNativeRange().start.node;
  const selectedCell = getParentElementWithTag(selectedNode, 'TD');
  if (!selectedCell) {
    return;
  }

  const table = getParentElementWithTag(selectedNode, 'TABLE');
  const tableContainer = Quill.find(table);
  const tableId = tableContainer.formats().table;
  const tableCells = tableContainer.descendants(TableCell);
  const selectedCellRect = getRelativeRect(selectedCell.getBoundingClientRect(), editor);

  const affectedCells = getInsertAndModifiedCellsForColumnInsert(
    tableCells,
    selectedCellRect,
    editor,
    insertRight
  );
  const addAsideCells = affectedCells.addAsideCells;
  const modifiedCells = affectedCells.modifiedCells;

  const cellId = generateCellId(tableCells);
  addAsideCells.forEach(cell => {
    const refCell = insertRight ? cell.next : cell;
    const cellRow = cell.parent;
    const rowId = cellRow.formats().row;
    const cellFormat = cell.formats();

    const tableCell = new TableCell(
      TableCell.create({
        row: rowId,
        cell: cellId,
        table: tableId,
        rowspan: cellFormat.rowspan,
        colspan: 1
      })
    );

    const tableCellLineObj = {
      tableId: tableId,
      rowId: rowId,
      cellId: cellId,
      rowspan: cellFormat.rowspan,
      colspan: 1
    };
    const tableCellLine = new TableCellLine(TableCellLine.create(JSON.stringify(tableCellLineObj)));

    const emptyLine = new Break(Break.create());

    tableCellLine.appendChild(emptyLine);
    tableCell.appendChild(tableCellLine);

    if (refCell) {
      cellRow.insertBefore(tableCell, refCell);
    } else {
      cellRow.appendChild(tableCell);
    }
  });

  modifiedCells.forEach(cell => {
    const cellColspan = +cell.formats().colspan;
    cell.format('colspan', cellColspan + 1);
  });

  editor.update();
};

//Method used to determine which cells need to be inserted and which cells need to be modified for purpose
//of inserting new column.
//Cells which will be inserted in the new column will be identical to the cells of the current column, except
//for the case when current column contains cell(s) which spans over the multiple column (else if conditions).
//Those cells are cells which needs to be modified, that is, to increase the colspan attribute by one.
const getInsertAndModifiedCellsForColumnInsert = (
  tableCells,
  selectedCellRect,
  editor,
  insertRight
) => {
  const addAsideCells = [];
  const modifiedCells = [];

  tableCells.forEach(cell => {
    const cellRect = getRelativeRect(cell.domNode.getBoundingClientRect(), editor);

    if (insertRight) {
      if (Math.abs(cellRect.x1 - selectedCellRect.x1) < RANGE_LIMIT) {
        addAsideCells.push(cell);
      } else if (
        selectedCellRect.x1 - cellRect.x > RANGE_LIMIT &&
        selectedCellRect.x1 - cellRect.x1 < -RANGE_LIMIT
      ) {
        modifiedCells.push(cell);
      }
    } else if (Math.abs(cellRect.x - selectedCellRect.x) < RANGE_LIMIT) {
      addAsideCells.push(cell);
    } else if (
      selectedCellRect.x - cellRect.x > RANGE_LIMIT &&
      selectedCellRect.x - cellRect.x1 < -RANGE_LIMIT
    ) {
      modifiedCells.push(cell);
    }
  });

  return {
    addAsideCells,
    modifiedCells
  };
};

export const deleteRow = editor => {
  const selectionRange = editor.selection.getNativeRange();
  if (!selectionRange) {
    return;
  }

  const selectedNode = selectionRange.start.node;
  const selectedCell = getParentElementWithTag(selectedNode, 'TD');
  if (!selectedCell) {
    return;
  }

  const table = getParentElementWithTag(selectedNode, 'TABLE');
  const tableContainer = Quill.find(table);
  const tableCells = tableContainer.descendants(TableCell);
  const tableRows = tableContainer.descendants(TableRow);
  const selectedCellRect = getRelativeRect(selectedCell.getBoundingClientRect(), editor);
  const selectedCellQuill = Quill.find(selectedCell);

  const rowsToRemove = tableRows.filter(row => {
    const rowRect = getRelativeRect(row.domNode.getBoundingClientRect(), editor);
    return (
      selectedCellRect.y1 + RANGE_LIMIT > rowRect.y1 && selectedCellRect.y - RANGE_LIMIT < rowRect.y
    );
  });

  const cellsToRemove = [];
  const modifiedCells = [];
  const moveCells = [];
  //CellsToRemove: represent the cells which needs to be removed (belongs to the selected row). If the selected cell
  //(cell where the cursor is positioned) has span over the multiple row, cells from those rows will also be deleted.
  //ModifiedCells: if the selected row contains cell(s) with span over the multiple rows, but their start is not in the current row,
  //they should not be deleted, only the rowspan attribute should be decreased by the rowspan of selected cell.
  //MoveCells: if the selected row contains cell(s) with the span over the multiple rows, and their start is in the selected row,
  //they should be transferred to the first row below.
  tableCells.forEach(cell => {
    const cellRect = getRelativeRect(cell.domNode.getBoundingClientRect(), editor);
    if (
      selectedCellRect.y1 + RANGE_LIMIT > cellRect.y1 &&
      selectedCellRect.y - RANGE_LIMIT < cellRect.y
    ) {
      cellsToRemove.push(cell);
    } else if (
      selectedCellRect.y + RANGE_LIMIT > cellRect.y &&
      selectedCellRect.y1 - RANGE_LIMIT < cellRect.y1
    ) {
      modifiedCells.push(cell);

      if (Math.abs(cellRect.y - selectedCellRect.y) < RANGE_LIMIT) {
        moveCells.push(cell);
      }
    }
  });

  if (cellsToRemove.length === tableCells.length) {
    tableContainer.remove();
    editor.update();
    return;
  }

  //For each moveCell find the reference cell in the next row, before which should be inserted the moved cell.
  moveCells.forEach(cell => {
    const cellRect = getRelativeRect(cell.domNode.getBoundingClientRect(), editor);
    const nextRow = tableRows.find(row => {
      const rowRect = getRelativeRect(row.domNode.getBoundingClientRect(), editor);
      return Math.abs(rowRect.y - selectedCellRect.y1) < RANGE_LIMIT;
    });

    const cellsInNextRow = nextRow.children;
    const refCell = cellsInNextRow.reduce((ref, compareCell) => {
      const compareRect = getRelativeRect(compareCell.domNode.getBoundingClientRect(), editor);
      if (Math.abs(cellRect.x1 - compareRect.x) < RANGE_LIMIT) {
        ref = compareCell;
      }
      return ref;
    }, null);
    nextRow.insertBefore(cell, refCell);
    const cellId = generateCellId(tableCells);
    cell.format('row', +nextRow.formats().row);
    cell.format('cell', cellId);
  });

  cellsToRemove.forEach(cell => {
    cell.remove();
  });

  const deleteRowspan = +selectedCellQuill.formats().rowspan;
  modifiedCells.forEach(cell => {
    const cellRowspan = +cell.formats().rowspan;
    cell.format('rowspan', cellRowspan - deleteRowspan);
  });

  rowsToRemove.forEach(row => {
    row.remove();
  });

  editor.update();
};

export const deleteColumn = editor => {
  const selectionRange = editor.selection.getNativeRange();
  if (!selectionRange) {
    return;
  }
  const selectedNode = selectionRange.start.node;
  const selectedCell = getParentElementWithTag(selectedNode, 'TD');
  if (!selectedCell) {
    return;
  }

  const table = getParentElementWithTag(selectedNode, 'TABLE');
  const tableContainer = Quill.find(table);
  const tableCells = tableContainer.descendants(TableCell);
  const selectedCellRect = getRelativeRect(selectedCell.getBoundingClientRect(), editor);
  const selectedCellQuill = Quill.find(selectedCell);

  const cellsToRemove = [];
  const modifiedCells = [];

  //CellsToRemove: represents the cells which need to be removed (belong to the selected column). If the selected
  //cell (cell where the cursor is positioned) has span over the multiple columns, cells from those columns will also be
  //deleted.
  //ModifiedCells: if the selected column contains cell(s) with greater column span than selected cell,
  //those cells should not be deleted, but their colspan attribute should be decreased by colspan attribute of selected cell.
  tableCells.forEach(cell => {
    const cellRect = getRelativeRect(cell.domNode.getBoundingClientRect(), editor);
    if (
      cellRect.x + RANGE_LIMIT > selectedCellRect.x &&
      cellRect.x1 - RANGE_LIMIT < selectedCellRect.x1
    ) {
      cellsToRemove.push(cell);
    } else if (
      cellRect.x < selectedCellRect.x + RANGE_LIMIT &&
      cellRect.x1 > selectedCellRect.x1 - RANGE_LIMIT
    ) {
      modifiedCells.push(cell);
    }
  });

  if (cellsToRemove.length === tableCells.length) {
    tableContainer.remove();
    editor.update();
    return;
  }

  cellsToRemove.forEach(cell => {
    cell.remove();
  });

  const deleteColspan = +selectedCellQuill.formats().colspan;
  modifiedCells.forEach(cell => {
    const cellColspan = +cell.formats().colspan;
    cell.format('colspan', cellColspan - deleteColspan);
  });

  editor.update();
};

export const deleteTable = editor => {
  const selectionRange = editor.selection.getNativeRange();
  if (!selectionRange) {
    return;
  }

  const selectedNode = selectionRange.start.node;
  const table = getParentElementWithTag(selectedNode, 'TABLE');
  if (!table) {
    return;
  }

  const tableContainer = Quill.find(table);
  tableContainer.remove();
  editor.update();
};

Quill.register(TableCell, true);
Quill.register(TableCellLine, true);
Quill.register(TableRow, true);
Quill.register(TableBody, true);
Quill.register(InsertedTableCellLine, true);
Quill.register(DeletedTableCellLine, true);
