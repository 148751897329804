import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import arrowIosBackwardFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Icon } from '@iconify/react';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomCheckbox, LightTooltip, Scrollbar, SearchWrapper } from 'components';
import {
  checkPermission,
  contentTypes,
  editsStatus,
  filterDropdownOptions,
  filterSearchedProperties,
  filterOptions,
  handleSearch,
  intelligibilityLevelDictionary,
  permissions,
  sourcePage
} from 'helpers';
import { UserContext } from 'providers';
import { userService, teamService } from 'services';

import { useStyles } from './FilterStyling.css';

export const FilterProperty = ({
  selectedCategory,
  setSelectedCategory,
  selectedFilterOptions,
  setSelectedFilterOptions,
  subFilterValue,
  setSelectedSubFilterValue,
  handleClose,
  page
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);

  const [dropdownItems, setDropdownItems] = useState([]);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const isUsersCategorySelected = selectedCategory.name === filterDropdownOptions.USERS;
  const isTeamsCategorySelected = selectedCategory.name === filterDropdownOptions.TEAMS;
  const isIntelligibilityCategorySelected =
    selectedCategory.name === filterDropdownOptions.INTELLIGIBILITY;
  const isEditsCategorySelected = selectedCategory.name === filterDropdownOptions.EDITS;
  const isTypesCategorySelected = selectedCategory.name === filterDropdownOptions.TYPES;

  const selectedFiltersBySubFilter = selectedFilterOptions[selectedCategory.name][subFilterValue];

  const { labels, user } = useContext(UserContext);

  const userHasReadPermission = checkPermission(user.permissions, permissions.USERS_READ);

  const queryKey = page === sourcePage.DOCUMENTS ? 'getDocumentFilterUsers' : 'getAccessibleUsers';

  const { data: usersData } = useQuery([queryKey], () => userService[queryKey](), {
    enabled: page === sourcePage.DOCUMENTS ? userHasReadPermission : isUsersCategorySelected
  });

  const { data: teamsData } = useQuery('teams', () => teamService.getTeams(), {
    enabled: isTeamsCategorySelected
  });

  const getInitialState = prop => {
    return Object.entries(prop).map(([key, value]) => {
      const transformedKey = isEditsCategorySelected ? key.toLowerCase() : key;
      return {
        name: transformedKey,
        id: value
      };
    });
  };

  const readabilityLevels = getInitialState(intelligibilityLevelDictionary);
  const edits = getInitialState(editsStatus);
  const types = getInitialState(contentTypes);

  const searchStyles = {
    width: '260px',
    height: '40px',
    borderRadius: '8px',
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${theme.palette.gray.light}`,
    marginLeft: '10px',
    marginBottom: '10px'
  };

  const isEqualOrNotEqualSelected = [filterOptions.EQUALS, filterOptions.DOES_NOT_EQUAL].includes(
    subFilterValue
  );

  const isContainsOrNotContainSelected = [
    filterOptions.CONTAINS,
    filterOptions.DOES_NOT_CONTAIN
  ].includes(subFilterValue);

  useEffect(() => {
    let dropdownProperties = null;
    switch (selectedCategory.name) {
      case filterDropdownOptions.USERS:
        dropdownProperties = usersData?.map(item => {
          return { ...item, name: item.displayName, id: item.masterUserIds };
        });
        break;
      case filterDropdownOptions.TEAMS:
        dropdownProperties = teamsData;
        break;
      case filterDropdownOptions.LABELS:
        dropdownProperties = labels;
        break;
      case filterDropdownOptions.INTELLIGIBILITY:
        dropdownProperties = readabilityLevels;
        break;
      case filterDropdownOptions.EDITS:
        dropdownProperties = edits;
        break;
      case filterDropdownOptions.TYPES:
        dropdownProperties = types;
    }
    setDropdownItems(filterSearchedProperties(dropdownProperties, search));
  }, [labels, teamsData, usersData, search, subFilterValue]);

  const handleItemSelection = item => {
    let updatedList = [...selectedFiltersBySubFilter];
    const itemIndex = selectionOfUsersHandler(updatedList, item);

    if (itemIndex === -1) {
      updatedList = isContainsOrNotContainSelected ? [item] : [...selectedFiltersBySubFilter, item];
    } else {
      updatedList.splice(itemIndex, 1);
    }

    const updatedSelectedFilterOptions = JSON.parse(JSON.stringify(selectedFilterOptions));
    updatedSelectedFilterOptions[selectedCategory.name][subFilterValue] = updatedList;
    setSelectedFilterOptions(updatedSelectedFilterOptions);
  };

  const onEnterPressed = e => {
    const code = e.keyCode || e.which;
    const updatedSelectedFilterOptions = JSON.parse(JSON.stringify(selectedFilterOptions));
    if (code === 13 && search !== '') {
      const newValue = isEqualOrNotEqualSelected ? dropdownItems : [{ name: search }];
      updatedSelectedFilterOptions[selectedCategory.name][subFilterValue] = newValue;
      setSelectedFilterOptions(updatedSelectedFilterOptions);
      isContainsOrNotContainSelected && handleClose();
    }
  };

  const handleChange = e => {
    setSelectedSubFilterValue(prevState => ({
      ...prevState,
      [selectedCategory.name]: e.target.value
    }));
  };

  const menuItemOnClickHandler = item => {
    handleItemSelection(item);
    isContainsOrNotContainSelected && handleClose();
  };

  const selectionOfUsersHandler = (selectedFiltersList, item) => {
    return selectedFiltersList?.findIndex(i => {
      if (isUsersCategorySelected) {
        return i.id.every((val, index) => val === item.id[index]);
      } else {
        return i.id === item.id;
      }
    });
  };

  const formatIntelligibilityLevel = word => {
    const number = word.match(/\d/)[0];
    return word.replace(`level_${number}`, `Level${number}`);
  };

  return (
    <>
      <Stack className={classes.filterProperty} onClick={e => e.stopPropagation()}>
        <Box
          data-cy='backToAllFilterCategories'
          component={Icon}
          icon={arrowIosBackwardFill}
          sx={{ width: 16, height: 16, ml: 1, cursor: 'pointer' }}
          onClick={() => {
            setSelectedCategory(null);
          }}
        />
        <Typography
          data-testid='selectedCategoryName'
          sx={{
            fontSize: theme.typography.pxToRem(16),
            fontFamily: theme.typography.fontFamilyPrimaryRegular
          }}>
          {selectedCategory.name === filterDropdownOptions.INTELLIGIBILITY
            ? t('currentScore')
            : t(selectedCategory.name)}
        </Typography>
      </Stack>
      <Stack>
        {!isIntelligibilityCategorySelected &&
          !isTypesCategorySelected &&
          !isEditsCategorySelected && (
            <Select
              data-testid='selectField'
              data-cy={`${selectedCategory.name}${'SubfilterSelect'}`}
              value={subFilterValue}
              className={classes.dropdownStyle}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: '5px',
                    border: '1px solid ' + theme.palette.gray.light,
                    boxShadow: 'none'
                  }
                }
              }}
              onChange={handleChange}>
              {selectedCategory.subFilterValues.map(item => (
                <MenuItem
                  disableRipple
                  data-cy={`${selectedCategory.name}_${item}Option`}
                  key={item}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  value={item}
                  className={classes.filterOptions}>
                  {t(item)}
                </MenuItem>
              ))}
            </Select>
          )}
        {selectedCategory.isSearchFieldEnabled && (
          <SearchWrapper
            dataCy='labelsFilterSearch'
            search={search}
            searchValue={searchValue}
            setSearch={setSearch}
            setSearchValue={setSearchValue}
            handleSearch={e => {
              handleSearch(e, setSearch);
              e.stopPropagation();
            }}
            onKeyDownHandler={onEnterPressed}
            component='customOutlinedInput'
            style={searchStyles}
            placeholder='Search'
          />
        )}
        <Scrollbar sx={{ maxHeight: '300px', minHeight: '50px' }}>
          {dropdownItems?.map(item => (
            <MenuItem
              disableRipple
              data-cy={`${selectedCategory.name}_${subFilterValue}_${item.id}`}
              key={item.id}
              onClick={e => {
                e.stopPropagation();
                menuItemOnClickHandler(item);
              }}
              className={classes.menuItems}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '200px' }}>
                {isIntelligibilityCategorySelected && (
                  <CircleIcon
                    sx={{
                      width: 8,
                      height: 8,
                      color: theme.palette[formatIntelligibilityLevel(item.name)].dark
                    }}
                  />
                )}
                <LightTooltip
                  title={
                    isIntelligibilityCategorySelected || isEditsCategorySelected
                      ? t(item.name)
                      : item.name
                  }
                  PopperProps={{
                    disablePortal: dropdownItems.length > 1,
                    style: { maxWidth: '250px', whiteSpace: 'pre-line', overflowWrap: 'break-word' }
                  }}>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontFamily: theme.typography.fontFamilyPrimaryRegular,
                      fontSize: theme.typography.pxToRem(16),
                      pt: '2px'
                    }}
                    data-testid={`menuItem-${item.id}`}>
                    {isIntelligibilityCategorySelected || isEditsCategorySelected
                      ? t(item.name)
                      : item.name}
                  </Typography>
                </LightTooltip>
              </div>
              {!isContainsOrNotContainSelected && (
                <div style={{ marginRight: '-10px' }}>
                  <CustomCheckbox
                    disableRipple
                    checked={selectionOfUsersHandler(selectedFiltersBySubFilter, item) >= 0}
                  />
                </div>
              )}
            </MenuItem>
          ))}
        </Scrollbar>
      </Stack>
    </>
  );
};

FilterProperty.propTypes = {
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  selectedFilterOptions: PropTypes.object,
  setSelectedFilterOptions: PropTypes.func,
  subFilterValue: PropTypes.string,
  setSelectedSubFilterValue: PropTypes.func,
  handleClose: PropTypes.func,
  page: PropTypes.number
};
