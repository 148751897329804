import React from 'react';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { improvementPercentage } from 'helpers';
export const CircularProgressDiagram = ({ intelligibilityData }) => {
  const theme = useTheme();

  const improvementColor =
    intelligibilityData.intelligibilityImprovements < 0
      ? theme.palette.error.main
      : theme.palette.active.dark;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}>
      <CircularProgress
        variant='determinate'
        sx={{ color: theme.palette.gray.lighter }}
        size={90}
        thickness={6}
        value={100}
      />
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          mt: !!intelligibilityData.intelligibilityImprovements && '5px'
        }}>
        <Typography
          variant='h4'
          sx={{
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamilySecondaryMedium,
            lineHeight: 0.75
          }}>
          {intelligibilityData.score}
        </Typography>
        {!!intelligibilityData.intelligibilityImprovements && (
          <Stack
            direction='row'
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              sx={{
                color: improvementColor,
                fontFamily: theme.typography.fontFamilySecondaryMedium
              }}>
              {improvementPercentage(intelligibilityData.intelligibilityImprovements)}%
            </Typography>
          </Stack>
        )}
      </Stack>
      <CircularProgress
        variant='determinate'
        thickness={6}
        size={90}
        sx={{
          color:
            theme.palette[`intelligibility_Level${intelligibilityData.intelligibilityLevel}`].dark,
          position: 'absolute',
          left: 0
        }}
        value={intelligibilityData.score}
      />
    </Box>
  );
};

CircularProgressDiagram.propTypes = {
  intelligibilityData: PropTypes.object
};
