import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Tenant`;

const getBillingInfo = async () => {
  const res = await axios.get(`${baseUrl}/billing_info`);
  return res.data;
};

const getFeatureFlags = async () => {
  const res = await axios.get(`${baseUrl}/feature_flags`);
  return res.data;
};

const requestBilling = async data => {
  const url = `${baseUrl}/request_billing`;
  const res = await axios.post(url, data, {
    headers: {
      'content-type': 'application/json-patch+json'
    }
  });
  return res.data;
};

export const tenantService = {
  getBillingInfo,
  getFeatureFlags,
  requestBilling
};
