// Formats objects for setting up the Quill editor
export const formats = [
  'background',
  'bold',
  'italic',
  'underline',
  'heading',
  'highlight',
  'highlightEdit',
  'comment',
  'highlightComment',
  'formats/word_suggestion',
  'word_suggestion',
  'formats/sentence_suggestion',
  'sentence_suggestion',
  'formats/paragraph_suggestion',
  'paragraph_suggestion',
  'complex_sentence_simplification',
  'complex_concept_simplification',
  'complex_word_simplification',
  'long_sentence_simplification',
  'writing_style_simplification',
  'formats/no_complexity',
  'no_complexity',
  'formats/low_priority',
  'low_priority',
  'formats/medium_priority',
  'medium_priority',
  'formats/high_priority',
  'high_priority',
  'align',
  'list',
  'indent',
  'link',
  'formats/delete',
  'deletedText',
  'formats/insertedText',
  'insertedText',
  'formats/retainedText',
  'retainedText',
  'listItem',
  'table-cell-line',
  'inserted-table-cell-line',
  'deleted-table-cell-line',
  'table-cell',
  'table-row',
  'table'
];
