import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const CommentsCounter = ({
  count,
  isCommentCreator = true,
  isSelected,
  isCommentConsumer,
  isResolved
}) => {
  const theme = useTheme();

  const switchCommentTagColor = () => {
    let backgroundColor;
    let textColor;
    let stroke;
    if (isCommentCreator) {
      backgroundColor = isSelected ? theme.palette.gray.light : theme.palette.gray.white;
      textColor = theme.palette.primary.main;
      stroke = theme.palette.gray.main;
    } else if (isCommentConsumer) {
      backgroundColor = isResolved ? theme.palette.gray.light : theme.palette.primary.main;
      textColor = isResolved ? theme.palette.primary.main : theme.palette.gray.white;
      stroke = isResolved ? theme.palette.primary.main : theme.palette.gray.light;
    }
    return {
      backgroundColor: backgroundColor,
      textColor: textColor,
      stroke: stroke
    };
  };

  const commentsStyling = switchCommentTagColor();

  return (
    count > 0 && (
      <div
        style={{
          width: '25px',
          height: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${commentsStyling.stroke}`,
          borderRadius: '50% 50% 50% 10%',
          backgroundColor: commentsStyling.backgroundColor
        }}>
        <p
          style={{
            lineHeight: '5px',
            paddingRight: '2px',
            color: commentsStyling.textColor,
            fontFamily: theme.typography.fontFamilyPrimaryMedium,
            fontSize: '11px'
          }}>
          +{count}
        </p>
      </div>
    )
  );
};

CommentsCounter.propTypes = {
  count: PropTypes.number,
  isResolved: PropTypes.bool,
  isCommentConsumer: PropTypes.bool,
  isCommentCreator: PropTypes.bool,
  isSelected: PropTypes.bool
};
