import React from 'react';

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const TextFieldWrapper = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.gray.white,
    zIndex: 100,
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    '& fieldset': {
      borderColor: theme.palette.gray.light
    }
  },
  '& .MuiOutlinedInput-root.Mui-error > *': {
    border: '2px solid',
    borderRadius: '8px',
    borderColor: theme.palette.error.main
  },
  '& .MuiOutlinedInput-input': {
    border: 'none !important',
    fontFamily: theme.typography.fontFamilyPrimaryMedium
  },
  '& .MuiFormHelperText-root': {
    height: 'fit-content',
    top: '-19px',
    color: theme.palette.quaternary.main + '!important',
    backgroundColor: theme.palette.quaternary[20],
    margin: '0px',
    padding: '19px 14px 3px 14px',
    border: '1px solid',
    borderRadius: '8px',
    borderColor: theme.palette.quaternary.main,
    position: 'relative',
    fontFamily: theme.typography.fontFamilyPrimaryMedium
  },
  '& .MuiInputAdornment-root': {
    border: 'none !important'
  }
}));

export const TextFieldStyle = ({ dataCy, ...props }) => {
  return <TextFieldWrapper {...props} inputProps={{ 'data-cy': dataCy }} />;
};

TextFieldStyle.propTypes = {
  dataCy: PropTypes.string
};
