import ReactQuill from 'react-quill';

const Parchment = ReactQuill.Quill.import('parchment');
//class for adding style attribute to the selection for new comment
export class HighlightCommentAttribute extends Parchment.Attributor.Style {
  constructor() {
    super('highlightComment', 'background-color', {
      scope: Parchment.Scope.INLINE
    });
  }

  value(node) {
    return super.value(node);
  }
}

ReactQuill.Quill.register('formats/highlightComment', new HighlightCommentAttribute());
