import { makeStyles } from '@mui/styles';

import { displayBorder } from 'helpers';

export const useStyles = makeStyles({
  separator: props => ({
    height: '24px',
    width: '1px',
    paddingLeft: '10px',
    paddingRight: '10px',
    [props.breakpoints.down(displayBorder.extraLargeModified)]: {
      padding: '3px 0px'
    }
  }),
  toolbarContainer: {
    backgroundColor: 'transparent',
    '& div': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 'none'
    },
    '& > div:before, & > div:after': {
      content: 'none'
    }
  },
  toolbar: props => ({
    borderRadius: '22px',
    backgroundColor: props.palette.primary.main,
    color: props.palette.grey[0],
    padding: '4px 8px',
    width: 'max-content',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      width: '350px'
    }
  }),
  toolbarPushedRight: {
    transform: 'translate(37%)'
  },
  toolbarPushedRightAndDown: props => ({
    transform: 'translateX(37%) translateY(160%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(37%) translateY(135%)'
    }
  }),
  toolbarPushedRightAndDownIfHeading: props => ({
    transform: 'translateX(37%) translateY(215%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(37%) translateY(170%)'
    }
  }),
  toolbarPushedRightForMoreRaws: {
    transform: 'translate(15%)'
  },
  toolbarPushedRightForMoreRawsAndDown: props => ({
    transform: 'translateX(15%) translateY(160%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(15%) translateY(135%)'
    }
  }),
  toolbarPushedRightForMoreRawsAndDownIfHeading: props => ({
    transform: 'translateX(15%) translateY(215%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(15%) translateY(170%)'
    }
  }),
  toolbarPushedRight20: {
    transform: 'translate(20%)'
  },
  toolbarPushedRight20AndDown: props => ({
    transform: 'translateX(20%) translateY(160%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(20%) translateY(135%)'
    }
  }),
  toolbarPushedRight20AndDownIfHeading: props => ({
    transform: 'translateX(20%) translateY(215%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(20%) translateY(170%)'
    }
  }),
  toolbarPushedLeft: {
    transform: 'translate(-42%)'
  },
  toolbarPushedLeftAndDown: props => ({
    transform: 'translateX(-42%) translateY(160%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(-42%) translateY(135%)'
    }
  }),
  toolbarPushedLeftAndDownIfHeading: props => ({
    transform: 'translateX(-42%) translateY(215%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(-42%) translateY(170%)'
    }
  }),
  toolbarPushedLeft15: {
    transform: 'translate(-15%)'
  },
  toolbarPushedLeft15AndDown: props => ({
    transform: 'translateX(-15%) translateY(160%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(-15%) translateY(135%)'
    }
  }),
  toolbarPushedLeft15AndDownIfHeading: props => ({
    transform: 'translateX(-15%) translateY(215%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(-15%) translateY(170%)'
    }
  }),
  toolbarPushedLeft20: {
    transform: 'translate(-20%)'
  },
  toolbarPushedLeft20AndDown: props => ({
    transform: 'translateX(-20%) translateY(160%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(-20%) translateY(135%)'
    }
  }),
  toolbarPushedLeft20AndDownIfHeading: props => ({
    transform: 'translateX(-20%) translateY(215%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateX(-20%) translateY(170%)'
    }
  }),
  toolbarPushedDown: props => ({
    transform: 'translateY(160%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateY(135%)'
    }
  }),
  toolbarPushedDownIfHeading: props => ({
    transform: 'translateY(215%)',
    [props.breakpoints.down(displayBorder.extraLarge)]: {
      transform: 'translateY(170%)'
    }
  }),
  blockStyleDropdown: props => ({
    width: '160px',
    height: '35px',
    verticalAlign: 'super',
    borderRadius: '5px',
    maring: '0px 6px !important',
    color: props.palette.gray.white,
    backgroundColor: props.palette.gray.main,
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(16),
    '& .MuiSelect-icon': {
      color: props.palette.gray.white
    }
  }),
  blockStyleDropdownOptions: props => ({
    color: props.palette.gray.white,
    borderBottom: '1px solid',
    borderBottomColor: props.palette.gray.main,
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(16)
  })
});
