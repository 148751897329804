import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';

const useStyles = makeStyles({
  img: {
    display: 'block',
    margin: 'auto'
  }
});

export const Logo = ({ sx, variant, width = '132px', height = '41px', isForLoader }) => {
  let logoIcon = 'logo';
  if (variant === 'logoWithText') {
    logoIcon = 'logoWithText';
  } else if (variant === 'smallLogo') {
    logoIcon = 'logoWithoutText';
  } else if (variant === 'pink') {
    logoIcon = 'logoPink';
  }

  if (isForLoader) {
    logoIcon = 'logoForLoader';
  }

  const classes = useStyles();
  return (
    <Box sx={{ ...sx }}>
      <div style={{ height: height, width: width }} alt='logo' className={classes.img}>
        <Icons iconName={logoIcon} />
      </div>
    </Box>
  );
};

Logo.propTypes = {
  sx: PropTypes.object,
  variant: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  isForLoader: PropTypes.bool
};
