import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Teams`;

const getTeams = () => {
  return axios.get(baseUrl).then(response => {
    return response.data;
  });
};

export const teamService = {
  getTeams
};
