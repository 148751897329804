import axios from 'axios';
import qs from 'qs';

import { getApiCoreUrl, partialObjectUpdate } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/FilterSets`;

const getFilterSets = source => {
  const url = `${baseUrl}?${qs.stringify({ source })}`;

  return axios.get(url).then(response => {
    return response.data;
  });
};

const saveFilterSet = data => {
  const url = `${baseUrl}/save_filter_set`;
  return axios.post(url, data);
};

const updateFilterSet = (id, propertyToUpdate, path) => {
  const url = `${baseUrl}/${id}`;
  return partialObjectUpdate(url, path, propertyToUpdate);
};

const deleteFilterSet = id => {
  const url = `${baseUrl}/${id}`;
  return axios.delete(url);
};

export const filterSetService = {
  getFilterSets,
  saveFilterSet,
  updateFilterSet,
  deleteFilterSet
};
