import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { PrimaryButton } from 'components';

import { useStyles } from './AuthFormsStyle.css';
import { AuthTitle } from './AuthTitle';
import { OTPInput } from './OTPInput';

export const MFARegister = ({ qrCodeValue, validateCode, isProfilePage, isTOTPWrong }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [otp, setOtp] = useState('');

  const onChange = value => {
    setOtp(value);
  };

  return (
    <div
      style={{
        maxWidth: isProfilePage && '700px',
        margin: isProfilePage && 'auto'
      }}>
      <Stack
        direction='row'
        alignItems='center'
        sx={{
          mb: isMobile ? 2.5 : 5,
          pt: (isMobile && 5) || (isProfilePage && '50px')
        }}>
        <Box sx={{ flexGrow: 1, display: 'initial', textAlign: 'center' }}>
          <AuthTitle page='mfaRegister' />
        </Box>
      </Stack>
      <Stack spacing={3}>
        <Typography className={classes.mfaStepText}>{t('installAuthenticator')}</Typography>
        <Typography className={classes.mfaStepText}>{t('scanQRCode')}</Typography>
        <img src={qrCodeValue} style={{ height: 166, width: 166, alignSelf: 'center' }} />
        {!isProfilePage && (
          <Stack spacing={3}>
            <Typography className={classes.mfaStepText}>{t('enterCodeRegister')}</Typography>
            {isTOTPWrong && (
              <Box sx={{ alignSelf: 'center' }}>
                <Alert severity='error'>{t('wrongCode')}</Alert>
              </Box>
            )}
            <OTPInput otp={otp} onChange={onChange} />
            <Stack spacing={3} sx={{ alignItems: 'center' }}>
              <PrimaryButton
                handleClick={() => validateCode(otp)}
                isDisabled={otp.length < 6}
                buttonText={t('complete')}
                width='334px'
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

MFARegister.propTypes = {
  qrCodeValue: PropTypes.string,
  validateCode: PropTypes.func,
  isProfilePage: PropTypes.bool,
  isTOTPWrong: PropTypes.bool
};
