import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/MasterUser`;

const switchTenant = async newTenantId => {
  const res = await axios.post(baseUrl, newTenantId);
  return res.data;
};

export const masterUserService = {
  switchTenant
};
