import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CircleScore } from 'components';
import { getIntelligibilityLevelFromScore } from 'helpers';

export const SimplificationScoreInfo = ({ scoreType, score }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  return (
    <Box style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
      <Typography
        sx={{
          fontSize: theme.typography.pxToRem(12),
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          color: theme.palette.gray.main
        }}>
        {t(scoreType)}:
      </Typography>
      <CircleScore
        backgroundColor={
          theme.palette[`intelligibility_Level${getIntelligibilityLevelFromScore(score)}`]['dark']
        }
        color={theme.palette.gray.white}
        score={score}
        width={25}
        height={25}
        fontSize={12}
      />
    </Box>
  );
};

SimplificationScoreInfo.propTypes = {
  scoreType: PropTypes.string,
  score: PropTypes.number
};
