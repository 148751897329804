import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemText, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { MESSAGE_TYPES } from 'helpers';

export const MenuItems = ({ isCommentCreator, isResolved, performCommentOperation }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const useStyles = makeStyles({
    menuItem: {
      height: '50px',
      minWidth: '180px',
      color: theme.palette.gray.dark,
      borderBottom: '0.5px solid ' + theme.palette.gray.lighter,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      '&:hover > *': {
        color: theme.palette.primary.main
      },
      '&:hover': {
        backgroundColor: theme.palette.gray.lighter
      }
    }
  });

  const classes = useStyles();

  return (
    <>
      {isResolved && (
        <MenuItem
          className={classes.menuItem}
          onClick={() => performCommentOperation(MESSAGE_TYPES.RESOLVE_COMMENT)}>
          <ListItemText
            primary={t('markUnresolved')}
            primaryTypographyProps={{
              variant: 'body2',
              fontFamily: theme.typography.fontFamilyPrimaryMedium
            }}
          />
        </MenuItem>
      )}
      {isCommentCreator && (
        <MenuItem
          className={classes.menuItem}
          onClick={() => performCommentOperation(MESSAGE_TYPES.REMOVE_COMMENTS)}>
          <ListItemText
            primary={t('delete')}
            primaryTypographyProps={{
              variant: 'body2',
              fontFamily: theme.typography.fontFamilyPrimaryMedium
            }}
          />
        </MenuItem>
      )}
    </>
  );
};

MenuItems.propTypes = {
  isCommentCreator: PropTypes.bool,
  isResolved: PropTypes.bool,
  performCommentOperation: PropTypes.func
};
