import React, { useContext, useEffect, useState } from 'react';

import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';

import { SimplificationItemAccordion } from 'components';
import { mixpanel, scrollToOverflowingDialog, MESSAGE_TYPES } from 'helpers';
import { WebSocketConnectionContext } from 'providers';

export const SimplificationItem = ({
  index,
  sentenceSuggestion,
  sentenceSuggestions,
  setSentenceSuggestions,
  setClickedIndicatorIndex,
  resetTimers,
  quillEditor,
  dialogRef,
  scrollRef
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    connection,
    id: documentId,
    previouslyClickedSection,
    setPreviouslyClickedSection,
    setIsParagraphDialogOpen
  } = useContext(WebSocketConnectionContext);

  const trackMixpanelData = (hide, suggestionText) => {
    mixpanel.track('Use paragraph-level suggestion', {
      Hide: hide ? 'On' : 'Off',
      'Replacement used': suggestionText,
      'Selected paragraph': previouslyClickedSection.text
    });
  };

  const handleApplySuggestion = (simplificationId, suggestionText) => {
    connection.invoke(
      MESSAGE_TYPES.APPLY_SIMPLIFICATION,
      documentId,
      previouslyClickedSection.id,
      simplificationId
    );
    quillEditor.deleteText(
      previouslyClickedSection.startIndex,
      previouslyClickedSection.endIndex - previouslyClickedSection.startIndex,
      'user'
    );
    quillEditor.insertText(previouslyClickedSection.startIndex, sentenceSuggestion.text, 'user');
    setPreviouslyClickedSection();
    resetTimers();
    trackMixpanelData(false, suggestionText);
    setClickedIndicatorIndex(-1);
    setIsParagraphDialogOpen(false);
  };

  const handleDialogClose = () => {
    quillEditor?.formatText(
      previouslyClickedSection?.startIndex,
      previouslyClickedSection?.endIndex - previouslyClickedSection?.startIndex,
      'paragraph_suggestion',
      false
    );
    setClickedIndicatorIndex(-1);
    connection?.invoke(MESSAGE_TYPES.CLOSE_SIMPLIFICATIONS);
  };

  useEffect(() => {
    // Dialog gets cut-off when it contains less than 3 suggestions and when it's opened near the end of the page with accordions expanded
    // SetTimeout is used because it takes some time for accordions to finish their expansion and that's when the dialog height and position should change
    // Whenever accordion gets expanded, scrollToOverflowingDialog method is called to reposition the dialog
    setTimeout(() => {
      if (dialogRef?.current) {
        scrollToOverflowingDialog(dialogRef.current, scrollRef);
      }
    }, 200);
  }, [isExpanded]);

  return (
    <SimplificationItemAccordion
      index={index}
      sentenceSuggestion={sentenceSuggestion}
      sentenceSuggestions={sentenceSuggestions}
      setSentenceSuggestions={setSentenceSuggestions}
      trackMixpanelData={trackMixpanelData}
      handleApplySimplification={handleApplySuggestion}
      handleDialogClose={handleDialogClose}
      setIsExpanded={setIsExpanded}
      isExpanded={isExpanded}
    />
  );
};

SimplificationItem.propTypes = {
  index: PropTypes.number,
  setClickedIndicatorIndex: PropTypes.func,
  sentenceSuggestion: PropTypes.object,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  resetTimers: PropTypes.func,
  quillEditor: PropTypes.object,
  dialogRef: PropTypes.object,
  scrollRef: PropTypes.object
};
