import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { Logo } from 'components';

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  },
  display: 'flex',
  justifyContent: 'start'
}));

export const LogoOnlyLayout = () => {
  return (
    <div>
      <HeaderStyle>
        <RouterLink to='/'>
          <Logo />
        </RouterLink>
      </HeaderStyle>
      {/* <Outlet /> */}
    </div>
  );
};
