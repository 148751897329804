import axios from 'axios';

import { downloadPDFReport, getApiCoreUrl, formatFilters } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/RiskReporting`;

const getRiskReport = async filter => {
  const url = `${baseUrl}/riskReport`;
  const obj = formatFilters(filter);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const getRiskReportVsTime = async filter => {
  const url = `${baseUrl}/riskReportVsTime`;
  const obj = formatFilters(filter);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const getHighRiskDocumentLabels = async filter => {
  const url = `${baseUrl}/highRiskDocumentLabels`;
  const obj = formatFilters(filter);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const getRiskReportPerLabel = async (filter, rowsPerPage = 10, page = 0) => {
  const url = `${baseUrl}/riskReportPerLabel`;
  const obj = formatFilters(filter, rowsPerPage, page);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const downloadRiskReport = async (dateRange, riskReportData) => {
  await downloadPDFReport(baseUrl, dateRange, riskReportData, 'risk-report.pdf');
};

export const riskService = {
  getRiskReport,
  getRiskReportVsTime,
  getHighRiskDocumentLabels,
  getRiskReportPerLabel,
  downloadRiskReport
};
