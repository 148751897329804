import React from 'react';

import { faArrowTrendUp, faChartSimple, faFilePen, faGear } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { permissions } from 'helpers';

import { APP_PATHS } from '../../routes/paths';

const getIcon = (icon, width, height) => <FontAwesomeIcon icon={icon} style={{ width, height }} />;

const ICONS = {
  reporting: getIcon(faChartSimple, '16px', '20px'),
  dashboard: getIcon(faArrowTrendUp, '18px', '16px'),
  document: getIcon(faFilePen, '18px', '20px'),
  settings: getIcon(faGear, '18px', '20px')
};

export const sidebarConfig = [
  {
    items: [
      {
        title: 'dashboard',
        path: APP_PATHS.DASHBOARD,
        icon: ICONS.dashboard
      }
    ]
  },
  {
    items: [
      {
        title: 'reporting',
        path: APP_PATHS.REPORTING,
        icon: ICONS.reporting,
        permission: permissions.READABILITY_READ,
        submenuItems: [
          { title: 'risk', path: APP_PATHS.RISK },
          {
            title: 'intelligibility',
            path: APP_PATHS.INTELLIGIBILITY
          },
          {
            title: 'productivity',
            path: APP_PATHS.PRODUCTIVITY,
            permission: permissions.USERS_READ
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: 'content',
        path: APP_PATHS.DOCUMENTS.root,
        icon: ICONS.document,
        permission: permissions.PRODUCTS_READ
      }
    ]
  },
  {
    items: [
      {
        title: 'settings',
        path: APP_PATHS.SETTINGS,
        icon: ICONS.settings,
        permission: [permissions.USERS_READ, permissions.LABELS_READ]
      }
    ]
  }
];
