import React from 'react';

import { TablePagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const rowsPerPageOptions = [5, 10, 25, 50, 100];

export const Pagination = ({
  total,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  isList = false
}) => {
  const theme = useTheme();

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      data-cy={'rowsPerPage' + rowsPerPage}
      component='div'
      count={total}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        '&.MuiTablePagination-root': {
          borderTop: 'none',

          '& .MuiInputBase-input': {
            paddingRight: '20px'
          }
        },
        'MuiTablePagination-root': {
          width: '50px'
        },
        '& .MuiTablePagination-spacer': {
          display: 'none'
        },
        '& .MuiTablePagination-displayedRows': {
          marginLeft: 'auto',
          fontSize: theme.typography.pxToRem(14),
          fontFamily: theme.typography.fontFamilyPrimaryRegular
        },
        '& .MuiTablePagination-selectLabel': {
          fontFamily: theme.typography.fontFamilyPrimaryRegular
        },
        '& .MuiButtonBase-root': {
          width: 18,
          height: 18,
          bgcolor: theme.palette.gray.lighter,
          '&:hover': {
            bgcolor: theme.palette.gray.light
          }
        },
        '& .MuiTablePagination-actions': {
          paddingRight: isList ? 0 : '1.5rem'
        },
        '& .MuiInputBase-root': {
          width: 'fit-content',
          '& .MuiTablePagination-select': {
            border: '1px solid',
            borderColor: theme.palette.gray.light,
            borderRadius: '4px',
            fontSize: theme.typography.pxToRem(14),
            fontFamily: theme.typography.fontFamilyPrimaryMedium,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '20px'
          }
        },
        '& .MuiTablePagination-selectIcon': {
          marginTop: '0px!important',
          width: '18px!important',
          height: '18px!important'
        },
        '& .MuiTablePagination-toolbar': {
          minHeight: '50px',
          height: '50px',
          paddingLeft: isList ? 0 : '24px'
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.2rem'
        },
        '& .MuiInputBase-input': {
          paddingRight: '20px!important'
        }
      }}
      backIconButtonProps={{
        sx: {
          marginRight: '10px'
        }
      }}
    />
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  isList: PropTypes.bool
};
