import Quill from 'quill';

const Inline = Quill.import('blots/inline');

class ReadabilityLevelBlot extends Inline {
  static value(node) {
    return {
      alt: node.getAttribute('alt')
    };
  }
}

Inline.order.splice(Inline.order.indexOf('inline'), 0, 'highlightEdit');
Inline.order.splice(Inline.order.indexOf('inline'), 0, 'highlight');

export class HighlightEditBlot extends Inline {
  static create(value) {
    const node = super.create();
    value.active && node.classList.add('active-highlight');
    return node;
  }
}

HighlightEditBlot.blotName = 'highlightEdit';
HighlightEditBlot.tagName = 'highlightEdit';

export class HighlightBlot extends Inline {
  static create(value) {
    const node = super.create();
    node.classList.add(value.active ? 'active-highlight' : 'highlight');
    return node;
  }
}

HighlightBlot.blotName = 'highlight';
HighlightBlot.tagName = 'highlight';

export class DeletedBlot extends ReadabilityLevelBlot {
  static create() {
    const node = super.create();
    node.setAttribute(
      'style',
      'text-decoration: line-through; text-decoration-color: #637381; text-decoration-thickness: 0.15rem; backgroundColor: transparent; color: #637381'
    );
    return node;
  }
}

DeletedBlot.blotName = 'delete';
DeletedBlot.tagName = 'delete';

export class InsertedBlot extends ReadabilityLevelBlot {
  static create() {
    const node = super.create();
    node.setAttribute('style', 'background-color: #DFE3E8; border-bottom: 3px solid #919EAB');
    return node;
  }
}

InsertedBlot.blotName = 'insert';
InsertedBlot.tagName = 'insert';
InsertedBlot.className = 'edit-text';

export class InsertedTextBlot extends ReadabilityLevelBlot {
  static create(value) {
    const node = super.create();
    node.classList.add(value.active ? 'active-highlight-edit' : value.isInserted && 'insertedText');
    return node;
  }
}

InsertedTextBlot.blotName = 'insertedText';
InsertedTextBlot.tagName = 'insertedText';

export class TextBlot extends ReadabilityLevelBlot {
  static create() {
    const node = super.create();
    return node;
  }
}

TextBlot.blotName = 'retainedText';
TextBlot.tagName = 'retainedText';

Quill.register('formats/highlight', HighlightBlot);
Quill.register('formats/highlightEdit', HighlightEditBlot);
Quill.register('formats/delete', DeletedBlot);
Quill.register('formats/insertedText', InsertedTextBlot);
Quill.register('formats/retainedText', TextBlot);
