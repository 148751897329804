import Quill from 'quill';

const Inline = Quill.import('blots/inline');

class SuggestionDensityBlot extends Inline {
  static value(node) {
    return {
      alt: node.getAttribute('alt')
    };
  }
}

export class NoComplexityBlot extends SuggestionDensityBlot {
  static blotName = 'no_complexity';

  static tagName = 'no_complexity';

  static create() {
    const node = super.create();
    node.classList.add('no_complexity');
    return node;
  }
}

export class LowPriorityBlot extends SuggestionDensityBlot {
  static blotName = 'low_priority';

  static tagName = 'low_priority';

  static create() {
    const node = super.create();
    node.classList.add('low_priority');
    return node;
  }
}

export class MediumPriorityBlot extends SuggestionDensityBlot {
  static blotName = 'medium_priority';

  static tagName = 'medium_priority';

  static create() {
    const node = super.create();
    node.classList.add('medium_priority');
    return node;
  }
}

export class HighPriorityBlot extends SuggestionDensityBlot {
  static blotName = 'high_priority';

  static tagName = 'high_priority';

  static create() {
    const node = super.create();
    node.classList.add('high_priority');
    return node;
  }
}

export class SentenceSuggestionBlot extends Inline {
  static blotName = 'sentence_suggestion';

  static tagName = 'sentence_suggestion';

  static create() {
    const node = super.create();
    node.classList.add('sentence_suggestion');
    return node;
  }
}

export class WordSuggestionBlot extends Inline {
  static blotName = 'word_suggestion';

  static tagName = 'word_suggestion';

  static create(value) {
    const node = super.create();
    node.classList.add('word_suggestion');

    if (value.active) {
      node.classList.add('word_suggestion_active');
    }

    return node;
  }
}

export class ParagraphSuggestionBlot extends Inline {
  static blotName = 'paragraph_suggestion';

  static tagName = 'paragraph_suggestion';

  static create() {
    const node = super.create();
    node.classList.add('paragraph_suggestion');
    return node;
  }
}

Inline.order.splice(Inline.order.indexOf('script'), 0, 'word_suggestion');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'sentence_suggestion');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'paragraph_suggestion');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'no_complexity');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'low_priority');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'medium_priority');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'high_priority');
Inline.order.splice(Inline.order.indexOf('inline'), 0, 'highlightEdit');
Inline.order.splice(Inline.order.indexOf('inline'), 0, 'highlight');

Quill.register('formats/word_suggestion', WordSuggestionBlot);
Quill.register('formats/sentence_suggestion', SentenceSuggestionBlot);
Quill.register('formats/paragraph_suggestion', ParagraphSuggestionBlot);
Quill.register('formats/no_complexity', NoComplexityBlot);
Quill.register('formats/low_priority', LowPriorityBlot);
Quill.register('formats/medium_priority', MediumPriorityBlot);
Quill.register('formats/high_priority', HighPriorityBlot);
