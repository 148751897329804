import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  defaultStyles: props => ({
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: props.palette.gray.lighter,
      borderRadius: '50%'
    }
  }),
  closeIcon: {
    marginLeft: '6.5rem',
    marginRight: '0.625rem'
  },
  arrowUp: {
    position: 'absolute',
    marginLeft: '5rem'
  },
  arrowDown: {
    position: 'absolute',
    marginLeft: '3.5rem'
  },
  dividerHeight: {
    height: '1.5rem',
    marginLeft: '3rem',
    marginRight: '1rem',
    position: 'absolute'
  },
  totalResult: props => ({
    position: 'absolute',
    color: props.palette.gray.main,
    opacity: '50%',
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(12)
  }),
  fontAwesomeIcon: {
    fontSize: '14px'
  }
});
