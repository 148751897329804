import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomTooltip, Icons } from 'components';
import { improvementPercentage } from 'helpers';

export const MetricsRating = ({
  metricType,
  textMetric,
  writingStyle,
  isKeyIssues = false,
  showTooltips = true,
  showScoreIcon = true
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  const improvementColor = textMetric?.isPositiveImprovement
    ? theme.palette.active.dark
    : theme.palette.error.main;

  const tooltipThresholdContent = () => {
    return (
      <>
        {textMetric.thresholds.map((threshold, index, array) => (
          <Grid
            key={metricType + '_' + textMetric.value}
            sx={{
              display: 'flex',
              direction: 'column',
              mt: '5px',
              alignItems: 'center',
              mb: array.length === index + 1 ? '0px' : '5px'
            }}>
            <Icons iconName={threshold.label + 'ScoreIcon'} />
            <Typography
              sx={{
                ml: '10px',
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                fontSize: theme.typography.pxToRem(14)
              }}>
              {threshold.displayValue}
            </Typography>
          </Grid>
        ))}
      </>
    );
  };

  const tooltipContent = () => {
    return (
      <Grid sx={{ display: 'flex', direction: 'column', alignItems: 'center' }}>
        {!isKeyIssues && <Icons iconName={'goodScoreIcon'} />}
        <Typography
          sx={{
            ml: '10px',
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            fontSize: theme.typography.pxToRem(14)
          }}>
          {writingStyle}
        </Typography>
      </Grid>
    );
  };

  const getIconName = () => {
    let iconName = '';
    if (isKeyIssues) {
      iconName = 'circleExclamation';
    } else {
      iconName = textMetric.status ? textMetric.status + 'ScoreIcon' : 'badScoreIcon';
    }
    return iconName;
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        direction: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: '20px'
      }}>
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        {showScoreIcon && (
          <Icons
            iconName={getIconName()}
            height={'16'}
            width={'16'}
            fill={isKeyIssues ? theme.palette['intelligibility_Level5'].dark : undefined}
          />
        )}
        <Typography
          data-testid='metricType'
          variant='body2'
          sx={{
            ml: '10px',
            mr: '10px',
            color: isKeyIssues ? theme.palette.error.main : theme.palette.gray.dark,
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            fontSize: theme.typography.pxToRem(14),
            paddingLeft: !showScoreIcon && '18px'
          }}>
          {t(metricType)}
        </Typography>
        {showTooltips && (
          <CustomTooltip
            dataTestId='tooltip'
            tooltipContent={writingStyle ? tooltipContent() : tooltipThresholdContent()}
          />
        )}
      </Grid>
      {!isKeyIssues && (
        <Grid data-testid='textMetricValue' sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='body2'
            sx={{
              color: theme.palette.gray.dark,
              fontFamily: theme.typography.fontFamilyPrimaryMedium,
              fontSize: theme.typography.pxToRem(14)
            }}>
            {textMetric.value}
          </Typography>
          {textMetric?.improvementPercentage !== undefined &&
            textMetric?.improvementPercentage !== 0 && (
              <Grid
                data-testid='textMetricImprovementPercentage'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'flex-end',
                  mb: '2px',
                  ml: '5px'
                }}>
                <>
                  <Typography
                    variant='body2'
                    sx={{
                      color: improvementColor,
                      fontFamily: theme.typography.fontFamilyPrimaryMedium,
                      fontSize: theme.typography.pxToRem(10)
                    }}>
                    {improvementPercentage(textMetric?.improvementPercentage)}%
                  </Typography>
                </>
              </Grid>
            )}
        </Grid>
      )}
    </Grid>
  );
};

MetricsRating.propTypes = {
  metricType: PropTypes.string,
  textMetric: PropTypes.object,
  writingStyle: PropTypes.string,
  isKeyIssues: PropTypes.bool,
  showTooltips: PropTypes.bool,
  showScoreIcon: PropTypes.bool
};

export default MetricsRating;
