import React from 'react';

import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import { Icons } from 'components';

export const CustomCheckbox = ({
  indeterminate,
  checked,
  onChange,
  disabled,
  dataCy,
  dataTestId
}) => {
  return (
    <Checkbox
      sx={{ width: 32, height: 32 }}
      icon={
        <div style={{ maxWidth: 'none', marginTop: '7px' }}>
          <Icons iconName={'checkboxUncheckedIcon'} />
        </div>
      }
      checkedIcon={
        <div style={{ maxWidth: 'none', marginTop: '7px' }}>
          <Icons iconName={'checkboxCheckedIcon'} />
        </div>
      }
      indeterminate={indeterminate}
      indeterminateIcon={
        <div style={{ maxWidth: 'none', marginTop: '7px' }}>
          <Icons iconName={'checkboxIndeterminateIcon'} />
        </div>
      }
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      data-cy={dataCy}
      data-testid={dataTestId}
    />
  );
};

CustomCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
  dataTestId: PropTypes.string
};
