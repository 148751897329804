import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Icons, LabelMenu } from 'components';

export const MoreMenu = ({
  existingLabels,
  selectedLabels,
  setSelectedLabels,
  isChangeLabelsInProgress,
  setIsChangeLabelInProgress,
  onDelete,
  selectedDocuments,
  hasDeletePermission,
  hasUpdateDocumentPermission,
  onChangeLabel,
  customStyles,
  vertical = 'top',
  horizontal = 'right',
  children,
  isDocumentEditor = true,
  disabled = false,
  isVertical = true
}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [t] = useTranslation('common');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const useStyles = makeStyles({
    iconButton: {
      padding: 0,
      backgroundColor: isDocumentEditor || open ? theme.palette.gray.lighter : 'unset',
      '&:hover': {
        color: theme.palette.gray.main,
        backgroundColor: theme.palette.gray.light
      }
    },
    menuItem: {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      height: '50px',
      '&:hover': {
        backgroundColor: theme.palette.gray.lighter
      },
      '&:hover > *': {
        color: theme.palette.primary.main
      }
    }
  });
  const classes = useStyles();

  const handleDeleteDialog = () => {
    setAnchorEl(null);
    onDelete();
  };

  const onChangeLabels = e => {
    onChangeLabel(e);
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <IconButton
        data-testid='iconButton'
        disabled={disabled}
        disableRipple
        className={clsx([classes.iconButton, customStyles])}
        ref={ref}
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        data-cy={'moreMenu'}>
        <Icons
          data-testid='icon'
          iconName={isVertical ? 'moreVerticalFill' : 'moreHorizontalFill'}
        />
      </IconButton>
      {isChangeLabelsInProgress ? (
        <Menu
          data-testid='labelMenu'
          open={open}
          anchorEl={ref.current}
          onClose={() => {
            setAnchorEl(false);
            setIsChangeLabelInProgress(false);
          }}
          PaperProps={{
            sx: { width: '270px' }
          }}
          MenuListProps={{
            sx: { padding: '0px' }
          }}>
          <LabelMenu
            existingLabels={existingLabels}
            selectedLabels={selectedLabels}
            setSelectedLabels={setSelectedLabels}
            setIsChangeLabelInProgress={setIsChangeLabelInProgress}
          />
        </Menu>
      ) : (
        <Menu
          data-testid='menu'
          open={open}
          anchorEl={ref.current}
          onBlur={() => setAnchorEl(null)}
          PaperProps={{
            sx: {
              width: 'fit-content',
              maxWidth: '100%',
              mt: '10px',
              boxShadow: '0px 0px 10px 0px rgba(99, 115, 129, 0.2)'
            }
          }}
          MenuListProps={{
            sx: { padding: '0px' }
          }}
          anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
          transformOrigin={{ vertical: 'top', horizontal: horizontal }}>
          {children}
          {hasUpdateDocumentPermission && (
            <MenuItem
              disableRipple
              data-testid='changeLabelsMenuItem'
              disabled={selectedDocuments.length > 1}
              className={classes.menuItem}
              onClick={onChangeLabels}
              data-cy='changeLabelsMenuItem'>
              <ListItemIcon>
                <Icons iconName={'labels'} />
              </ListItemIcon>
              <ListItemText
                primary={t('changeLabels')}
                primaryTypographyProps={{
                  variant: 'body2',
                  fontFamily: theme.typography.fontFamilyPrimaryMedium
                }}
              />
            </MenuItem>
          )}
          {hasDeletePermission && (
            <MenuItem
              disableRipple
              data-testid='deleteMenuItem'
              className={classes.menuItem}
              onClick={handleDeleteDialog}
              data-cy='deleteMenuItem'>
              <ListItemIcon>
                <Icons iconName={'delete'} />
              </ListItemIcon>
              <ListItemText
                primary={t('delete')}
                primaryTypographyProps={{
                  variant: 'body2',
                  fontFamily: theme.typography.fontFamilyPrimaryMedium
                }}
              />
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

MoreMenu.propTypes = {
  onDelete: PropTypes.func,
  hasDeletePermission: PropTypes.bool,
  hasUpdateDocumentPermission: PropTypes.bool,
  onChangeLabel: PropTypes.func,
  customStyles: PropTypes.string,
  vertical: PropTypes.string,
  existingLabels: PropTypes.array,
  selectedLabels: PropTypes.array,
  setSelectedLabels: PropTypes.func,
  isChangeLabelsInProgress: PropTypes.bool,
  setIsChangeLabelInProgress: PropTypes.func,
  selectedDocuments: PropTypes.array,
  children: PropTypes.object,
  isDocumentEditor: PropTypes.bool,
  disabled: PropTypes.bool,
  isVertical: PropTypes.bool,
  horizontal: PropTypes.string
};
