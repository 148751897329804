import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Roles`;

const getRoles = () => {
  return axios.get(baseUrl).then(response => {
    return response.data;
  });
};

export const roleService = {
  getRoles
};
