import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { PrimaryButton } from 'components';
import { mixpanel, toastMessage } from 'helpers';
import { documentService } from 'services';

export const DownloadPDFButton = ({ documentId, documentName }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const downloadReport = () => {
    return documentService.downloadReport(documentId, documentName).then(() => {
      toastMessage(enqueueSnackbar, closeSnackbar, t('successFileDownload'), 'success');
    });
  };

  return (
    <PrimaryButton
      buttonText={t('downloadPDF')}
      icon={'downloadReport'}
      iconStrokeColor={theme.palette.gray.white}
      handleClick={() => {
        downloadReport();
        mixpanel.track('Download report', {
          'Document Id': documentId
        });
      }}
    />
  );
};

DownloadPDFButton.propTypes = {
  documentId: PropTypes.string,
  documentName: PropTypes.string
};
