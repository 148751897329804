import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import {
  getColorBasedOnImprovement,
  getIntelligibilityLevelFromScore,
  improvementPercentage
} from 'helpers/methods';

export const IntelligibilityScoreInfo = ({
  originalParagraphScore,
  newParagraphScore,
  improvement
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  const useStyles = makeStyles({
    scoreInfo: {
      display: 'flex',
      alignItems: 'end',
      paddingLeft: '15px',
      paddingRight: '15px'
    },
    scoreInfoTitle: {
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      color: theme.palette.gray.main,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: '5px',
      marginBottom: '10px'
    },
    scoreInfoValues: {
      fontFamily: theme.typography.fontEditorPrimary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(12),
      marginBottom: '10px'
    }
  });

  const classes = useStyles();

  return (
    <Stack>
      <Stack direction='row' className={classes.scoreInfo}>
        <Typography className={classes.scoreInfoTitle}>{t('originalScore')}:</Typography>
        <Typography
          className={classes.scoreInfoValues}
          sx={{
            color:
              theme.palette[
                `intelligibility_Level${getIntelligibilityLevelFromScore(originalParagraphScore)}`
              ].dark
          }}>
          {originalParagraphScore}
        </Typography>
      </Stack>
      <Stack direction='row' className={classes.scoreInfo}>
        <Typography className={classes.scoreInfoTitle}>{t('suggestionScore')}:</Typography>
        <Typography
          className={classes.scoreInfoValues}
          sx={{
            color:
              theme.palette[
                `intelligibility_Level${getIntelligibilityLevelFromScore(newParagraphScore)}`
              ].dark
          }}>
          {newParagraphScore}
        </Typography>
      </Stack>
      <Stack direction='row' className={classes.scoreInfo}>
        <Typography className={classes.scoreInfoTitle}>{t('improvement')}:</Typography>
        <Typography
          className={classes.scoreInfoValues}
          sx={{
            color: getColorBasedOnImprovement(improvement, theme)
          }}>
          {improvementPercentage(improvement)}%
        </Typography>
      </Stack>
    </Stack>
  );
};

IntelligibilityScoreInfo.propTypes = {
  originalParagraphScore: PropTypes.number,
  newParagraphScore: PropTypes.number,
  improvement: PropTypes.number
};
