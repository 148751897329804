import React from 'react';

import { ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const ListItemStyleWrapper = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.gray.main,
  '&:before': {
    top: 0,
    left: 0,
    width: 4,
    bottom: 0,
    content: '""',
    display: 'none',
    position: 'absolute',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: theme.palette.primary.main,
    marginTop: 4,
    marginBottom: 4
  },
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

export const ListItemStyle = ({ dataCy, ...props }) => {
  return <ListItemStyleWrapper {...props} data-cy={dataCy} />;
};

ListItemStyle.propTypes = {
  dataCy: PropTypes.string
};
