import React from 'react';

import { styled } from '@mui/material/styles';

import { LoadingAnimation } from 'components';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper
}));

export const LoadingScreen = ({ ...other }) => {
  return (
    <div>
      <RootStyle {...other}>
        <LoadingAnimation height={150} width={150} />
      </RootStyle>
    </div>
  );
};
