import axios from 'axios';
import qs from 'qs';

import { getApiCoreUrl, downloadFile } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Versions`;

const getDocumentVersions = async (id, search = '') => {
  const url = `${baseUrl}/${id}?${qs.stringify({
    search: search
  })}`;
  return await axios.get(url).then(response => {
    return response.data;
  });
};

const getVersionContent = async (documentId, versionId) => {
  const url = `${baseUrl}/${documentId}/${versionId}`;
  return await axios.get(url).then(response => {
    return response.data;
  });
};

const downloadVersion = (documentId, versionId, documentName, versionName) => {
  const url = `${baseUrl}/download/${documentId}/${versionId}`;
  return axios.get(url).then(() => {
    return downloadFile(axios, url, documentName, '.docx', versionName);
  });
};

const duplicateVersion = async (id, versionId) => {
  const url = `${baseUrl}/${id}/${versionId}/duplicate`;
  return await axios.post(url).then(response => {
    return response.data;
  });
};

const restoreVersion = async (id, versionId) => {
  const url = `${baseUrl}/${id}/${versionId}/restore`;
  return await axios.post(url).then(response => {
    return response.data;
  });
};

const uploadVersion = async (file, documentId) => {
  const url = `${baseUrl}/${documentId}/upload`;
  const formData = new FormData();
  formData.append('file', file);
  return await axios.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  });
};

const rename = async (documentId, version) => {
  const url = `${baseUrl}/${documentId}/rename`;
  return await axios.put(url, version).then(response => {
    return response.data;
  });
};

export const versionService = {
  getDocumentVersions,
  getVersionContent,
  downloadVersion,
  duplicateVersion,
  restoreVersion,
  uploadVersion,
  rename
};
