import React from 'react';

import {
  faChevronDown,
  faChevronUp,
  faMagnifyingGlass,
  faXmark
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, InputBase, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './SearchTermComponent.css';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  boxShadow: '0px 0px 10px 0px #63738126',
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto'
  },
  border: '1px solid ' + theme.palette.gray.light
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: 356,
  height: 35,
  [theme.breakpoints.up('xl')]: {
    width: 375
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 35
    },
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    color: theme.palette.primary.main,
    '&::placeholder': {
      color: theme.palette.gray.main
    }
  }
}));

export const SearchTermComponent = ({
  value,
  total,
  handleChange,
  placeholder,
  activeHighlightIndex,
  handleChangesDown,
  handleChangesUp
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const selectSearchValue = () => {
    const searchInput = document.getElementById('searchInput');
    searchInput.focus();
    searchInput.select();
  };

  return (
    <div className={classes.flex}>
      <Search>
        <SearchIconWrapper>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className={classes.fontAwesomeIcon}
            style={{ color: theme.palette.info.main }}
          />
        </SearchIconWrapper>
        <StyledInputBase
          data-testid='searchInput'
          autoFocus
          id='searchInput'
          placeholder={placeholder}
          value={value}
          onChange={e => handleChange(e.target.value)}
          inputProps={{ 'aria-label': 'search', maxLength: 50 }}
          endAdornment={
            value.length > 1 && (
              <div>
                <p className={classes.totalResult}>
                  {total && activeHighlightIndex + 1}/{total}
                </p>
                <Divider className={classes.dividerHeight} orientation='vertical' />
                <Stack
                  data-testid='expandMore'
                  className={clsx([classes.defaultStyles, classes.arrowUp])}
                  onClick={() => {
                    selectSearchValue();
                    handleChangesUp(total);
                  }}>
                  <FontAwesomeIcon icon={faChevronDown} className={classes.fontAwesomeIcon} />
                </Stack>
                <Stack
                  data-testid='expandLess'
                  className={clsx([classes.defaultStyles, classes.arrowDown])}
                  onClick={() => {
                    selectSearchValue();
                    handleChangesDown(total);
                  }}>
                  <FontAwesomeIcon icon={faChevronUp} className={classes.fontAwesomeIcon} />
                </Stack>
                <Stack
                  data-testid='closeIcon'
                  className={clsx([classes.defaultStyles, classes.closeIcon])}
                  onClick={() => handleChange('')}>
                  <FontAwesomeIcon icon={faXmark} className={classes.fontAwesomeIcon} />
                </Stack>
              </div>
            )
          }
        />
      </Search>
    </div>
  );
};

SearchTermComponent.propTypes = {
  value: PropTypes.string,
  total: PropTypes.number,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  handleChangesDown: PropTypes.func,
  handleChangesUp: PropTypes.func,
  activeHighlightIndex: PropTypes.number
};
