import React from 'react';

import { useTheme } from '@mui/material/styles';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { MAvatar } from './MAvatar';

export const MyAvatar = ({ displayName, src, ...other }) => {
  const theme = useTheme();

  const hashToIndex = userName => {
    return [...userName].reduce((acc, char) => acc + char.charCodeAt(0), 0) % 10;
  };

  const getFirstCharacter = userName => {
    return capitalize(userName && userName.charAt(0));
  };

  return (
    <MAvatar
      style={{
        fontFamily: theme.typography.fontFamilyPrimaryRegular
      }}
      src={src}
      alt={displayName}
      colorIndex={src ? -1 : hashToIndex(displayName)}
      {...other}>
      {getFirstCharacter(displayName)}
    </MAvatar>
  );
};

MyAvatar.propTypes = {
  displayName: PropTypes.string,
  src: PropTypes.string
};
