import Quill from 'quill';
import { v4 as uuidv4 } from 'uuid';

const Block = Quill.import('blots/block');
const NUM_OF_INDENTATION_LEVELS = 9;

class ListItem extends Block {
  static blotName = 'listItem';

  static tagName = 'list-item';

  static create(propertiesString) {
    const properties = JSON.parse(propertiesString);
    const node = super.create();

    node.setAttribute('listId', properties.id);
    node.setAttribute('listItemId', uuidv4());

    // old lists still can have 'ordered' value
    node.setAttribute('class', properties.type === 'ordered' ? 'decimal' : properties.type);
    return node;
  }

  static formats(domNode) {
    return JSON.stringify({
      listItemId: domNode.getAttribute('listItemId') || undefined,
      id: domNode.getAttribute('listId') || undefined,
      type: domNode.classList.contains('bullet') ? 'bullet' : domNode.classList.value
    });
  }
}

export const setNumberingAndStyle = listItems => {
  const counters = {};

  if (listItems) {
    for (const li of listItems) {
      const listId = li.getAttribute('listId');

      if (!(listId in counters)) {
        counters[listId] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      }

      const indentationLevel = getIndentationLevel(li);
      let counterResetString = '';
      for (let i = 0; i < NUM_OF_INDENTATION_LEVELS; i++) {
        setCounterValue(i, indentationLevel, counters[listId]);

        if (i <= indentationLevel) {
          counterResetString += `ol${i} ${counters[listId][i]} `;
        }
      }

      li.style.counterReset = counterResetString;
    }
  }
};

// Quill editor uses keyboard bindings to define behavior for some keys.
// Set editor bindings for the new list.
// For example, if a list item has 1. order and the user presses shift it becomes 1.1.
export const setEditorListBindings = editor => {
  Object.entries(editor.keyboard.bindings).forEach(prop => {
    const propValue = prop[1];
    propValue.forEach(key => {
      if (Array.isArray(key.format) && key.format.includes('list')) {
        const idx = key.format.indexOf('list');
        key.format[idx] = 'listItem';
      }
    });
  });

  // 8 represents 'Backspace' key.
  // 0 represents behavior when the user clicks 'Backspace' (without combination with any other key like shift, ctrl, etc.) on indent and list item.
  // Original handler is replaced because it was dependent on the old list.
  editor.keyboard.bindings[8][0].handler = (_, context) => handleListBackspace(editor, context);
  // 8 represents 'Tab' key.
  // 5 represents behavior when the user presses 'Tab' (without another key combination) on selected list items separated by empty line .
  editor.keyboard.bindings[9][5].handler = () => handleListTabWithEmptyLine(editor);
};

export const setSelectedListBtn = (editor, range) => {
  const rangeFormats = editor.getFormat(range);

  if (rangeFormats['listItem']) {
    const listItemFormats = rangeFormats['listItem'];

    // Get distinct list ids from selected items.
    // 'listItemFormats' can be an object or array if there is multiple paragraphs selected.
    const items = Array.isArray(listItemFormats) ? [...listItemFormats] : [listItemFormats];
    const selectedLists = [...new Set(items.map(li => JSON.parse(li).id))];

    // If there is more than one id, then none list button should be active
    if (selectedLists.length === 1) {
      const listButtons = [...document.getElementsByClassName('ql-list-item')];
      for (const btn of listButtons) {
        if (
          btn.getAttribute('value') ===
          [...new Set(items.map(li => JSON.parse(li).type))][0].split(' ')[0]
        ) {
          btn.classList.add('ql-active');
        }
      }
    }
  }
};

// Defines editor behavior when the user clicks 'Backspace' on a list item.
// If there is indentation then the indentation value will be decremented.
// If there is no indentation, the list item becomes a standard paragraph.
const handleListBackspace = (editor, context) => {
  if (context.format.indent !== undefined) {
    editor.format('indent', '-1', 'user');
  } else if (context.format.listItem !== undefined) {
    editor.format('listItem', false, 'user');
  }
};

const handleListTabWithEmptyLine = editor => {
  editor.format('indent', '+1', 'user');
};

const setCounterValue = (index, level, counter) => {
  if (index === level) {
    counter[index]++;
  } else if (index > level) {
    counter[index] = 0;
  } else if (counter[index] === 0) {
    counter[index] = 1;
  }
};

const getIndentationLevel = listItem => {
  const classArray = [...listItem.classList];

  if (classArray.some(c => c.includes('indent'))) {
    return Number(classArray.filter(c => c.includes('indent'))[0].split('-')[2]);
  }

  return 0;
};

Quill.register(ListItem, true);
