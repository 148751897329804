import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CardActionArea } from '@mui/material';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';

import { Icons, LightTooltip } from 'components';

export const IconCollapse = ({ onToggleCollapse, isCollapsed }) => {
  const [t] = useTranslation('common');

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <LightTooltip
      data-testid='tooltip'
      title={isCollapsed ? t('expandMenu') : t('collapseMenu')}
      open={isTooltipOpen}
      onOpen={() => setIsTooltipOpen(true)}
      onClose={() => setIsTooltipOpen(false)}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}>
      <CardActionArea
        disableRipple
        onClick={() => {
          setIsTooltipOpen(false);
          onToggleCollapse();
        }}
        sx={{
          width: 30,
          height: 30,
          display: 'flex',
          borderRadius: '100%',
          cursor: 'pointer',
          marginLeft: '1.5rem',
          zIndex: 9999,
          position: isCollapsed && 'fixed',
          left: isCollapsed && '2rem'
        }}>
        <Box sx={{ display: 'flex' }} data-testid='iconCollapse'>
          <Icons iconName={isCollapsed ? 'collapseRightIcon' : 'collapseIcon'} />
        </Box>
      </CardActionArea>
    </LightTooltip>
  );
};

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  isCollapsed: PropTypes.bool
};
