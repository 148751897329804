import React, { useEffect, useMemo, useRef } from 'react';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

export const OTPInput = ({ otp, onChange }) => {
  const theme = useTheme();
  const RE_DIGIT = new RegExp(/^\d+$/);
  const otpLength = 6;

  const inputFieldRef = useRef();

  const useStyles = makeStyles({
    otpGroup: {
      width: '100%',
      maxWidth: '361px',
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'center'
    },
    otpInput: {
      width: '50px',
      height: '50px',
      border: '1.5px solid',
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.primary.white,
      borderRadius: '8px',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      fontFamily: theme.typography.fontFamilyPrimaryMedium
    }
  });
  const classes = useStyles();

  const valueItems = useMemo(() => {
    const valueArray = otp.split('');
    const items = [];

    for (let i = 0; i < otpLength; i++) {
      const char = valueArray[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [otp, otpLength]);

  const focusToNextInput = element => {
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const focusToPreviousInput = element => {
    if (element.previousSibling) {
      element.previousSibling.focus();
    }
  };

  useEffect(() => {
    inputFieldRef.current.focus();
  }, []);

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }
    targetValue = isTargetValueDigit ? targetValue : ' ';
    const targetValueLength = targetValue.length;
    if (targetValueLength === 1) {
      const newValue = otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
      onChange(newValue);
      if (!isTargetValueDigit) {
        return;
      }
      focusToNextInput(target);
    } else if (targetValueLength === otpLength) {
      onChange(targetValue);
      target.blur();
    }
  };

  const inputOnKeyDown = e => {
    const { key } = e;
    const target = e.target;
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      return focusToNextInput(target);
    }
    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      return focusToPreviousInput(target);
    }
    const targetValue = target.value;
    target.setSelectionRange(0, targetValue.length);
    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }
    focusToPreviousInput(target);
  };

  const inputOnFocus = e => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className={classes.otpGroup}>
      {valueItems.map((digit, idx) => (
        <input
          ref={idx === 0 ? inputFieldRef : null}
          className={classes.otpInput}
          key={idx}
          type='text'
          inputMode='numeric'
          pattern='\d{1}'
          maxLength={otpLength}
          value={digit}
          onChange={e => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  );
};

OTPInput.propTypes = {
  otp: PropTypes.string,
  onChange: PropTypes.func
};
