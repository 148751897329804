import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { Box, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { format, isBefore, isValid, parse } from 'date-fns';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { Icons } from 'components';
import { getStringWithoutSpaces } from 'helpers';

import './DatePickerDropdown.css';

export const DatePickerDropdown = ({
  handleClick,
  setSelectedCustomDateRange,
  isOpen,
  textFieldValue,
  setTextFieldValue,
  dateRange,
  setDateRange,
  shouldExcludeDates
}) => {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const [t] = useTranslation('common');
  const appliedFilter = JSON.parse(localStorage.getItem('filtersAndTableSorting'));
  const dateFilter = appliedFilter?.date;

  const fromText = dateFilter?.[0]
    ? format(new Date(dateFilter[0]), 'dd/MM/yyyy')
    : capitalize(t('from'));
  const toText = dateFilter?.[1]
    ? format(new Date(dateFilter[1]), 'dd/MM/yyyy')
    : capitalize(t('to'));
  const fromToText = `${fromText} - ${toText}`;

  const useStyles = makeStyles({
    divContainer: {
      cursor: 'pointer',
      display: 'flex',
      paddingRight: '10px',
      paddingLeft: '10px',
      borderRadius: '8px',
      backgroundColor: isOpen ? theme.palette.gray.main : 'transparent',
      '&:hover': {
        backgroundColor: isOpen ? theme.palette.tertiary.dark : theme.palette.gray.light
      }
    },
    stackContainer: {
      height: '30px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    textStyle: {
      paddingLeft: '5px',
      fontSize: theme.typography.pxToRem(14),
      color: isOpen ? theme.palette.common.white : theme.palette.gray.main,
      fontFamily: theme.typography.fontFamilyPrimaryRegular + '!important'
    },
    boxContainer: {
      marginTop: '5px',
      padding: '8px',
      border: '1px solid' + theme.palette.gray.light,
      width: '540px',
      borderRadius: '8px',
      height: '40px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      userSelect: 'none'
    },
    dateFieldTextStyle: {
      fontSize: theme.typography.pxToRem(14),
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      color: theme.palette.gray.main,
      width: 'auto !important'
    },
    errorMessageStyle: {
      backgroundColor: theme.palette.quinary.main,
      p: '8px',
      borderRadius: '0px 0px 8px 8px',
      borderTop: 'none !important',
      border: '1px solid ' + theme.palette.quaternary.main,
      width: '540px',
      height: '20px',
      marginTop: '-5px',
      justifyContent: 'center'
    }
  });
  const classes = useStyles();

  const getStartDate = () => {
    return dateFilter?.[0] ? new Date(dateFilter[0]) : dateRange[0];
  };

  const getEndDate = () => {
    return dateFilter?.[1] ? new Date(dateFilter[1]) : dateRange[1];
  };

  useEffect(() => {
    const startDate = dateRange[0] ? format(new Date(dateRange[0]), 'dd/MM/yyyy') : fromText;
    const endDate = dateRange[1] ? format(new Date(dateRange[1]), 'dd/MM/yyyy') : toText;
    setTextFieldValue(`${startDate} - ${endDate}`);
  }, [dateRange]);

  useEffect(() => {
    if (textFieldValue === fromToText) {
      setDateRange([null, null]);
      setError(false);
    }
  }, [textFieldValue]);

  const handleTextFieldClick = () => {
    if (textFieldValue === fromToText) {
      setTextFieldValue('-');
    }
  };

  const handleChange = e => {
    const inputValue = e.target.value;
    const cursorPosition = e.target.selectionStart;
    if (
      e.nativeEvent.inputType === 'deleteContentBackward' &&
      cursorPosition > 0 &&
      inputValue[cursorPosition - 1] === '-'
    ) {
      const editedValue =
        inputValue.slice(0, cursorPosition - 2) + inputValue.slice(cursorPosition);
      setTextFieldValue(editedValue);
      e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
    }
    if (inputValue === '') {
      setTextFieldValue('-');
    } else if (inputValue.includes('-')) {
      setTextFieldValue(e.target.value);
    }
    const dates = inputValue.split('-');
    const parsedStartDate = parse(dates[0]?.trim(), 'dd/MM/yyyy', new Date());
    const parsedEndDate = parse(dates[1]?.trim(), 'dd/MM/yyyy', new Date());
    const isValidEndDate = isValid(parsedEndDate) && isBefore(parsedStartDate, parsedEndDate);

    if (
      isValid(parsedStartDate) &&
      (isValidEndDate || getStringWithoutSpaces(dates[1]).length === 0)
    ) {
      setError(false);
      setSelectedCustomDateRange([
        new Date(parsedStartDate),
        isValid(parsedEndDate) ? new Date(parsedEndDate) : null
      ]);
    } else {
      setError(true);
    }
  };

  const datesToExclude = [];

  const excludeDates = () => {
    if (shouldExcludeDates) {
      if (dateRange[0]) {
        // Same date shouldn't be chosen as both start and end date
        datesToExclude.push(new Date(dateRange[0]));
      } else {
        // Today shouldn't be chosen as startDate
        datesToExclude.push(new Date());
      }

      return datesToExclude;
    }
  };

  return (
    <div className={classes.divContainer}>
      <Stack
        data-cy={'customDatePicker'}
        ref={ref}
        onClick={e => {
          handleClick();
          setAnchorEl(e.currentTarget);
        }}
        className={classes.stackContainer}>
        <Icons
          iconName={'calendar'}
          fill={isOpen ? theme.palette.common.white : theme.palette.gray.main}
        />
      </Stack>
      <Menu
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={ref.current}
        PaperProps={{
          sx: { width: '570px', mt: '5px', height: '415px', marginLeft: '-10px' }
        }}
        MenuListProps={{
          sx: {
            padding: '0px'
          }
        }}>
        <MenuItem
          disableRipple
          style={{
            height: '60px',
            display: 'flex',
            flexDirection: 'column'
          }}
          className={'hover-white-menu-item'}>
          <Box className={classes.boxContainer}>
            <TextField
              onChange={e => {
                handleChange(e);
              }}
              onClick={() => handleTextFieldClick()}
              className={classes.dateFieldTextStyle}
              variant='standard'
              value={textFieldValue}
              InputProps={{
                style: {
                  height: '40px',
                  width: '250px',
                  fontSize: theme.typography.pxToRem(14),
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  color: theme.palette.gray.main
                },
                disableUnderline: true
              }}
            />
          </Box>
          {error && (
            <Stack className={classes.errorMessageStyle}>
              <Typography
                sx={{
                  paddingLeft: '5px',
                  fontSize: theme.typography.pxToRem(10),
                  fontFamily: theme.typography.fontFamilyPrimaryRegular
                }}>
                {t('invalidDate')}
              </Typography>
            </Stack>
          )}
        </MenuItem>
        <MenuItem sx={{ paddingTop: '20px' }} className={'hover-white-menu-item'} disableRipple>
          <DatePicker
            isClearable
            fixedHeight
            inline
            maxDate={new Date()}
            monthsShown={2}
            className={classes.currentMonth}
            popperPlacement='bottom-start'
            startDate={dateRange[0] || getStartDate()}
            endDate={dateRange[0] ? dateRange[1] : getEndDate()}
            selectsRange
            onChange={newDateRange => {
              setDateRange(newDateRange);
              setSelectedCustomDateRange(newDateRange);
            }}
            excludeDates={excludeDates()}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

DatePickerDropdown.propTypes = {
  handleClick: PropTypes.func,
  setSelectedCustomDateRange: PropTypes.func,
  isOpen: PropTypes.bool,
  setTextFieldValue: PropTypes.func,
  textFieldValue: PropTypes.string,
  dateRange: PropTypes.array,
  setDateRange: PropTypes.func,
  shouldExcludeDates: PropTypes.bool
};
