import React, { forwardRef } from 'react';

import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { ButtonAnimate } from './ButtonAnimate';

const MIconButton = forwardRef(({ children, ...other }, ref) => (
  <ButtonAnimate>
    <IconButton ref={ref} {...other}>
      {children}
    </IconButton>
  </ButtonAnimate>
));

MIconButton.propTypes = {
  children: PropTypes.node
};

MIconButton.displayName = 'MIconButton';

export default MIconButton;
