export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          },
          minWidth: '40px',
          height: '50px',
          fontWeight: 'normal',
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          fontSize: theme.typography.pxToRem(16),
          boxShadow: 'none'
        },
        sizeLarge: {
          height: 48
        },
        containedTertiary: {
          boxShadow: '5px 5px 15px ' + theme.palette.gray.main
        },
        startIcon: {
          marginRight: '0px',
          marginLeft: '0px'
        }
      }
    }
  };
}
