import axios from 'axios';
import queryString from 'query-string';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/UserDashboard`;

const getUserDashboardData = async () => {
  return axios.get(baseUrl).then(res => {
    return res.data;
  });
};

const getUserActivity = async (timePeriod = 30) => {
  const url = `${baseUrl + '/user_activity'}?${queryString.stringify({
    timePeriod: timePeriod
  })}`;
  return axios.get(url).then(res => {
    return res.data;
  });
};

export const userDashboardService = {
  getUserDashboardData,
  getUserActivity
};
