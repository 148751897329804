import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
  ADVERBS,
  PASSIVE_VOICE,
  READING_TIME,
  FLESCH_KINKAID,
  PARAGRAPH_LENGTH,
  SENTENCE_LENGTH,
  SYLLABLES_PER_WORD
} from 'helpers';

import { useStyles } from './IntelligibilitySidebar.css';
import { MetricsRating } from './MetricsRating';

export const TextDensityMetrics = ({ intelligibilityData }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);

  const formatReadingTime = minutes => {
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.ceil(minutes % 60);
      return remainingMinutes === 0 ? hours + 'h ' : hours + 'h ' + remainingMinutes + 'm';
    } else {
      return Math.ceil(minutes) + 'm';
    }
  };

  return (
    <>
      <Typography
        variant='subtitle2'
        sx={{
          color: theme.palette.gray.dark,
          fontFamily: theme.typography.fontFamilyPrimarySemibold,
          pt: '10px'
        }}>
        {t('intelligibility')}
      </Typography>
      <MetricsRating
        metricType={FLESCH_KINKAID}
        textMetric={intelligibilityData.textMetrics.fkre}
      />
      <MetricsRating
        metricType={SYLLABLES_PER_WORD}
        textMetric={intelligibilityData.textMetrics.syllablesPerWord}
      />
      <MetricsRating
        metricType={SENTENCE_LENGTH}
        textMetric={intelligibilityData.textMetrics.averageWordsPerSentence}
      />
      <MetricsRating
        metricType={PARAGRAPH_LENGTH}
        textMetric={intelligibilityData.textMetrics.averageSentencesPerParagraph}
      />
      <MetricsRating
        metricType={READING_TIME}
        textMetric={{
          value: formatReadingTime(intelligibilityData.textMetrics.readingTime)
        }}
        showTooltips={false}
        showScoreIcon={false}
      />
      <Divider sx={{ m: '25px 0px' }} />
      <Typography variant='body2' className={classes.headerStyle}>
        {t('writingStyle')}
      </Typography>
      <MetricsRating
        metricType={PASSIVE_VOICE}
        textMetric={intelligibilityData.textMetrics.passiveVoiceCount}
      />
      <MetricsRating
        metricType={ADVERBS}
        textMetric={intelligibilityData.textMetrics.adverbsCount}
      />
      <Divider sx={{ mt: '25px' }} />
    </>
  );
};

TextDensityMetrics.propTypes = {
  intelligibilityData: PropTypes.object
};

export default TextDensityMetrics;
