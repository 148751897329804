import Quill from 'quill';

const Parchment = Quill.import('parchment');

const config = {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['0', '1', '2']
};

const HeadingAttribute = new Parchment.Attributor.Attribute('heading', 'heading', config);
const HeadingClass = new Parchment.Attributor.Class('heading', 'ql-heading', config);

Quill.register(HeadingAttribute, true);
Quill.register(HeadingClass, true);
