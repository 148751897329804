import React from 'react';

import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { SvgIconStyle } from 'components';
import { backIcon } from 'resources';

export const BackButton = ({
  handleClick,
  position,
  label,
  height = '16px',
  width = '16px',
  customStyles
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    defaultStyles: {
      '&:hover': {
        backgroundColor: theme.palette.gray.lighter
      },
      cursor: 'pointer',
      borderRadius: '50%',
      width: '36px',
      height: '36px',
      justifyContent: 'center',
      marginRight: '0.5rem'
    }
  });

  const classes = useStyles();

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        color: theme.palette.gray.main,
        position: position,
        bottom: '15px'
      }}>
      <Stack onClick={handleClick} className={clsx([classes.defaultStyles, customStyles])}>
        <SvgIconStyle src={backIcon} sx={{ alignSelf: 'center', height: height, width: width }} />
      </Stack>
      <Typography
        gutterBottom
        variant='subtitle1'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          marginBottom: 0
        }}>
        {label}
      </Typography>
    </Stack>
  );
};

BackButton.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  position: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  customStyles: PropTypes.string
};

export default BackButton;
