import axios from 'axios';
import qs from 'qs';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Labels`;

const getLabels = async (search = '', page = null, rowsPerPage = null) => {
  const url = `${baseUrl}?${qs.stringify({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    search: search
  })}`;

  const response = await axios.get(url);
  return response.data;
};

const createLabel = data => {
  return axios.post(baseUrl, data);
};

const renameLabel = async newLabelName => {
  const res = await axios.put(baseUrl, newLabelName);
  return res.data;
};

const deleteLabel = id => {
  const url = `${baseUrl}/${id}`;
  return axios.delete(url);
};

export const labelService = {
  getLabels,
  createLabel,
  renameLabel,
  deleteLabel
};
