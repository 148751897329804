import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Select = () => {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon
      }
    }
  };
};

export default Select;
