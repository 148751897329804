import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faChevronRight,
  faTable,
  faTableLayout,
  faTrash
} from '@fortawesome/pro-regular-svg-icons';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { TableMenuItem } from 'components';
import {
  deleteColumn,
  deleteRow,
  deleteTable,
  insertColumn,
  insertRow,
  insertTable
} from 'components/Table/Table';

import './TableMenu.css';

export const TableMenu = React.memo(({ editor }) => {
  const theme = useTheme();
  const ref = useRef();
  const [t] = useTranslation('common');

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selection, setSelection] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [tableAnchorElement, setTableAnchorElement] = useState(ref.current);
  const [delayHandler, setDelayHandler] = useState();
  const [tableData, setTableData] = useState({
    row: 0,
    column: 0
  });
  const [tableInput, setTableInput] = useState(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, (v, i) => ({
        bg: theme.palette.gray.lightest,
        borderColor: theme.palette.info.main,
        column: i
      }))
    )
  );

  const useStyles = makeStyles({
    menuItem: {
      color: theme.palette.gray.dark,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      height: '30px',
      '&:hover': {
        backgroundColor: theme.palette.gray.lightest
      },
      '&:hover > *': {
        color: theme.palette.primary.main
      }
    },
    iconButton: {
      '&:hover': {
        color: theme.palette.quaternary.main,
        backgroundColor: theme.palette.quaternary[20]
      }
    },
    fontAwesomeIcon: {
      color: theme.palette.gray.dark
    },
    insertTable: {
      paddingRight: '0.3rem'
    }
  });
  const classes = useStyles();

  //This method is triggered when hovering over 10x10 insert table fields for graphical selection of table dimensions.
  //It is used to set background and border color of selected table dimension.
  useEffect(() => {
    const newTable = Array.from({ length: 10 }, (obj, row) =>
      Array.from({ length: 10 }, (v, col) => ({
        bg:
          row + 1 <= tableData.row && col + 1 <= tableData.column
            ? theme.palette.gray.lightest
            : theme.palette.gray.white,
        borderColor:
          row + 1 <= tableData.row && col + 1 <= tableData.column
            ? theme.palette.primary.main
            : theme.palette.info.main,
        column: col
      }))
    );
    setTableInput(newTable);
  }, [tableData]);

  useEffect(() => {
    if (!showOptions) {
      setTableData({
        row: 0,
        column: 0
      });
    }
  }, [showOptions]);

  const handleButtonClick = () => {
    setSelection(editor.getSelection());
    setIsOpen(true);
  };

  const handleInsertTable = () => {
    setTableData({ row: -1, column: -1 });
    if (selection) {
      insertTable(editor, selection, tableData.row, tableData.column);
    }
    setShowOptions(false);
    setIsOpen(false);
  };

  const expanderHandler = useCallback(e => {
    const currentTarget = e.currentTarget;

    setDelayHandler(
      setTimeout(() => {
        setTableAnchorElement(currentTarget.parentElement.parentElement);
        setShowTable(true);
      }, 300)
    );
  });

  const expanderLeaveHandler = useCallback(() => {
    clearTimeout(delayHandler);
  });

  const mainMenuOnBlurHandler = useCallback(() => {
    if (!showTable) {
      setIsOpen(false);
    }
  }, [showTable]);

  const mainMenuEnterItemHandler = useCallback(() => {
    setShowTable(false);
    setTableData({ row: -1, column: -1 });
  });

  const subMenuOnBlurHandler = useCallback(() => {
    if (showTable) {
      setShowTable(false);
      setTableData({ row: -1, column: -1 });
      setIsOpen(false);
    }
  });

  const selectedTableString = () => {
    return tableData.row >= 1 ? `${tableData.row} x ${tableData.column}` : '';
  };

  const getTableDivKey = (rowNumber, columnNumber) => {
    return Number('' + rowNumber + columnNumber);
  };

  const handleDeleteTable = () => {
    deleteTable(editor);
  };

  const handleDeleteRow = () => {
    deleteRow(editor);
  };

  const handleDeleteColumn = () => {
    deleteColumn(editor);
  };

  const handleInsertRow = addBelow => {
    insertRow(addBelow, editor);
  };

  const handleInsertColumn = insertRight => {
    insertColumn(insertRight, editor);
  };

  return (
    <div>
      <button
        ref={ref}
        onClick={e => {
          e.stopPropagation();
          handleButtonClick();
        }}
        data-testid='tableButton'>
        <div
          className={'button-image'}
          style={{ color: theme.palette.gray.dark, display: 'flex', gap: '2px' }}>
          <FontAwesomeIcon icon={faTableLayout} style={{ width: '14px', height: '14px' }} />
          <FontAwesomeIcon icon={faSortDown} style={{ marginBottom: '3px', width: '6px' }} />
        </div>
      </button>
      <Menu
        data-testid='mainMenu'
        open={isOpen}
        id={'mainMenu'}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onBlur={mainMenuOnBlurHandler}
        PaperProps={{
          sx: { width: '220px', maxWidth: '120%', mt: '10px' }
        }}
        role='tooltip'>
        <MenuItem
          data-testid='insertTableItem'
          className={clsx(classes.menuItem, classes.insertTable)}
          onMouseEnter={expanderHandler}
          onMouseLeave={expanderLeaveHandler}>
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Grid sx={{ display: 'flex' }} item xs={2}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faTable} className={classes.fontAwesomeIcon} />
              </ListItemIcon>
            </Grid>
            <Grid sx={{ display: 'flex' }} item xs={9}>
              <ListItemText
                primary={t('insertTable')}
                primaryTypographyProps={{
                  variant: 'body2',
                  fontFamily: theme.typography.fontFamilyPrimarySmall
                }}
              />
            </Grid>
            <Grid sx={{ display: 'flex' }} item xs={1}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faChevronRight} className={classes.fontAwesomeIcon} />
              </ListItemIcon>
            </Grid>
          </Grid>
        </MenuItem>
        <Divider sx={{ mx: '0.8rem' }} />
        <TableMenuItem
          iconName={faArrowUp}
          menuItemName='insertRowAbove'
          menuItemHandler={mainMenuEnterItemHandler}
          menuItemClickHandler={() => handleInsertRow(false)}
          classes={classes}
          oldEditor={false}
        />
        <TableMenuItem
          iconName={faArrowDown}
          menuItemName='insertRowBelow'
          menuItemHandler={mainMenuEnterItemHandler}
          menuItemClickHandler={() => handleInsertRow(true)}
          classes={classes}
          oldEditor={false}
        />
        <TableMenuItem
          iconName={faArrowLeft}
          menuItemName='insertColumnLeft'
          menuItemHandler={mainMenuEnterItemHandler}
          menuItemClickHandler={() => handleInsertColumn(false)}
          classes={classes}
          oldEditor={false}
        />
        <TableMenuItem
          iconName={faArrowRight}
          menuItemName='insertColumnRight'
          menuItemHandler={mainMenuEnterItemHandler}
          menuItemClickHandler={() => handleInsertColumn(true)}
          classes={classes}
          oldEditor={false}
        />
        <Divider sx={{ mx: '0.8rem' }} />
        <TableMenuItem
          iconName={faTrash}
          menuItemName='deleteRow'
          menuItemHandler={mainMenuEnterItemHandler}
          menuItemClickHandler={handleDeleteRow}
          classes={classes}
          oldEditor={false}
        />
        <TableMenuItem
          iconName={faTrash}
          menuItemName='deleteColumn'
          menuItemHandler={mainMenuEnterItemHandler}
          menuItemClickHandler={handleDeleteColumn}
          classes={classes}
          oldEditor={false}
        />
        <TableMenuItem
          iconName={faTrash}
          menuItemName='deleteTable'
          menuItemHandler={mainMenuEnterItemHandler}
          menuItemClickHandler={handleDeleteTable}
          classes={classes}
          oldEditor={false}
        />
      </Menu>
      <Menu
        open={showTable}
        anchorEl={tableAnchorElement}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ pointerEvents: 'none' }}
        PaperProps={{ sx: { marginLeft: theme.spacing(1) } }}
        onBlur={subMenuOnBlurHandler}>
        <MenuItem style={{ pointerEvents: 'auto' }}>
          <div>
            <div className='table-input'>
              {tableInput.map((grp, row) =>
                grp.map(({ column, bg, borderColor }, col) => (
                  <Stack
                    data-testid={`insertTable-${row}-${column}`}
                    key={getTableDivKey(row, col)}
                    onClick={() => handleInsertTable()}
                    onMouseOver={() => setTableData({ row: row + 1, column: column + 1 })}
                    className='table-unit'
                    style={{
                      borderColor: `${borderColor}`,
                      backgroundColor: `${bg}`
                    }}
                  />
                ))
              )}
            </div>
            <div>
              {
                <span
                  style={{
                    fontSize: '0.85em',
                    display: 'table',
                    margin: '0 auto'
                  }}>
                  <i>{selectedTableString()}</i>
                </span>
              }
            </div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
});

TableMenu.displayName = 'TableMenu';

TableMenu.propTypes = {
  editor: PropTypes.object
};
