import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DialogActions, Dialog, DialogTitle, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { PrimaryButton } from 'components';

export const ConfirmDialog = ({ isOpen, setIsOpen, callback, dialogMessage }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  const handleConfirm = () => {
    callback();
    setIsOpen(false);
  };

  const useStyles = makeStyles({
    dialogPaper: {
      borderRadius: '10px',
      width: '550px'
    },
    closeIcon: {
      width: '20px',
      height: '20px',
      backgroundColor: theme.palette.gray.lighter,
      borderRadius: '50%',
      cursor: 'pointer',
      color: theme.palette.gray.main,
      marginTop: '20px',
      marginRight: '20px',
      alignSelf: 'end'
    },
    dialogTitle: {
      color: theme.palette.gray.dark,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      paddingTop: '0px',
      paddingLeft: '30px'
    }
  });
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} classes={{ paper: classes.dialogPaper }}>
      <Stack alignItems='start'>
        <CloseRoundedIcon
          onClick={() => {
            setIsOpen(false);
          }}
          className={classes.closeIcon}
        />
        <DialogTitle className={classes.dialogTitle}>{dialogMessage}</DialogTitle>
      </Stack>
      <DialogActions sx={{ p: 4, pt: 10, gap: 3 }}>
        <PrimaryButton
          variant='white'
          handleClick={() => setIsOpen(false)}
          buttonText={t('cancel')}
          height='40px'
          padding='10px 20px 10px 20px'
          fontSize={14}
        />
        <PrimaryButton
          handleClick={handleConfirm}
          buttonText={t('confirm')}
          height='40px'
          padding='10px 20px 10px 20px'
          fontSize={14}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  callback: PropTypes.func,
  dialogMessage: PropTypes.string
};
