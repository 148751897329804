import axios from 'axios';

import { downloadPDFReport, getApiCoreUrl, formatFilters } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/IntelligibilityReporting`;

const getIntelligibilityAnalytics = async filter => {
  const obj = formatFilters(filter);
  return axios.post(baseUrl, obj).then(res => {
    return res.data;
  });
};

const getIntelligibilityPerLabel = async (filter, rowsPerPage = 10, page = 0) => {
  const url = `${baseUrl}/perLabel`;
  const obj = formatFilters(filter, rowsPerPage, page);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const getIntelligibilityPerTime = async filter => {
  const url = `${baseUrl}/perDate`;
  const obj = formatFilters(filter);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const downloadIntelligibilityReport = async (dateRange, intelligibilityReportData) => {
  await downloadPDFReport(
    baseUrl,
    dateRange,
    intelligibilityReportData,
    'intelligibility-report.pdf'
  );
};

export const intelligibilityService = {
  getIntelligibilityAnalytics,
  getIntelligibilityPerLabel,
  getIntelligibilityPerTime,
  downloadIntelligibilityReport
};
