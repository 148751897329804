import { useContext } from 'react';

import { useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { DocumentStateContext } from 'providers';

export const useStoreFields = fields => {
  const { editorStore } = useContext(DocumentStateContext);
  return useStore(
    editorStore,
    useShallow(state =>
      fields.reduce((acc, field) => {
        acc[field] = state[field];
        return acc;
      }, {})
    )
  );
};
