import mixpanelInstance from 'mixpanel-browser';

import { mixpanelProjectId, mixpanelUri } from './config';

const mixpanel = {};

let isMixpanelInitialized = false;

mixpanel.init = () => {
  if (mixpanelProjectId && mixpanelUri) {
    mixpanelInstance.init(mixpanelProjectId, {
      api_host: mixpanelUri
    });
    isMixpanelInitialized = true;
  }
};

['alias', 'identify', 'register', 'reset', 'track'].forEach(fn => {
  mixpanel[fn] = (...args) => {
    if (!isMixpanelInitialized) {
      return;
    }
    mixpanelInstance[fn](...args);
  };
});

mixpanel.setUser = (...args) => {
  if (isMixpanelInitialized && mixpanelInstance?.people) {
    mixpanelInstance.people.set(...args);
  }
};

export { mixpanel };
