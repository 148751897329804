import React, { useState } from 'react';

import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { Icons } from 'components';

import { FilterSetsList } from './FilterSetsList';
import { OverwriteFilterSet } from './OverwriteFilterSet';
import { SaveFilterSetDialog } from './SaveFilterSetDialog';

export const SaveFilterSet = ({
  filterSetData,
  page,
  filterSets,
  isDisabled,
  refetchFilterSets,
  selectedFilterSet,
  setSelectedFilterSet,
  applyFilterSet,
  resetAllFilters,
  setSelectedFilterSetId
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const currentFilterSet = filterSets?.find(fs => fs.id === selectedFilterSet);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        data-cy='saveFilterSetIconButton'
        disabled={isDisabled}
        disableRipple
        onClick={() => {
          setIsDialogOpen(true);
          refetchFilterSets();
        }}>
        <Icons iconName={'saveFilterSet'} />
      </IconButton>
      {selectedFilterSet ? (
        <OverwriteFilterSet
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          currentFilterSet={currentFilterSet}
          filterSetData={filterSetData}
        />
      ) : (
        <SaveFilterSetDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          filterSetData={filterSetData}
          page={page}
          filterSets={filterSets}
          id={currentFilterSet?.id}
          refetchFilterSets={refetchFilterSets}
        />
      )}
      <FilterSetsList
        filterSets={filterSets}
        isDisabled={isDisabled}
        filterSetData={filterSetData}
        page={page}
        refetchFilterSets={refetchFilterSets}
        applyFilterSet={applyFilterSet}
        selectedFilterSet={selectedFilterSet}
        setSelectedFilterSet={setSelectedFilterSet}
        resetAllFilters={resetAllFilters}
        setSelectedFilterSetId={setSelectedFilterSetId}
      />
    </div>
  );
};

SaveFilterSet.propTypes = {
  filterSetData: PropTypes.object,
  page: PropTypes.number,
  filterSets: PropTypes.array,
  isDisabled: PropTypes.bool,
  refetchFilterSets: PropTypes.func,
  selectedFilterSet: PropTypes.number,
  setSelectedFilterSet: PropTypes.func,
  applyFilterSet: PropTypes.func,
  resetAllFilters: PropTypes.func,
  setSelectedFilterSetId: PropTypes.func
};
