import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Stack, Typography, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { MyAvatar, LightTooltip } from 'components';
import { UserContext } from 'providers';
import { switchAccount } from 'resources';
import { APP_PATHS } from 'routes';

const AccountStyle = styled('div')(() => ({
  display: 'flex',
  paddingLeft: '2.4px',
  flexDirection: 'column',
  marginLeft: '15px'
}));

export const AccountSettings = ({ isCollapse, setIsSwitchAccountDialogOpen }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const { user } = useContext(UserContext);

  return (
    <Stack
      data-testid='accountSettings'
      spacing={3}
      sx={{
        pr: 2,
        pt: 3,
        pb: 2
      }}>
      <div>
        <AccountStyle>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mb: '5px'
            }}>
            <LightTooltip
              title={isCollapse && t('accountPage')}
              placement='right'
              data-testid='lightTooltip'>
              <Link underline='none' component={RouterLink} to={APP_PATHS.PROFILE}>
                <MyAvatar
                  displayName={user.displayName}
                  src={user.profilePictureUrl}
                  sx={{ width: 40, height: 40, fontSize: theme.typography.pxToRem(18) }}
                />
              </Link>
            </LightTooltip>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pl: '1rem'
              }}>
              <Grid
                sx={{
                  display: 'flex'
                }}>
                <Typography
                  variant='body2'
                  sx={{
                    color: theme.palette.primary.main,
                    fontFamily: theme.typography.fontFamilyPrimaryMedium,
                    fontSize: theme.typography.pxToRem(14),
                    textWrap: 'nowrap'
                  }}>
                  {user.displayName}
                </Typography>
                <Grid sx={{ display: 'flex', pl: 1.5 }}>
                  {user.tenants.length > 1 && (
                    <LightTooltip title={t('switchAccount')} placement='right'>
                      <button
                        data-testid='switchAccountButton'
                        style={{
                          cursor: 'pointer',
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '5px'
                        }}
                        onClick={() => setIsSwitchAccountDialogOpen(true)}>
                        <img
                          alt='Switch Account'
                          src={switchAccount}
                          style={{
                            cursor: 'pointer',
                            alignSelf: 'center',
                            marginLeft: '5px'
                          }}
                        />
                      </button>
                    </LightTooltip>
                  )}
                </Grid>
              </Grid>
              <Typography
                variant='body2'
                sx={{
                  color: theme.palette.gray.main,
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  fontSize: theme.typography.pxToRem(12),
                  textWrap: 'nowrap'
                }}>
                {user?.roles[0].name}
              </Typography>
            </Grid>
          </Grid>
        </AccountStyle>
      </div>
    </Stack>
  );
};

AccountSettings.propTypes = {
  isCollapse: PropTypes.bool,
  setIsSwitchAccountDialogOpen: PropTypes.func
};
