import axios from 'axios';

import { documentVersionEnum, download, downloadFile, getApiCoreUrl, upload } from 'helpers';

const uploadDocument = (file, productId) => {
  return upload(file, `${getApiCoreUrl()}/api/v1/Products/${productId}/upload`);
};

const downloadDocument = documentId => {
  const url = `${getApiCoreUrl()}/api/v1/Files/${documentId}`;
  return axios.post(url).then(res => {
    return download(res.data);
  });
};

const downloadDocx = (productId, productName, documentVersion) => {
  const url = `${getApiCoreUrl()}/api/v1/Files/download/${
    documentVersion === documentVersionEnum.SIMPLIFIED_VERSION ? 'simplifiedVersion' : 'sections'
  }/${productId}`;
  return axios.get(url).then(() => {
    return downloadFile(axios, url, productName, '.docx');
  });
};

export const fileService = {
  downloadDocx,
  downloadDocument,
  uploadDocument
};
