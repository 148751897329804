import React, { useEffect, useRef } from 'react';

import lottie from 'lottie-web';
import PropTypes from 'prop-types';

import animationData from 'resources/animations/amplify-loader.json';

export const LoadingAnimation = ({ height, width, marginRight }) => {
  const loaderContainerRef = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: loaderContainerRef?.current,
      loop: true,
      autoplay: true,
      animationData: animationData,
      renderer: 'svg'
    });
  }, []);

  return (
    <div
      ref={loaderContainerRef}
      style={{ width: width, height: height, marginRight: marginRight, display: 'flex' }}
    />
  );
};

LoadingAnimation.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  marginRight: PropTypes.string
};
