import { format, lastDayOfMonth, intervalToDuration, subDays } from 'date-fns';

import { dateRangeValues, dateUnit } from 'helpers';

const getCustomDateFormat = groupedFilters => {
  const dateDifference = intervalToDuration({
    start: new Date(groupedFilters[0]),
    end: new Date(groupedFilters[1] || format(new Date(), 'yyyy-MM-dd'))
  });
  if (dateDifference.years === 0 && dateDifference.months === 0 && dateDifference.days < 7) {
    return 'eee';
  } else if (dateDifference.years >= 5) {
    return 'yyyy';
  } else if (
    dateDifference.years > 1 ||
    (dateDifference.years === 1 && dateDifference.months > 0)
  ) {
    return 'MMM yyyy';
  } else if (
    dateDifference.months >= 2 ||
    (dateDifference.years === 1 && dateDifference.months === 0)
  ) {
    return 'MMM';
  } else if (dateDifference.months <= 2 || dateDifference.days > 7) {
    return 'MMM dd';
  }
};

const isFirstOrLastElement = (index, dates) => {
  return index === 0 || index === dates.length - 1;
};

const startAndEndDates = dates => {
  const endDate = new Date(dates[dates.length - 1]);
  const startDate = new Date(dates[0]);
  return {
    startDate: startDate,
    endDate: endDate,
    lastDayOfMonthStartDate: format(lastDayOfMonth(startDate), 'yyyy-MM-dd'),
    startDateFormat: format(startDate, 'yyyy-MM-dd'),
    lastDayOfMonthEndDate: format(lastDayOfMonth(endDate), 'yyyy-MM-dd'),
    endDateFormat: format(endDate, 'yyyy-MM-dd')
  };
};

export const checkTimeInterval = (dates, groupedFilters, isColumnChart = false) => {
  const { startDate, endDate, lastDayOfMonthStartDate, lastDayOfMonthEndDate } =
    startAndEndDates(dates);
  let dateFormat = '';
  switch (groupedFilters.id) {
    case dateRangeValues.TODAY:
      dateFormat = 'eeee';
      break;
    case dateRangeValues.SEVEN_DAYS:
      dateFormat = 'eee';
      break;
    case dateRangeValues.YESTERDAY:
      dateFormat = 'eeee';
      break;
    case dateRangeValues.THIRTY_DAYS:
      dateFormat = 'MMM dd';
      break;
    case dateRangeValues.CUSTOM:
      dateFormat = groupedFilters.date ? getCustomDateFormat(groupedFilters.date) : 'MMM';
      break;
    case dateRangeValues.SIX_MONTHS:
    case dateRangeValues.TWELVE_MONTHS:
    case dateRangeValues.THREE_MONTHS:
      dateFormat = 'MMM';
      break;
    default:
      dateFormat = getCustomDateFormat(dates);
  }

  if (isColumnChart) {
    return dates.map(d => format(new Date(d), dateFormat));
  } else if (groupedFilters.id === dateRangeValues.CUSTOM && groupedFilters.date) {
    return handleCustomDateRange(dateFormat, dates, groupedFilters);
  } else if (
    [
      dateRangeValues.TODAY,
      dateRangeValues.YESTERDAY,
      dateRangeValues.THIRTY_DAYS,
      dateRangeValues.SEVEN_DAYS
    ].includes(groupedFilters.id)
  ) {
    return dates.map(d => format(new Date(d), dateFormat));
  } else if (groupedFilters.id === dateRangeValues.ALL_TIME) {
    return handleCustomDateRange(dateFormat, dates, groupedFilters);
  } else {
    return removeStartAndEndDate(
      startDate,
      endDate,
      lastDayOfMonthStartDate,
      lastDayOfMonthEndDate,
      dateFormat,
      dates
    );
  }
};

const removeStartAndEndDate = (
  startDate,
  endDate,
  lastDayOfMonthStartDate,
  lastDayOfMonthEndDate,
  dateFormat,
  dates
) => {
  return checkDatesAndLastDatesEquality(
    dates,
    dateFormat,
    format(startDate, 'yyyy-MM-dd'),
    lastDayOfMonthStartDate,
    format(endDate, 'yyyy-MM-dd'),
    lastDayOfMonthEndDate
  );
};

const checkDatesAndLastDatesEquality = (
  dates,
  dateFormat,
  startDate,
  lastDayOfMonthStartDate,
  endDate,
  lastDayOfMonthEndDate
) => {
  if (startDate !== lastDayOfMonthStartDate && endDate === lastDayOfMonthEndDate) {
    return dates.map((d, index) => (index === 0 ? '' : format(new Date(d), dateFormat)));
  } else if (endDate !== lastDayOfMonthEndDate && startDate !== lastDayOfMonthStartDate) {
    return dates.map((d, index) =>
      isFirstOrLastElement(index, dates) ? '' : format(new Date(d), dateFormat)
    );
  } else if (endDate !== lastDayOfMonthEndDate && startDate === lastDayOfMonthStartDate) {
    return dates.map((d, index) =>
      index === dates.length - 1 ? '' : format(new Date(d), dateFormat)
    );
  }

  return dates.map(d => format(new Date(d), dateFormat));
};

const checkIfDateIsEndOfPeriod = (date, month, lastDateOfMonth, period) => {
  return date !== lastDateOfMonth || (date === lastDateOfMonth && (month + 1) % period !== 0);
};

const checkTimePeriod = (startDateNotEndOfPeriod, endDateNotEndOfPeriod, dateFormat, dates) => {
  if (startDateNotEndOfPeriod && !endDateNotEndOfPeriod) {
    return dates.map((d, index) => (index === 0 ? '' : format(new Date(d), dateFormat)));
  } else if (startDateNotEndOfPeriod && endDateNotEndOfPeriod) {
    return dates.map((d, index) =>
      isFirstOrLastElement(index, dates) ? '' : format(new Date(d), dateFormat)
    );
  } else if (!startDateNotEndOfPeriod && endDateNotEndOfPeriod) {
    return dates.map((d, index) =>
      index === dates.length - 1 ? '' : format(new Date(d), dateFormat)
    );
  }
  return dates.map(d => format(new Date(d), dateFormat));
};

const handleCustomDateRange = (dateFormat, dates, groupedFilters) => {
  const {
    startDate,
    endDate,
    lastDayOfMonthStartDate,
    startDateFormat,
    lastDayOfMonthEndDate,
    endDateFormat
  } = startAndEndDates(dates);
  const dateDifference = intervalToDuration({
    start: new Date(Array.isArray(groupedFilters.date) ? groupedFilters.date[0] : dates[0]),
    end: new Date(
      Array.isArray(groupedFilters.date) && groupedFilters.date[1]
        ? groupedFilters.date[1]
        : dates[dates.length - 1]
    )
  });

  if (dateDifference.years >= 5) {
    return removeStartAndEndDate(
      startDate,
      endDate,
      format(new Date(lastDayOfMonthStartDate.split('-')[0], 11, 31), 'yyyy-MM-dd'),
      format(new Date(lastDayOfMonthEndDate.split('-')[0], 11, 31), 'yyyy-MM-dd'),
      dateFormat,
      dates
    );
  } else if (dateDifference.years < 5 && dateDifference.years >= 2) {
    const startDateNotEndOfSemester = checkIfDateIsEndOfPeriod(
      startDateFormat,
      startDate.getMonth(),
      lastDayOfMonthStartDate,
      6
    );
    const endDateNotEndOfSemester = checkIfDateIsEndOfPeriod(
      endDateFormat,
      endDate.getMonth(),
      lastDayOfMonthEndDate,
      6
    );
    return checkTimePeriod(startDateNotEndOfSemester, endDateNotEndOfSemester, dateFormat, dates);
  } else if (
    dateDifference.years < 2 &&
    (dateDifference.years > 1 ||
      (dateDifference.years === 1 && (dateDifference.months > 0 || dateDifference.days > 0)))
  ) {
    const startDateNotEndOfQuartal = checkIfDateIsEndOfPeriod(
      startDateFormat,
      startDate.getMonth(),
      lastDayOfMonthStartDate,
      3
    );
    const endDateNotEndOfQuartal = checkIfDateIsEndOfPeriod(
      endDateFormat,
      endDate.getMonth(),
      lastDayOfMonthEndDate,
      3
    );
    return checkTimePeriod(startDateNotEndOfQuartal, endDateNotEndOfQuartal, dateFormat, dates);
  } else if (dateDifference.months >= 2 && dateDifference.years <= 1) {
    return removeStartAndEndDate(
      startDate,
      endDate,
      lastDayOfMonthStartDate,
      lastDayOfMonthEndDate,
      dateFormat,
      dates
    );
  } else if (dateDifference.days >= 7 && dateDifference.years === 0 && dateDifference.months < 2) {
    return getFormattedXAxisLabels(dates);
  }
  return dates.map(d => format(new Date(d), dateFormat));
};

export const checkIfWeeksShouldBeDisplayed = groupedFilters => {
  if (groupedFilters.id === dateRangeValues.CUSTOM) {
    const yesterday = subDays(new Date(), 1);
    const dateDifference = intervalToDuration({
      start: new Date(
        Array.isArray(groupedFilters.date) ? groupedFilters.date[0] : groupedFilters.date
      ),
      end: new Date(Array.isArray(groupedFilters.date) ? groupedFilters.date[1] : yesterday)
    });

    const isMonthSelected =
      dateDifference.years < 1 && dateDifference.months === 1 && dateDifference.days === 0;
    const isLessThanMonthSelected = dateDifference.months < 1 && dateDifference.days > 7;
    return isMonthSelected || isLessThanMonthSelected;
  }
  return false;
};

export const getFormattedXAxisLabels = dates => {
  return dates.map((date, index) => {
    const parsedDate = new Date(date);
    return parsedDate.getDay() === 1 || isFirstOrLastElement(index, dates)
      ? format(parsedDate, 'MMM dd')
      : '';
  });
};

export const customFilterConfig = selectedDateFilter => {
  return {
    filter: 'custom',
    id: dateRangeValues.CUSTOM,
    unit: dateUnit.CUSTOM,
    startDate:
      Array.isArray(selectedDateFilter.date) && selectedDateFilter.date[0]
        ? format(new Date(selectedDateFilter.date[0]), 'dd/MM/yyyy')
        : null,
    endDate:
      Array.isArray(selectedDateFilter.date) && selectedDateFilter.date[1]
        ? format(new Date(selectedDateFilter.date[1]), 'dd/MM/yyyy')
        : format(new Date().setDate(new Date().getDate()), 'dd/MM/yyyy')
  };
};

export const handleNavigation = (increment, navigationIndexes, perUserData, setIndexes) => {
  let startIndex = navigationIndexes.startValue + increment;
  let endIndex = navigationIndexes.endValue + increment;
  if (navigationIndexes.startValue + increment < 0) {
    const lessThenDefaultIncrement = navigationIndexes.endValue + increment;
    startIndex = navigationIndexes.startValue - lessThenDefaultIncrement;
    endIndex = navigationIndexes.endValue - lessThenDefaultIncrement;
  }
  if (navigationIndexes.endValue + increment > perUserData.length) {
    const lessThenDefaultIncrement = perUserData.length - navigationIndexes.endValue;
    startIndex = navigationIndexes.startValue + lessThenDefaultIncrement;
    endIndex = navigationIndexes.endValue + lessThenDefaultIncrement;
  }
  setIndexes({
    startValue: startIndex,
    endValue: endIndex
  });
};

export const getMaxChartValue = uniqueUserData => {
  // Because min value on y-axis is 0, we need to add another point in case all values are 0/null, so there won't be duplicate
  // 0 values on y-axis as max and min
  if (
    uniqueUserData?.length > 1 ||
    (uniqueUserData?.length === 1 && uniqueUserData[0] && uniqueUserData[0] !== 0)
  ) {
    return Math.max(...uniqueUserData);
  } else {
    return 5;
  }
};

export const getTicksAmount = uniqueUserData => {
  if (uniqueUserData.length > 4) {
    return 4;
  } else if (uniqueUserData.length > 1) {
    return uniqueUserData.length - 1;
  } else {
    return uniqueUserData.length;
  }
};

export const getRiskDataPerLevel = (riskData, level) => {
  return riskData.find(item => item.level === level);
};

export const getDateRangeForReport = (groupedFilters, selectedDateFilter) => {
  let dateRange;
  if (groupedFilters.id === dateRangeValues.CUSTOM) {
    dateRange = { startDate: selectedDateFilter.date[0], endDate: selectedDateFilter.date[1] };
  } else {
    dateRange = { startDate: groupedFilters.date, endDate: null };
  }

  return dateRange;
};
