import React, { createContext, useState, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const initialState = {
  collapseHover: false,
  onToggleCollapse: () => {
    // do nothing
  },
  onHoverEnter: () => {
    // do nothing
  },
  onHoverLeave: () => {
    // do nothing
  }
};

const CollapseDrawerContext = createContext(initialState);

const CollapseDrawerProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false
  });

  useEffect(() => {
    if (isMobile) {
      setCollapse({
        click: false,
        hover: false
      });
    }
  }, [isMobile]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleCollapseWhenInEditor = () => {
    setCollapse({ ...collapse, click: true });
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click && !collapse.hover,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
        onOpenEditorCollapse: handleCollapseWhenInEditor
      }}>
      {children}
    </CollapseDrawerContext.Provider>
  );
};

CollapseDrawerProvider.propTypes = {
  children: PropTypes.node
};

export { CollapseDrawerProvider, CollapseDrawerContext };
