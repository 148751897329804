import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  title: props => ({
    fontFamily: props.typography.fontFamilySecondaryBold,
    color: props.palette.gray.dark,
    '&:hover': { color: props.palette.gray.dark },
    fontSize: '30px'
  }),
  description: props => ({
    color: props.palette.gray.main,
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: '15px'
  }),
  textFieldStyle: {
    width: '340px',
    fontFamily: props => props.typography.fontFamilyPrimaryMedium
  },
  buttonStyle: props => ({
    fontFamily: props.typography.fontFamilySecondaryMedium,
    fontSize: props.typography.pxToRem(14),
    fontWeight: 'normal',
    color: props.palette.gray.white,
    backgroundColor: props.palette.secondary.main,
    width: '340px',
    '&:hover': { backgroundColor: props.palette.tertiary.main }
  }),
  buttonMargin: props => ({
    marginTop: '40px!important',
    [props.breakpoints.down('1920')]: {
      marginTop: '20px!important'
    }
  }),
  mfaStepText: props => ({
    fontFamily: props.typography.fontFamilyPrimaryMedium,
    fontSize: props.typography.pxToRem(15)
  })
});
