import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Icons, PrimaryButton, TextFieldStyle } from 'components';
import { loginService } from 'services';

import { AuthTitle } from './AuthTitle';

export const ForgotPasswordForm = ({ onSent, onGetEmail }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('required'))
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    await loginService.resetPassword(values.email).then(() => {
      onSent();
      onGetEmail(values.email);
    });
    setSubmitting(false);
  };

  return (
    <div style={{ marginTop: '25px' }}>
      <Stack direction='row' alignItems='center' sx={{ mb: 5, display: 'flex' }}>
        <Box sx={{ flexGrow: 1, width: '360px' }}>
          <AuthTitle page='forgotPassword' />
        </Box>
      </Stack>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={forgotPasswordSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting, errors, getFieldProps, touched }) => (
          <Form autoComplete='off' noValidate>
            <Stack spacing={3} sx={{ alignItems: 'center' }}>
              {errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}
              <TextFieldStyle
                sx={{
                  fontFamily: theme.typography.fontFamilyPrimaryMedium + '!important',
                  width: '360px'
                }}
                type='email'
                inputname={t('emailAddress')}
                InputProps={{
                  startAdornment: (
                    <div
                      style={{
                        marginRight: '20px',
                        borderRadius: 'initial',
                        border: 'transparent'
                      }}>
                      <Icons iconName={'envelopeIcon'} />
                    </div>
                  )
                }}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                placeholder={t('email')}
                helperText={touched.email && errors.email}
              />

              <PrimaryButton
                buttonText={'resetPassword'}
                loading={isSubmitting}
                width={'360px'}
                type={'submit'}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func
};
