import { useState } from 'react';

import { isEqual } from 'lodash';

export const useMemoizedState = initialValue => {
  const [state, _setState] = useState(initialValue);

  const setState = newState => {
    _setState(prev => {
      if (isEqual(newState, prev)) {
        return prev;
      } else {
        return newState;
      }
    });
  };

  return [state, setState];
};
