import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogActions, Dialog } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { DialogWarning, PrimaryButton } from 'components';
import { toastMessage } from 'helpers';
import { filterSetService } from 'services';

export const OverwriteFilterSet = ({ isOpen, setIsOpen, currentFilterSet, filterSetData }) => {
  const [t] = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleConfirm = () => {
    const filterData = JSON.stringify(filterSetData);
    filterSetService.updateFilterSet(currentFilterSet.id, filterData, '/filterState').then(() => {
      toastMessage(enqueueSnackbar, closeSnackbar, t('successFilterSetSave'), 'success');
    });

    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth={'md'}>
      <DialogWarning
        setIsOpen={setIsOpen}
        text={t('overwriteFilter', { filterSetName: currentFilterSet?.name })}
      />
      <DialogActions sx={{ p: 4, pt: 6, gap: '10px' }}>
        <PrimaryButton
          handleClick={handleConfirm}
          buttonText={t('save')}
          dataCy='saveFilterSetButton'
        />
      </DialogActions>
    </Dialog>
  );
};

OverwriteFilterSet.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  currentFilterSet: PropTypes.object,
  filterSetData: PropTypes.object
};
