import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons, TextFieldStyle } from 'components';

export const BasicUserForm = ({ touched, errors, getFieldProps }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  return (
    <Stack spacing={{ xs: 3, sm: 2 }}>
      <Stack direction='row' spacing={{ xs: 3, sm: 2 }}>
        <div style={{ flex: 0.5 }}>
          <Typography
            variant='body2'
            style={{
              color: theme.palette.gray.main,
              fontFamily: theme.typography.fontFamilyPrimaryRegular
            }}>
            {t('firstName')}
          </Typography>
          <TextFieldStyle
            fullWidth
            {...getFieldProps('firstName')}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            dataCy='firstNameInput'
          />
        </div>
        <div style={{ flex: 0.5 }}>
          <Typography
            variant='body2'
            style={{
              color: theme.palette.gray.main,
              fontFamily: theme.typography.fontFamilyPrimaryRegular
            }}>
            {t('lastName')}
          </Typography>
          <TextFieldStyle
            fullWidth
            {...getFieldProps('lastName')}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            dataCy='lastNameInput'
          />
        </div>
      </Stack>
      <Stack direction='row' spacing={{ xs: 3, sm: 2 }}>
        <div style={{ flex: 1 }}>
          <Typography
            variant='body2'
            sx={{
              color: theme.palette.gray.main,
              fontFamily: theme.typography.fontFamilyPrimaryRegular
            }}>
            {t('email')}
          </Typography>
          <TextFieldStyle
            fullWidth
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            dataCy='emailInput'
            InputProps={{
              endAdornment: Boolean(touched.email && errors.email) && (
                <InputAdornment position='end' sx={{ border: 'none !important' }}>
                  <Icons iconName={'exclamationTriangleIcon'} />
                </InputAdornment>
              )
            }}
          />
        </div>
      </Stack>
    </Stack>
  );
};

BasicUserForm.propTypes = {
  touched: PropTypes.object,
  errors: PropTypes.object,
  getFieldProps: PropTypes.func
};
