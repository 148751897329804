import axios from 'axios';

import { downloadPDFReport, getApiCoreUrl, formatFilters } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/ProductivityReporting`;

const getDocumentAnalytics = async filter => {
  const url = `${baseUrl}/documentAnalytics`;
  const obj = formatFilters(filter);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const getUserAnalytics = async filter => {
  const url = `${baseUrl}/userAnalytics`;
  const obj = formatFilters(filter);
  return axios.post(url, obj).then(res => {
    return res.data;
  });
};

const downloadProductivityReport = async (dateRange, productivityReportData) => {
  await downloadPDFReport(baseUrl, dateRange, productivityReportData, 'productivity-report.pdf');
};

export const productivityService = {
  getDocumentAnalytics,
  getUserAnalytics,
  downloadProductivityReport
};
