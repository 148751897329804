import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { DeleteDialog, Icons } from 'components';
import { toastMessage } from 'helpers';
import { filterSetService } from 'services';

import { SaveFilterSetDialog } from './SaveFilterSetDialog';

export const FilterSetsList = ({
  filterSets,
  isDisabled,
  filterSetData,
  page,
  refetchFilterSets,
  applyFilterSet,
  selectedFilterSet,
  setSelectedFilterSet,
  resetAllFilters,
  setSelectedFilterSetId
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isSaveFilterSetDialogOpen, setIsSaveFilterSetDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRenameInProgress, setIsRenameInProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const appliedFilters = JSON.parse(localStorage.getItem('filtersAndTableSorting'));
  const appliedFilterSet = appliedFilters?.selectedFilterSetId;
  const isFilterSetApplied = filterSetId => {
    return (
      (appliedFilterSet === filterSetId && selectedFilterSet === 0) ||
      (selectedFilterSet !== 0 && selectedFilterSet === filterSetId)
    );
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    refetchFilterSets();
  };

  const handleDelete = id => {
    filterSetService.deleteFilterSet(id).then(() => {
      toastMessage(enqueueSnackbar, closeSnackbar, t('successDeleteFilterSet'), 'success');
      setIsDeleteDialogOpen(false);
      refetchFilterSets();
    });

    setSelectedFilterSet(0);
    resetAllFilters();
  };

  return (
    <div>
      <IconButton
        disableRipple
        onClick={handleClick}
        sx={{ width: '20px', height: '20px' }}
        data-cy='showFilterSetsIconButton'>
        {open ? (
          <ExpandLessRoundedIcon fontSize={'medium'} sx={{ color: theme.palette.primary.main }} />
        ) : (
          <ExpandMoreRoundedIcon fontSize={'medium'} sx={{ color: theme.palette.primary.main }} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: { width: '240px', boxShadow: `0px 0px 20px 0px ${theme.palette.gray.light}` }
        }}
        MenuListProps={{
          sx: {
            padding: '0px'
          }
        }}>
        <MenuItem
          data-cy='saveAsMenuItem'
          disabled={isDisabled}
          disableRipple
          onClick={() => {
            setSelectedFilterSet(0);
            setIsSaveFilterSetDialogOpen(true);
          }}
          sx={{
            borderBottom: `1px solid ${theme.palette.gray.light}`,
            height: '40px',
            '&:hover': {
              bgcolor: theme.palette.gray.lightest
            }
          }}>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamilyPrimaryRegular,
              color: theme.palette.primary.main,
              fontSize: theme.typography.pxToRem(16)
            }}>
            {t('saveAs')}
          </Typography>
        </MenuItem>
        <Typography
          sx={{
            padding: '15px',
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            color: theme.palette.gray.main,
            fontSize: theme.typography.pxToRem(12),
            height: '40px'
          }}>
          {t('savedFilterSets')}
        </Typography>
        {filterSets?.length > 0 ? (
          filterSets.map(filterSet => {
            return (
              <MenuItem
                data-cy={`filterSet${filterSet.id}-menuItem`}
                disableRipple
                key={filterSet.id}
                selected={isFilterSetApplied(filterSet.id)}
                onClick={() => {
                  setSelectedFilterSet(filterSet.id);
                  if (setSelectedFilterSetId) {
                    setSelectedFilterSetId(filterSet.id);
                  }
                  applyFilterSet(filterSet.id);
                }}
                sx={{
                  height: '40px',
                  justifyContent: 'space-between',
                  '&:hover': {
                    bgcolor: theme.palette.gray.lightest
                  },
                  '&.Mui-selected': {
                    bgcolor: theme.palette.gray.lightest,
                    '&:hover': {
                      bgcolor: theme.palette.gray.lightest
                    }
                  }
                }}>
                <Tooltip title={filterSet.name} placement='bottom-start'>
                  <Typography
                    sx={{
                      fontFamily: theme.typography.fontFamilyPrimaryRegular,
                      color: theme.palette.primary.main,
                      fontSize: theme.typography.pxToRem(16),
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {filterSet.name}
                  </Typography>
                </Tooltip>
                <div>
                  <IconButton
                    data-cy={`filterSet${filterSet.id}-rename`}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedFilterSet(filterSet.id);
                      setIsRenameInProgress(true);
                    }}>
                    <Icons iconName='edit' />
                  </IconButton>
                  <IconButton
                    data-cy={`filterSet${filterSet.id}-delete`}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedFilterSet(filterSet.id);
                      setIsDeleteDialogOpen(true);
                    }}>
                    <Icons iconName='delete' />
                  </IconButton>
                </div>
              </MenuItem>
            );
          })
        ) : (
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamilyPrimaryRegular,
              color: theme.palette.primary.main,
              fontSize: theme.typography.pxToRem(16),
              padding: '15px'
            }}>
            {t('noSavedFilterSets')}
          </Typography>
        )}
      </Menu>
      <SaveFilterSetDialog
        isDialogOpen={isSaveFilterSetDialogOpen}
        setIsDialogOpen={setIsSaveFilterSetDialogOpen}
        filterSetData={filterSetData}
        page={page}
        filterSets={filterSets}
        id={selectedFilterSet}
        refetchFilterSets={refetchFilterSets}
      />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        callback={() => handleDelete(selectedFilterSet)}
        text={'areYouSureFilterSet'}
      />
      {isRenameInProgress && (
        <SaveFilterSetDialog
          isDialogOpen={isRenameInProgress}
          setIsDialogOpen={setIsRenameInProgress}
          filterSetData={filterSetData}
          page={page}
          filterSets={filterSets}
          text={'renameFilterSet'}
          isRename
          id={selectedFilterSet}
          refetchFilterSets={refetchFilterSets}
        />
      )}
    </div>
  );
};

FilterSetsList.propTypes = {
  filterSets: PropTypes.array,
  isDisabled: PropTypes.bool,
  filterSetData: PropTypes.object,
  page: PropTypes.number,
  applyFilterSet: PropTypes.func,
  refetchFilterSets: PropTypes.func,
  selectedFilterSet: PropTypes.number,
  setSelectedFilterSet: PropTypes.func,
  resetAllFilters: PropTypes.func,
  setSelectedFilterSetId: PropTypes.func
};
