import React from 'react';

import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import { Icon } from '@iconify/react';
import { Box, GlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

const SnackbarStyles = () => {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1.2),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.gray.light,
            backgroundColor: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamilyPrimaryMedium,
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                color: theme.palette.gray.dark
              },
            '&.SnackbarItem-variantSuccess': {
              backgroundColor: theme.palette.success.light
            },
            '&.SnackbarItem-variantError': {
              backgroundColor: theme.palette.error.lighter
            },
            '&.SnackbarItem-variantWarning': {
              backgroundColor: theme.palette.warning.lighter
            },
            '&.SnackbarItem-variantInfo': {
              backgroundColor: theme.palette.info.lighter
            }
          },
          '& .SnackbarItem-message': {
            padding: '0 !important'
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 }
          }
        }
      }}
    />
  );
};

const SnackbarIcon = ({ icon, color }) => {
  return (
    <Box
      component='span'
      sx={{
        mr: 1.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`
      }}>
      <Icon icon={icon} width={30} height={30} />
    </Box>
  );
};

export const NotistackProvider = ({ children }) => {
  return (
    <div>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        iconVariant={{
          success: <SnackbarIcon icon={checkmarkCircle2Fill} color='success' />,
          error: <SnackbarIcon icon={closeCircleFill} color='error' />,
          warning: <SnackbarIcon icon={alertTriangleFill} color='warning' />,
          info: <SnackbarIcon icon={infoFill} color='info' />
        }}>
        {children}
      </SnackbarProvider>
    </div>
  );
};

SnackbarIcon.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.string
};

NotistackProvider.propTypes = {
  children: PropTypes.node
};
