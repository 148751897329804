import { Quill } from 'react-quill';

import Delta from 'quill-delta';

const Clipboard = Quill.import('modules/clipboard');

class PlainClipboard extends Clipboard {
  checkTableElements(op, elementsFromTable) {
    if (elementsFromTable.length > 0) {
      const tableAttributes = elementsFromTable.map(o => o.attributes['table-cell-line']);
      //Checking if elements are from same cell
      const elementsAreEqual = tableAttributes.every(el => el === tableAttributes[0]);
      if (elementsAreEqual) {
        delete op.attributes['table-cell-line'];
      }
    }
  }

  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const delta = new Delta().retain(range.index).delete(range.length);

    const text = e.clipboardData.getData('text');
    const htmlText = e.clipboardData.getData('text/html');
    if (htmlText.length) {
      const initialContent = this.quill.clipboard.convert(htmlText);
      initialContent.forEach(ops => delta.push(ops));
    } else {
      delta.insert(text);
    }

    let insertLength = 0;

    const enabledProps = [
      'bold',
      'italic',
      'underline',
      'heading',
      'align',
      'list',
      'indent',
      'link',
      'listItem',
      'table-cell-line',
      'table-cell',
      'table-row',
      'table'
    ];

    for (const op of delta.ops) {
      if (op.insert !== undefined) {
        const elementsFromTable = delta.ops.filter(o => o.attributes?.['table-cell-line']);
        this.checkTableElements(op, elementsFromTable);

        insertLength += op.insert.length;
        const attributes = op.attributes;
        for (const prop in attributes) {
          if (!enabledProps.includes(prop)) {
            delete attributes[prop];
          }
        }
      }
    }

    const index = insertLength + range.index;
    const selectionLength = 0;
    this.quill.updateContents(delta, 'user');
    this.quill.setSelection(index, selectionLength, 'user');
  }
}

Quill.register('modules/clipboard', PlainClipboard, true);
