import React from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ListHead, Scrollbar } from 'components';

export const CommonComplexWord = ({ intelligibilityData }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  const DOCUMENTS_TABLE_HEAD = [
    { id: 'id', width: '0%', align: 'left' },
    { id: 'word', label: t('word'), width: '60%', align: 'left' },
    { id: 'repetition', label: t('repetition'), width: '40%', align: 'center' }
  ];

  const isDocumentNotFound = intelligibilityData.length === 0;
  const commonComplexWords = intelligibilityData?.commonComplexWords || [];

  const renderListHead = () => {
    if (!isDocumentNotFound) {
      return (
        <ListHead
          headLabel={DOCUMENTS_TABLE_HEAD}
          rowCount={intelligibilityData.length}
          selectAllDisabled
          isDocumentsTable={false}
          dataCy={'selectAllCommonComplexWordsCheckbox'}
        />
      );
    }
  };

  return (
    <>
      <Typography
        variant='subtitle2'
        sx={{
          color: theme.palette.gray.dark,
          fontFamily: theme.typography.fontFamilyPrimarySemibold,
          my: '15px'
        }}>
        {t('commonComplexWords')}
      </Typography>
      <Typography
        variant='caption'
        sx={{
          color: theme.palette.gray.dark,
          fontFamily: theme.typography.fontFamilyPrimaryRegular
        }}>
        {t('commonComplexWordsBlurb')}
      </Typography>
      {/* 410 - height of elements surrounding table space */}
      <Scrollbar sx={{ maxHeight: window.innerHeight - 410 }}>
        <Table stickyHeader aria-label='sticky table'>
          {renderListHead()}
          <TableBody>
            {commonComplexWords.slice(0, 10).map((row, index) => {
              const { id, word, numberOfOccurrences } = row;
              return (
                <TableRow key={id} tabIndex={-1}>
                  <TableCell
                    padding='none'
                    sx={{
                      py: '5px',
                      pl: '0px!important',
                      borderBottom: `0.5px dashed ${theme.palette.gray.light}`
                    }}>
                    <Typography
                      variant='caption'
                      noWrap
                      sx={{
                        fontFamily: theme.typography.fontFamilyPrimaryMedium,
                        textDecoration: 'none',
                        color: theme.palette.gray.dark,
                        fontSize: theme.typography.pxToRem(11)
                      }}>
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: '5px',
                      py: '6px',
                      borderBottom: `0.5px dashed ${theme.palette.gray.light}`
                    }}
                    padding='none'
                    scope='row'>
                    <Typography
                      variant='caption'
                      noWrap
                      sx={{
                        fontFamily: theme.typography.fontFamilyPrimarySemibold,
                        textDecoration: 'none',
                        color: theme.palette.gray.dark,
                        fontWeight: 500,
                        overflow: 'visible'
                      }}>
                      {word}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      py: '5px',
                      borderBottom: `0.5px dashed ${theme.palette.gray.light}`,
                      '&.MuiTableCell-root': {
                        pr: '10px'
                      }
                    }}
                    align='center'
                    padding='none'>
                    <Typography
                      variant='caption'
                      noWrap
                      sx={{
                        fontFamily: theme.typography.fontFamilyPrimarySemibold,
                        textDecoration: 'none',
                        color: theme.palette.gray.dark,
                        fontWeight: 500
                      }}>
                      {numberOfOccurrences}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </>
  );
};

CommonComplexWord.propTypes = {
  intelligibilityData: PropTypes.object
};
