import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';

export const TableMenuItem = ({
  iconName,
  menuItemName,
  menuItemHandler,
  menuItemClickHandler,
  classes,
  oldEditor = true
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  return (
    <MenuItem
      data-testid={menuItemName}
      className={classes.menuItem}
      onMouseEnter={menuItemHandler}
      onClick={menuItemClickHandler}>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Grid sx={{ display: 'flex' }} item xs={2}>
          <ListItemIcon>
            {oldEditor ? (
              <Icons iconName={iconName} />
            ) : (
              <FontAwesomeIcon icon={iconName} style={{ color: theme.palette.gray.dark }} />
            )}
          </ListItemIcon>
        </Grid>
        <Grid sx={{ display: 'flex' }} item xs={10}>
          <ListItemText
            primary={t(menuItemName)}
            primaryTypographyProps={{
              variant: 'body2',
              fontFamily: theme.typography.fontFamilyPrimarySmall
            }}
          />
        </Grid>
      </Grid>
    </MenuItem>
  );
};

TableMenuItem.propTypes = {
  iconName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  menuItemName: PropTypes.string,
  menuItemHandler: PropTypes.func,
  menuItemClickHandler: PropTypes.func,
  classes: PropTypes.object,
  oldEditor: PropTypes.bool
};
