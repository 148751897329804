import ReactQuill from 'react-quill';

const Parchment = ReactQuill.Quill.import('parchment');

export class CommentAttribute extends Parchment.Attributor.Class {
  constructor(attrName = 'comment', keyName = 'comment') {
    super(attrName, keyName, { scope: Parchment.Scope.INLINE_ATTRIBUTE });
  }

  // add id to the comment class attributor
  add(node, value) {
    if (!this.canAdd(node, value)) {
      return false;
    }
    const ids = Array.isArray(value) ? value : [value];
    const formattedIds = ids.map(id => {
      if (id.includes('comment-')) {
        return id.replace('comment-', '');
      } else {
        node.className = '';
        return id;
      }
    });
    formattedIds.forEach(id => {
      node.classList.add(`${this.keyName}-${id}`);
    });
    return true;
  }

  //passes array of ids to quill internals
  value(node) {
    const prefix = `${this.keyName}-`;
    if (!node.classList.length) {
      return;
    }
    const list = Array.from(node.classList)
      .filter(listItem => listItem.startsWith(prefix))
      .map(listItem => listItem.slice(prefix.length));
    return list.length > 0 ? list : null;
  }

  // remove comment class from class attribute
  remove(node) {
    if (
      node.classList.length === 1 ||
      (node.classList.length === 2 && node.classList.contains('comment'))
    ) {
      node.removeAttribute('class');
    }
  }
}

ReactQuill.Quill.register('formats/comment', new CommentAttribute());
