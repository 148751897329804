import React, { forwardRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { mixpanel } from 'helpers';

export const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const pageName = pathname.substring(1);
    const pageNameCapitalized = pageName[0].toUpperCase() + pageName.substring(1);
    mixpanel.track('View page', {
      'Page name': pageNameCapitalized
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box ref={ref} {...other} sx={{ boxShadow: 0, height: '100%' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.displayName = 'Page';

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};
