import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { LoadingAnimation } from 'components';

export const FetchingReplacementsLoader = ({ margin, height, width, classes }) => {
  const [t] = useTranslation('common');

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', m: margin }}>
        <LoadingAnimation height={height} width={width} />
      </Box>
      <Typography className={classes.fetchingSuggestions}>{t('fetchingSuggestions')}</Typography>
    </>
  );
};

FetchingReplacementsLoader.propTypes = {
  margin: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  classes: PropTypes.object
};
