import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import { ClearField, Icons, SearchStyle, CustomOutlinedInput } from 'components';

export const SearchWrapper = ({
  search,
  searchValue,
  setSearch,
  setSearchValue,
  handleSearch,
  component,
  style,
  onKeyDownHandler,
  placeholder,
  dataCy,
  handleClick
}) => {
  const [t] = useTranslation('common');

  const Component = component === 'search' ? SearchStyle : CustomOutlinedInput;

  return (
    <Component
      onKeyDown={onKeyDownHandler}
      data-cy={dataCy}
      sx={style}
      value={searchValue}
      onChange={e => {
        e.preventDefault();
        setSearchValue(e.target.value);
        handleSearch(e, setSearch);
      }}
      placeholder={t(placeholder)}
      onClick={handleClick}
      startAdornment={
        <InputAdornment position='start'>
          <Icons iconName={'searchIcon'} />
        </InputAdornment>
      }
      endAdornment={
        search !== '' && (
          <InputAdornment position='end'>
            <ClearField
              handleChange={() => {
                setSearchValue('');
                setSearch('');
              }}
            />
          </InputAdornment>
        )
      }
    />
  );
};

SearchWrapper.propTypes = {
  search: PropTypes.string,
  searchValue: PropTypes.string,
  setSearch: PropTypes.func,
  setSearchValue: PropTypes.func,
  handleSearch: PropTypes.func,
  component: PropTypes.string,
  style: PropTypes.object,
  onKeyDownHandler: PropTypes.func,
  placeholder: PropTypes.string,
  dataCy: PropTypes.string,
  handleClick: PropTypes.func
};
