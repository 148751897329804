import axios from 'axios';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Reporting`;

const reloadReporting = async () => await axios.get(baseUrl);

const clearReporting = async () => await axios.delete(baseUrl);

export const reportingService = {
  clearReporting,
  reloadReporting
};
