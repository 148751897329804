import React, { useContext } from 'react';

import { LoadingScreen } from 'components';
import { UserContext, ResponseProvider } from 'providers';
import { Router } from 'routes';
import { GlobalStyles } from 'theme';

import { ProgressBarStyle } from './ProgressBarStyle';
import { ThemePrimaryColor } from './ThemePrimaryColor';

export const App = () => {
  const { initialized } = useContext(UserContext);

  if (!initialized) {
    return (
      <LoadingScreen
        sx={{
          top: 0,
          left: 0,
          width: 1,
          zIndex: 9999,
          minHeight: '100vh'
        }}
      />
    );
  }

  return (
    <ThemePrimaryColor>
      <ResponseProvider>
        <ProgressBarStyle />
        <GlobalStyles />
        <Router />
      </ResponseProvider>
    </ThemePrimaryColor>
  );
};
