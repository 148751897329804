import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { LoadingAnimation } from 'components';

export const SimplificationLoader = ({ height, my, fontSize, flexDirection = 'row' }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const isRowDirection = flexDirection === 'row';

  return (
    <Box
      data-testid='simplificationLoader'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: my,
        height: height,
        flexDirection: flexDirection,
        gap: !isRowDirection && '10px'
      }}>
      <LoadingAnimation height={30} width={30} />
      <Typography
        data-testid='fetchingSuggestions'
        sx={{
          ml: isRowDirection ? 2 : 0,
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          fontSize: theme.typography.pxToRem(fontSize)
        }}>
        {t('fetchingSuggestions')}
      </Typography>
    </Box>
  );
};

SimplificationLoader.propTypes = {
  height: PropTypes.number,
  my: PropTypes.number,
  fontSize: PropTypes.number,
  flexDirection: PropTypes.string
};
