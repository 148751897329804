import React from 'react';

import { MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { SearchWrapper } from 'components';

import { useStyles } from './LabelSelection.css.js';

export const SearchMenuItem = ({
  search,
  setSearch,
  searchValue,
  setSearchValue,
  handleSearch,
  component,
  style,
  additionalMenuClass = '',
  onKeyDownHandler,
  placeholder
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MenuItem
      disableRipple
      onClick={e => e.stopPropagation()}
      className={clsx(classes.menuItem, additionalMenuClass)}>
      <SearchWrapper
        search={search}
        searchValue={searchValue}
        setSearch={setSearch}
        setSearchValue={setSearchValue}
        handleSearch={handleSearch}
        component={component}
        style={style}
        onKeyDownHandler={onKeyDownHandler}
        placeholder={placeholder}
      />
    </MenuItem>
  );
};

SearchMenuItem.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  handleSearch: PropTypes.func,
  component: PropTypes.string,
  style: PropTypes.object,
  additionalMenuClass: PropTypes.string,
  onKeyDownHandler: PropTypes.func,
  placeholder: PropTypes.string
};
