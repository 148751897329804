import React, { useContext } from 'react';
import ReactQuill from 'react-quill';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';
import { checkFeatureFlag, featureFlagsEnum, getParentElementWithTag } from 'helpers';
import { UserContext, WebSocketConnectionContext } from 'providers';

import { useStyles } from './CustomInlineToolbar.css.js';
import { TableMenu } from './TableMenu/TableMenu';

// eslint-disable-next-line func-style
function undoChange() {
  this.quill.history.undo();
}

// eslint-disable-next-line func-style
function redoChange() {
  this.quill.history.redo();
}

//formatting applied on every list item except on those where formatting of numbering is intended
// eslint-disable-next-line func-style
function defaultListItemFormatting(obj, value) {
  // Define a new list id as an incremented max id value from existing lists.
  const items = Array.from(document.getElementsByTagName('list-item'));
  const id = items.length
    ? Math.max(...items.map(li => parseInt(li.getAttribute('listId')))) + 1
    : 1;

  // 'value' can be 'bullet', 'decimal', or false, if selected paragraphs are already list items.
  if (value) {
    obj.quill.format('listItem', `{ "id": "${id}", "type": "${value}"}`, 'user');
  } else {
    obj.quill.format('listItem', false, 'user');
  }
}

//formatting applied on all text except on list numbering
const defaultBoldFormatting = (obj, value, isBoldActive) => {
  // applied styling (bold/italic) for text which is not of type numbered list
  if (value) {
    obj.quill.format(isBoldActive ? 'bold' : 'italic', true, 'user');
  } else {
    obj.quill.format(isBoldActive ? 'bold' : 'italic', false, 'user');
  }
};

// // check if one whole list item is selected
const checkIfWholeListItemIsSelected = (selections, text, selectedListItems) => {
  // array of selected list items text lengths
  const selectedListItemsTextLength = text.map(item => {
    return item.cache.length;
  });
  // sum of new lines between selected list items
  const sumOfNewLinesBetweenListItems = selectedListItemsTextLength.length - 1;
  // sum of selected list items text lengths including new lines
  const selectedListItemsTextLengthSum =
    selectedListItemsTextLength.reduce((partialSum, a) => partialSum + a, 0) +
    sumOfNewLinesBetweenListItems;
  // check if one or more whole numbered list item is selected
  return (
    (selections.length === selectedListItemsTextLengthSum - selectedListItemsTextLength.length ||
      selections.length === selectedListItemsTextLengthSum) &&
    selectedListItems.type !== 'bullet'
  );
};

// styling applied to the list numbering
const findSelectedListItemAndApplyStyling = (
  elementList,
  selectedListItems,
  value,
  isBoldActive
) => {
  selectedListItems.forEach(item => {
    for (const li of elementList) {
      if (li.getAttribute('listItemId') === item.listItemId) {
        if (value) {
          li.classList.add(isBoldActive ? 'bold' : 'italic');
        } else {
          li.classList.remove(isBoldActive ? 'bold' : 'italic');
        }
      }
    }
  });
};

// return list item dom nodes
const findSelectedListItems = obj => {
  const listItemFormats = getFormat(obj).rangeFormats.listItem;
  const items = Array.isArray(listItemFormats) ? [...listItemFormats] : [listItemFormats];
  return items[0] ? [...new Set(items.map(li => JSON.parse(li)))] : {};
};

// apply styling if whole list item is selected or proceed to default behavior
const numberingListItemStyling = (obj, value, isBoldActive) => {
  const listFormats = getFormat(obj);
  const foundSelectedListItems = findSelectedListItems(obj);
  if (listFormats.rangeFormats.listItem) {
    if (
      checkIfWholeListItemIsSelected(
        listFormats.selections,
        listFormats.text,
        foundSelectedListItems
      )
    ) {
      findSelectedListItemAndApplyStyling(
        listFormats.elementList,
        foundSelectedListItems,
        value,
        isBoldActive
      );
    }
  }
  defaultBoldFormatting(obj, value, isBoldActive);
};

// eslint-disable-next-line func-style
function boldListItemNumberingHandler(value) {
  numberingListItemStyling(this, value, true);
}

// eslint-disable-next-line func-style
function italicListItemNumberingHandler(value) {
  numberingListItemStyling(this, value, false);
}

// apply indentation depending on toolbar button click
const indentationFormat = (obj, value) => {
  if (value === '+1' || value === '-1') {
    obj.quill.format('indent', value, 'user');
  }
};

// eslint-disable-next-line func-style
function getFormatFunc(obj, item, index, selectedListItemsTextLength) {
  return obj.quill.getFormat({
    index: item,
    length: selectedListItemsTextLength[index]
  });
}

// get indentation level of a list item
const getIndentation = indentationFormatOfEachListItem =>
  parseInt(
    Array.isArray(indentationFormatOfEachListItem.indent)
      ? indentationFormatOfEachListItem.indent[1]
      : indentationFormatOfEachListItem.indent || 0
  );

// set list item selection based on index position, get current indentation, and apply new one
const indentationDisassemble = (
  obj,
  indexPositionsOfEachMarkedListItem,
  selectedListItemsTextLength,
  value
) => {
  indexPositionsOfEachMarkedListItem.forEach((item, index) => {
    setSelection(obj, item, index, selectedListItemsTextLength);
    indentationFormat(obj, value);
  });
};

// eslint-disable-next-line func-style
function setSelection(obj, item, index, selectedListItemsTextLength) {
  obj.quill.setSelection({
    index: item,
    length: selectedListItemsTextLength[index]
  });
}

// check if right max indentation position is reached
// defined in checkIndentationLevel function with number 8
const checkIfMaxIndentationIsReached = (
  obj,
  indexPositionsOfEachMarkedListItem,
  selectedListItemsTextLength
) => {
  return indexPositionsOfEachMarkedListItem.map((item, index) => {
    setSelection(obj, item, index, selectedListItemsTextLength);
    return getIndentation(getFormatFunc(obj, item, index, selectedListItemsTextLength));
  });
};

// stop indentation progress if indentation limit is reached
const checkIndentationLevel = (value, indentationArr) => {
  return value === '+1' && indentationArr.includes(8);
};

// eslint-disable-next-line func-style
function indentationHandler(value) {
  const range = this.quill.getSelection();

  if (range.length === 0) {
    indentationFormat(this, value);
  }

  const text = this.quill.getLines(range.index, range.length);
  const selectedListItemsTextLength = text.map(item => {
    return item.cache.length - 1;
  });
  const indexPositionOfMarkedItems = range.index;
  let currentLength = 0;
  // array of starting index positions of all marked list items
  const indexPositionsOfEachMarkedListItem = selectedListItemsTextLength.map(
    (item, index, thisArray) => {
      if (index) {
        currentLength += thisArray[index - 1] + 1;
      }
      return indexPositionOfMarkedItems + currentLength;
    }
  );
  // array of indentation levels for every marked list item
  const indentationArr = checkIfMaxIndentationIsReached(
    this,
    indexPositionsOfEachMarkedListItem,
    selectedListItemsTextLength
  );

  // stop right indentation if level 8 is reached
  if (checkIndentationLevel(value, indentationArr)) {
    this.quill.setSelection(range);
    return;
  }

  indentationDisassemble(
    this,
    indexPositionsOfEachMarkedListItem,
    selectedListItemsTextLength,
    value
  );
  this.quill.setSelection(range);
}

// apply styling when going from all bolded bullet to all bolded numbered list
const findSelectedListItemAndApplyStylings = (elementList, selectedListItems, formatStyles) => {
  selectedListItems.forEach(item => {
    for (const li of elementList) {
      if (li.getAttribute('listItemId') === item.listItemId) {
        if (formatStyles) {
          li.classList.remove('bullet');
          li.classList.add('decimal');
          if ('bold' in formatStyles) {
            li.classList.add('bold');
          }
          if ('italic' in formatStyles) {
            li.classList.add('italic');
          }
        }
      }
    }
  });
};

// quill methods used on multiple places extracted in one function
const getFormat = obj => {
  const selection = obj.quill.getSelection();
  return {
    rangeFormats: obj.quill.getFormat(),
    selections: obj.quill.getSelection(),
    text: obj.quill.getLines(selection.index, selection.length),
    elementList: document.getElementsByTagName('list-item')
  };
};

const checkIfNumberingStylingIsIntended = (obj, value, formatStyles) => {
  const listFormats = getFormat(obj);
  return (
    listFormats.rangeFormats.listItem &&
    checkIfWholeListItemIsSelected(
      listFormats.selections,
      listFormats.text,
      findSelectedListItems(obj)
    ) &&
    value &&
    value !== 'bullet' &&
    Object.keys(formatStyles).length !== 0
  );
};

// check if whole list item is selected, if bold or italic is applied across those items
// and one of the list buttons is clicked
// eslint-disable-next-line func-style
function listItemHandler(obj, value) {
  const listFormats = getFormat(obj);
  const formatStyles = obj.quill.getFormat(listFormats.selections);
  delete formatStyles.listItem;
  if (checkIfNumberingStylingIsIntended(obj, value, formatStyles)) {
    findSelectedListItemAndApplyStylings(
      listFormats.elementList,
      findSelectedListItems(obj),
      formatStyles
    );
  } else {
    defaultListItemFormatting(obj, value);
  }
}

// eslint-disable-next-line func-style
function listHandler(value) {
  listItemHandler(this, value);
}

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
      'list-item': listHandler,
      bold: boldListItemNumberingHandler,
      italic: italicListItemNumberingHandler,
      indent: indentationHandler
    }
  },
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true
  }
};

// Quill Toolbar component
export const CustomInlineToolbar = ({
  quillEditor,
  isCommentingActive,
  handleCommentsButtonClick
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const selectionRange = quillEditor?.selection?.getNativeRange();
  const { history } = useContext(WebSocketConnectionContext);
  let isTableSelected = null;
  const { featureFlags } = useContext(UserContext);
  if (selectionRange) {
    const selectedNode = selectionRange.start.node;
    isTableSelected = getParentElementWithTag(selectedNode, 'TABLE');
  }

  const QuillIcons = ReactQuill.Quill.import('ui/icons');
  QuillIcons.bold = null;
  QuillIcons.italic = null;
  QuillIcons.underline = null;
  QuillIcons.link = null;
  QuillIcons.align[''] = null;
  QuillIcons.align['center'] = null;
  QuillIcons.align['right'] = null;
  QuillIcons.align['justify'] = null;
  QuillIcons.list['ordered'] = null;
  QuillIcons.list['bullet'] = null;
  QuillIcons.indent['+1'] = null;
  QuillIcons.indent['-1'] = null;
  QuillIcons.clean = null;

  const Link = ReactQuill.Quill.import('formats/link');
  Link.sanitize = function (url) {
    const protocol = url.slice(0, url.indexOf(':'));
    if (this.PROTOCOL_WHITELIST.indexOf(protocol) === -1) {
      url = 'https://' + url;
    }
    return url;
  };
  ReactQuill.Quill.register(Link, true);

  const commenting = checkFeatureFlag(featureFlags, featureFlagsEnum.COMMENTING);

  return (
    <div id='toolbar'>
      <span className='ql-formats'>
        <button disabled={!history.undo.length || isCommentingActive} className='ql-undo'>
          <div className={'button-image'}>
            <Icons iconName={'undo'} />
          </div>
        </button>
        <button disabled={!history.redo.length || isCommentingActive} className='ql-redo'>
          <div className={'button-image'}>
            <Icons iconName={'redo'} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div style={{ height: '24px', width: '1px' }}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span disabled={isTableSelected || isCommentingActive} className='ql-formats'>
        <select className='ql-heading ql-picker' defaultValue=''>
          <option value=''>Normal</option>
          <option value='0'>Heading 1</option>
          <option value='1'>Heading 2</option>
          <option value='2'>Heading 3</option>
        </select>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button disabled={isCommentingActive} className='ql-bold'>
          <div className={'button-image'}>
            <Icons iconName={'bold'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-italic'>
          <div className={'button-image'}>
            <Icons iconName={'italic'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-underline'>
          <div className={'button-image'}>
            <Icons iconName={'underline'} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button disabled={isCommentingActive} className='ql-link'>
          <div className={'button-image'}>
            <Icons iconName={'link'} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span disabled={isTableSelected} className='ql-formats'>
        <button disabled={isCommentingActive} className='ql-align' value=''>
          <div className={'button-image'}>
            <Icons iconName={'alignLeft'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-align' value='center'>
          <div className={'button-image'}>
            <Icons iconName={'alignCenter'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-align' value='right'>
          <div className={'button-image'}>
            <Icons iconName={'alignRight'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-align' value='justify'>
          <div className={'button-image'}>
            <Icons iconName={'alignJustify'} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span disabled={isTableSelected} className='ql-formats'>
        <button disabled={isCommentingActive} className='ql-list-item' value='bullet'>
          <div className={'button-image'}>
            <Icons iconName={'listUnordered'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-list-item' value='decimal'>
          <div className={'button-image'}>
            <Icons iconName={'listOrdered'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-indent' value='+1'>
          <div className={'button-image'}>
            <Icons iconName={'indentationRight'} />
          </div>
        </button>
        <button disabled={isCommentingActive} className='ql-indent' value='-1'>
          <div className={'button-image'}>
            <Icons iconName={'indentationLeft'} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span disabled={isCommentingActive} className='ql-formats'>
        <TableMenu editor={quillEditor} />
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      <span className='ql-formats'>
        <button disabled={isCommentingActive} className='ql-clean'>
          <div className={'button-image'}>
            <Icons iconName={'cleanText'} />
          </div>
        </button>
      </span>
      <span className='ql-formats'>
        <div className={classes.separator}>
          <Icons iconName={'separator'} />
        </div>
      </span>
      {commenting && (
        <>
          <span className='ql-formats'>
            <button
              onClick={handleCommentsButtonClick}
              style={{
                backgroundColor: isCommentingActive && theme.palette.gray.light,
                borderRadius: '20px'
              }}>
              <div className={'button-image'}>
                <Icons iconName={'addComment'} />
              </div>
            </button>
          </span>
          <span className='ql-formats'>
            <div className={classes.separator}>
              <Icons iconName={'separator'} />
            </div>
          </span>
        </>
      )}
    </div>
  );
};

CustomInlineToolbar.displayName = 'CustomInlineToolbar';

CustomInlineToolbar.propTypes = {
  quillEditor: PropTypes.object,
  isCommentingActive: PropTypes.bool,
  handleCommentsButtonClick: PropTypes.func
};
