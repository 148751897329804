import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components/Icons/Icons';
import { suggestionTargetTypesEnum } from 'helpers/enums';
import { handleRejectSuggestion } from 'helpers/suggestionMethods';
import { WebSocketConnectionContext } from 'providers';

export const SimplificationActions = ({
  suggestionText,
  simplificationId,
  handleApplySuggestion,
  handleDialogClose,
  sentenceSuggestions,
  setSentenceSuggestions,
  trackMixpanelData,
  selectedText
}) => {
  const {
    connection,
    id: documentId,
    previouslyClickedSection
  } = useContext(WebSocketConnectionContext);
  const [t] = useTranslation('common');
  const theme = useTheme();

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = suggestionText => {
    setShowTooltip(true);
    navigator?.clipboard?.writeText(suggestionText);

    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const setParagraphSuggestions = (suggestions, rejectedReplacement) => {
    const filteredArray = suggestions?.filter(item => item.text !== rejectedReplacement);
    setSentenceSuggestions(filteredArray);
  };

  const tooltipOffset = {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -10]
          }
        }
      ]
    }
  };

  const useStyles = makeStyles({
    simplificationActionsButton: {
      padding: 0,
      width: '32px',
      height: '32px'
    }
  });

  const classes = useStyles();

  return (
    <Stack direction='row' alignSelf={'flex-end'} sx={{ paddingBottom: '10px' }}>
      <Tooltip title={t('reject')} placement='top' slotProps={tooltipOffset}>
        <IconButton
          data-testid={`rejectSimplification-${simplificationId}`}
          className={classes.simplificationActionsButton}
          onClick={() => {
            handleRejectSuggestion(
              connection,
              documentId,
              suggestionText,
              selectedText || previouslyClickedSection.text,
              suggestionTargetTypesEnum.PARAGRAPH,
              sentenceSuggestions,
              setParagraphSuggestions
            );
            trackMixpanelData(true, suggestionText);
            sentenceSuggestions.length === 1 && handleDialogClose();
          }}>
          <Icons iconName={'flag'} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('copy')} placement='top-start' slotProps={tooltipOffset}>
        <div>
          <Tooltip
            data-testid={'simplificationCopiedTooltip'}
            title={t('copied')}
            open={showTooltip}
            placement='top'
            slotProps={tooltipOffset}>
            <IconButton
              data-testid={'copySimplification'}
              className={classes.simplificationActionsButton}
              sx={{
                ml: 1,
                mr: '15px'
              }}
              onClick={() => handleCopy(suggestionText)}>
              <Icons iconName={'copy'} fill={theme.palette.gray.main} width='16' height='20' />
            </IconButton>
          </Tooltip>
        </div>
      </Tooltip>
      <Tooltip title={t('apply')} placement='top' slotProps={tooltipOffset}>
        <IconButton
          data-testid={`applySimplification-${simplificationId}`}
          className={classes.simplificationActionsButton}
          onClick={() => handleApplySuggestion(simplificationId, suggestionText)}
          sx={{
            mr: '15px'
          }}>
          <Icons iconName={'checkedGreenRounded'} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

SimplificationActions.propTypes = {
  suggestionText: PropTypes.string,
  simplificationId: PropTypes.string,
  handleApplySuggestion: PropTypes.func,
  handleDialogClose: PropTypes.func,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  trackMixpanelData: PropTypes.func,
  selectedText: PropTypes.string
};
