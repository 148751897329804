import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  backgroundColor: theme.palette.gray.white,
  '& fieldset': {
    borderWidth: '0px !important'
  }
}));
