import React from 'react';

import { Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ReactComponent as GrayQuestionMark } from 'resources/images/grayQuestionMark.svg';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{
      popper: className
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    padding: '16px',
    whiteSpace: 'pre-line',
    boxShadow: `0px 0px 20px 0px ${'rgba(99, 115, 129, 0.2)'}`
  }
}));

export const CustomTooltip = ({
  tooltipContent,
  dataTestId,
  iconWidth = '20px',
  iconHeight = '20px',
  isErrorTooltip = false
}) => {
  const theme = useTheme();

  const iconColor = isErrorTooltip ? theme.palette.error.main : theme.palette.gray.main;

  const useStyles = makeStyles({
    icon: {
      '& text': {
        stroke: 'none',
        fill: iconColor
      },
      '& g': {
        stroke: iconColor,
        fill: 'none'
      },
      '&:hover text': {
        fill: '#fff',
        stroke: 'none'
      },
      '&:hover g': {
        fill: iconColor,
        stroke: iconColor
      }
    }
  });
  const classes = useStyles();

  return (
    <div data-testid={dataTestId}>
      <StyledTooltip
        title={
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamilyPrimaryMedium,
              fontSize: theme.typography.pxToRem(12)
            }}>
            {tooltipContent}
          </Typography>
        }>
        <Grid item sx={{ cursor: 'default', display: 'flex', alignItems: 'center' }}>
          <GrayQuestionMark className={classes.icon} width={iconWidth} height={iconHeight} />
        </Grid>
      </StyledTooltip>
    </div>
  );
};

CustomTooltip.propTypes = {
  tooltipContent: PropTypes.any,
  dataTestId: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  isErrorTooltip: PropTypes.bool
};

export default CustomTooltip;
