import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ClearField } from 'components';

export const ChipField = ({
  startAdornment,
  label,
  handleRemove,
  size,
  border,
  height,
  fontFamily,
  clearFieldDisabled,
  color,
  errorMessage,
  width,
  showErrorMessage
}) => {
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    fileBoxStyle: {
      borderRadius: '8px',
      display: 'flex',
      width: width || '100%',
      height: height,
      marginTop: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      border: border,
      borderColor: theme.palette.gray.light
    },
    fileListStyle: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '6px',
      width: 'inherit'
    }
  }));
  const classes = useStyles();

  return (
    <Box className={classes.fileBoxStyle}>
      <div className={classes.fileListStyle}>
        {startAdornment}
        <Box>
          <Stack sx={{ display: 'grid' }}>
            <Typography
              variant='body2'
              noWrap
              sx={{
                marginLeft: !startAdornment && '10px',
                fontFamily: fontFamily,
                color: color
              }}>
              {label} <br /> {size}
            </Typography>
          </Stack>
        </Box>
        {showErrorMessage && (
          <Typography
            sx={{
              marginLeft: 'auto',
              fontSize: '12px',
              color: color,
              fontFamily: fontFamily
            }}>
            {errorMessage}
          </Typography>
        )}
      </div>
      {!clearFieldDisabled && (
        <Box>
          <Stack>
            <ClearField handleChange={handleRemove} margin={10} />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

ChipField.propTypes = {
  startAdornment: PropTypes.element,
  label: PropTypes.string,
  handleRemove: PropTypes.func,
  size: PropTypes.string,
  border: PropTypes.string,
  height: PropTypes.number,
  fontFamily: PropTypes.string,
  clearFieldDisabled: PropTypes.bool,
  color: PropTypes.string,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
  showErrorMessage: PropTypes.bool
};
