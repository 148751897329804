import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { checkFeatureFlag, featureFlagsEnum } from 'helpers';
import { UserContext } from 'providers';

export const UploadFileDropzone = ({ files, onDrop, warning, isSingleFileUpload }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const { featureFlags } = useContext(UserContext);

  const uploadPdf = checkFeatureFlag(featureFlags, featureFlagsEnum.PDF_UPLOAD);
  const uploadHtml = checkFeatureFlag(featureFlags, featureFlagsEnum.HTML_UPLOAD);

  const useStyles = makeStyles({
    dropzoneStyle: {
      border: `2px dashed ${theme.palette.gray.dark}`,
      background: theme.palette.gray.lightest,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '16px',
      width: '100%',
      maxWidth: '700px',
      height: '100px',
      flexDirection: 'column',
      padding: '15px'
    }
  });
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      ...(uploadPdf && { 'application/pdf': ['.pdf'] }),
      ...(uploadHtml && { 'text/html': ['.html'] })
    },
    useFsAccessApi: false
  });

  return (
    <Box {...getRootProps()} className={classes.dropzoneStyle}>
      <input
        {...getInputProps({ multiple: !isSingleFileUpload })}
        disabled={isSingleFileUpload && !!files}
        data-cy='uploadDocumentsDropzone'
        data-testid='uploadDocumentsDropzone'
      />
      <FontAwesomeIcon
        icon={faCloudArrowUp}
        color={theme.palette.gray.dark}
        size='xl'
        width='30px'
      />
      <Typography
        variant='body2'
        color={theme.palette.gray.dark}
        fontFamily={theme.typography.fontFamilyPrimarySemibold}>
        {t('dropFiles')}
        <Typography
          variant='body2'
          component='span'
          sx={{
            color:
              isSingleFileUpload && files
                ? theme.palette.gray.main
                : theme.palette.longSentence.main,
            fontFamily: theme.typography.fontFamilyPrimarySemibold,
            textDecoration: 'underline',
            cursor: 'pointer'
          }}>
          {t('browse')}
        </Typography>
        {t('chooseFileFromMachine')}
      </Typography>
      <Typography
        fontFamily={theme.typography.fontFamilyPrimaryRegular}
        fontSize={theme.typography.pxToRem(12)}
        color={theme.palette.gray.main}>
        {warning}
      </Typography>
    </Box>
  );
};

UploadFileDropzone.propTypes = {
  files: PropTypes.array,
  onDrop: PropTypes.func,
  warning: PropTypes.string,
  isSingleFileUpload: PropTypes.bool
};
