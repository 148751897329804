const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900, // OLD 960
    lg: 1200, // OLD 1280
    xl: 1536, // OLD 1920,
    xlm: 1900 // scrollbar on safari takes some 15px, so extraLarge (1920px) breakpoint is never reached
  }
};

export default breakpoints;
