import React, { Suspense } from 'react';

import { LoadingScreen } from 'components';

// eslint-disable-next-line react/display-name
export const Loadable = Component => props => {
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            minHeight: '100vh'
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};
