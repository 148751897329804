import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ParagraphLevelSuggestionsDialogTitle, Scrollbar, SimplificationLoader } from 'components';
import { MESSAGE_TYPES } from 'helpers';
import { WebSocketConnectionContext } from 'providers';

import { useStyles } from './ParagraphLevelSuggestionDialog.css';
import { SimplificationItem } from '../SimplificationItem/SimplificationItem';

export const ParagraphLevelSuggestionDialog = ({
  isFetchingSuggestions,
  clickedIndicatorIndex,
  setClickedIndicatorIndex,
  sectionsDimensionData,
  resetTimers,
  sentenceSuggestions,
  setSentenceSuggestions,
  quillEditor,
  dialogRef,
  scrollRef
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  const [lastOpenDialogIndicatorIndex, setLastOpenDialogIndicatorIndex] = useState(-1);
  const isParagraphLevelSuggestionDialogOpen = clickedIndicatorIndex !== -1;

  const { connection, previouslyClickedSection, setIsParagraphDialogOpen } = useContext(
    WebSocketConnectionContext
  );
  const classes = useStyles(theme);

  useEffect(() => {
    isParagraphLevelSuggestionDialogOpen && setLastOpenDialogIndicatorIndex(clickedIndicatorIndex);
  }, [clickedIndicatorIndex]);

  const handleDialogClose = () => {
    if (previouslyClickedSection) {
      quillEditor?.formatText(
        previouslyClickedSection?.startIndex,
        previouslyClickedSection?.endIndex - previouslyClickedSection?.startIndex,
        'paragraph_suggestion',
        false
      );
    }

    setClickedIndicatorIndex(-1);
    setIsParagraphDialogOpen(false);
    connection.invoke(MESSAGE_TYPES.CLOSE_SIMPLIFICATIONS);
  };

  const simplificationContent = () => {
    return (
      <Scrollbar sx={{ maxHeight: '300px' }}>
        <DialogActions
          disableSpacing
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {sentenceSuggestions?.map((sentenceSuggestion, index) => {
            return (
              <SimplificationItem
                key={sentenceSuggestion.simplificationId}
                index={index}
                sentenceSuggestion={sentenceSuggestion}
                sentenceSuggestions={sentenceSuggestions}
                setSentenceSuggestions={setSentenceSuggestions}
                resetTimers={resetTimers}
                quillEditor={quillEditor}
                setClickedIndicatorIndex={setClickedIndicatorIndex}
                dialogRef={dialogRef}
                scrollRef={scrollRef}
              />
            );
          })}
        </DialogActions>
      </Scrollbar>
    );
  };

  const getDialogPosition = () => {
    return (
      lastOpenDialogIndicatorIndex !== -1 &&
      sectionsDimensionData[lastOpenDialogIndicatorIndex]?.offsetTop +
        sectionsDimensionData[lastOpenDialogIndicatorIndex]?.height
    );
  };

  return (
    <Dialog
      data-testid='paragraphLevelSuggestionsDialog'
      slotProps={{
        backdrop: {
          style: {
            opacity: 0
          }
        }
      }}
      sx={{
        position: 'absolute',
        '& .MuiDialog-container': {
          position: 'relative',
          top: getDialogPosition(),
          height: 'auto',
          width: 'auto'
        }
      }}
      container={() => document.getElementById('dialogParentContainer')}
      fullWidth
      maxWidth='xl'
      PaperProps={{ className: classes.paper }}
      open={isParagraphLevelSuggestionDialogOpen}
      onClick={e => e.stopPropagation()}
      onClose={handleDialogClose}>
      <ParagraphLevelSuggestionsDialogTitle
        handleDialogClose={handleDialogClose}
        title='simplifyText'
      />
      <DialogContent
        ref={dialogRef}
        sx={{
          '&.MuiDialogContent-root': {
            padding: 0
          }
        }}>
        {isFetchingSuggestions ? (
          <SimplificationLoader height={200} my={6} fontSize={16} />
        ) : (
          simplificationContent()
        )}
        {!isFetchingSuggestions && sentenceSuggestions?.length === 0 && (
          <Box
            data-testid='noSuggestionsBox'
            sx={{
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 6
            }}>
            <Typography
              data-testid='noSuggestionsText'
              variant='body3'
              sx={{ fontFamily: theme.typography.fontFamilyPrimaryRegular }}>
              {t('noParagraphSuggestions')}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

ParagraphLevelSuggestionDialog.propTypes = {
  clickedIndicatorIndex: PropTypes.number,
  setClickedIndicatorIndex: PropTypes.func,
  sectionsDimensionData: PropTypes.array,
  isFetchingSuggestions: PropTypes.bool,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  resetTimers: PropTypes.func,
  quillEditor: PropTypes.object,
  dialogRef: PropTypes.object,
  scrollRef: PropTypes.object
};
