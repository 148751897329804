import React from 'react';

import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { CommentsCounter } from 'components';
import { calculateIndicatorPosition, getCommentIdsWithoutPrefix } from 'helpers';

export const EditorCommentsSection = React.memo(
  ({
    allComments,
    sectionsInfo,
    sectionsDimensionData,
    onCommentIndicatorClick,
    selectedCommentTag,
    setSelectedCommentTag
  }) => {
    const useStyles = makeStyles({
      visibilityIcon: {
        minWidth: '2.813rem',
        height: '1.563rem',
        borderRadius: '0.813rem',
        marginRight: '1rem',
        position: 'absolute',
        right: '0%',
        textAlign: '-webkit-center',
        display: 'flex',
        justifyContent: 'center'
      }
    });
    const classes = useStyles();

    const handleCommentTagClick = (sectionId, index) => {
      setSelectedCommentTag(prevState => {
        return prevState === sectionId ? -1 : sectionId;
      });
      onCommentIndicatorClick(sectionsDimensionData[index].isComment);
    };

    const commentsAndRepliesCount = commentIds => {
      const idsWithoutPrefixes = getCommentIdsWithoutPrefix(commentIds);
      const repliesCount = idsWithoutPrefixes?.map(idWithoutPrefixes => {
        const foundComment = allComments?.find(comment => comment.id === idWithoutPrefixes);
        return foundComment?.replies.length;
      });
      const filteredCounter = repliesCount?.filter(value => typeof value === 'number');
      const reducedFilterCounter = filteredCounter?.reduce(
        (acc, currentValue) => acc + currentValue,
        commentIds.length
      );
      return reducedFilterCounter;
    };

    return (
      <div>
        {sectionsInfo?.map((sectionInfo, index) => {
          const clickedCommentTag = sectionInfo.id === selectedCommentTag;
          if (sectionInfo.isEmpty || sectionsDimensionData[index]?.isComment.length === 0) {
            return;
          }
          const count = commentsAndRepliesCount(sectionsDimensionData[index]?.isComment);
          return (
            <IconButton
              disableRipple
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              onClick={e => {
                e.stopPropagation();
                handleCommentTagClick(sectionInfo.id, index);
              }}
              key={sectionInfo.id}
              className={classes.visibilityIcon}
              style={{
                top: calculateIndicatorPosition(sectionsDimensionData, index, true),
                cursor: 'pointer',
                pointerEvents: 'auto'
              }}>
              <CommentsCounter count={count} isResolved={false} isSelected={clickedCommentTag} />
            </IconButton>
          );
        })}
      </div>
    );
  }
);

EditorCommentsSection.displayName = 'EditorCommentsSection';

EditorCommentsSection.propTypes = {
  allComments: PropTypes.array,
  sectionsInfo: PropTypes.array,
  sectionsDimensionData: PropTypes.array,
  onCommentIndicatorClick: PropTypes.func,
  selectedCommentTag: PropTypes.number,
  setSelectedCommentTag: PropTypes.func
};
