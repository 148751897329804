import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { Avatar, ListItemText, Menu } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { LightTooltip, ListItemStyle } from 'components';
import { checkPermission } from 'helpers';
import { UserContext } from 'providers';

import { sidebarConfig } from './SidebarConfig';

export const CollapsedMenu = ({
  icon,
  isSidebarCollapsed,
  listItemIconStyle,
  anchorEl,
  setAnchorEl,
  title
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const ref = useRef(null);
  const open = Boolean(anchorEl);
  const { user } = useContext(UserContext);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleClose = e => {
    setIsTooltipOpen(false);
    setAnchorEl(null);
    e.preventDefault();
  };

  const { pathname } = useLocation();

  const textStyling = title => {
    const isActiveRoot = pathname.includes(title);
    return {
      height: '40px',
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      fontWeight: isActiveRoot ? 500 : theme.typography.fontWeightRegular,
      color: theme.palette.primary.main,
      backgroundColor: isActiveRoot && theme.palette.gray.lightest,
      textWrap: 'nowrap',
      pl: '10px',
      '&:hover': {
        backgroundColor: theme.palette.gray.lightest
      }
    };
  };

  const submenuItems = sidebarConfig[1].items[0].submenuItems;

  const collapsedSubMenuItems = submenuItems.map(item => {
    return (
      checkPermission(user.permissions, item.permission) && (
        <ListItemStyle
          key={item.title}
          disableRipple
          component={RouterLink}
          to={item.path}
          sx={textStyling(item.title)}>
          <ListItemText disableTypography primary={item.title} />
        </ListItemStyle>
      )
    );
  });

  return (
    <>
      <LightTooltip
        title={!isSidebarCollapsed && t(title)}
        placement='right'
        open={isTooltipOpen && !anchorEl}
        onOpen={() => setIsTooltipOpen(true)}
        onClose={() => setIsTooltipOpen(false)}>
        <Avatar
          sx={listItemIconStyle}
          ref={ref}
          onClick={e => {
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }}>
          {icon}
        </Avatar>
      </LightTooltip>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClick={e => handleClose(e)}
        onClose={e => handleClose(e)}
        disableScrollLock
        PaperProps={{
          sx: {
            width: '115px',
            mt: -5,
            ml: isSidebarCollapsed ? 29 : 8,
            backgroundColor: theme.palette.gray.white,
            borderRadius: '5px'
          }
        }}
        MenuListProps={{
          sx: { padding: '0px' }
        }}>
        {collapsedSubMenuItems}
      </Menu>
    </>
  );
};

CollapsedMenu.propTypes = {
  icon: PropTypes.object,
  isSidebarCollapsed: PropTypes.bool,
  listItemIconStyle: PropTypes.object,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  title: PropTypes.string
};
