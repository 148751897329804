export const featureFlagsEnum = {
  COMMENTING: 'Commenting',
  DOCUMENT_MANAGEMENT_PINNED_HEADER: 'DocumentManagementPinnedHeader',
  PERSONAS_SCORING: 'PersonasScoring',
  UPLOAD_DOCUMENT_FROM_URL: 'UploadDocumentFromUrl',
  DOCUMENT_EDITOR: 'DocumentEditor',
  PDF_UPLOAD: 'UploadPdf',
  HTML_UPLOAD: 'UploadHtml'
};

export const checkFeatureFlag = (assignedFeatureFlags, neededFeatureFlags) => {
  if (
    !neededFeatureFlags ||
    !assignedFeatureFlags ||
    assignedFeatureFlags.length === 0 ||
    !assignedFeatureFlags[neededFeatureFlags]
  ) {
    return false;
  }

  return assignedFeatureFlags[neededFeatureFlags];
};
