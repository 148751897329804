import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { checkPermission } from 'helpers';
import { UserContext } from 'providers';

export const PermissionChecker = ({ children, permissions, allNeeded }) => {
  const { user } = useContext(UserContext);
  const hasPermission = checkPermission(user.permissions, permissions, allNeeded);

  return <>{hasPermission ? children : null}</>;
};

PermissionChecker.propTypes = {
  allNeeded: PropTypes.bool,
  children: PropTypes.any,
  permissions: PropTypes.any
};
