import { merge } from 'lodash';

import Button from './Button';
import Card from './Card';
import { Select } from './Select';
import Table from './Table';

export default function ComponentsOverrides(theme) {
  return merge(Button(theme), Card(theme), Table(theme), Select(theme));
}
