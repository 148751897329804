import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogTitle, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';

export const DialogWarning = ({ setIsOpen, text }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  const useStyles = makeStyles({
    closeIcon: {
      width: '20px',
      height: '20px',
      backgroundColor: theme.palette.gray.lighter,
      borderRadius: '50%',
      padding: '0.15rem',
      cursor: 'pointer',
      color: theme.palette.gray.main,
      marginRight: '12px'
    },
    dialogTitle: {
      color: theme.palette.gray.dark,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      fontSize: theme.typography.pxToRem(16),
      marginLeft: '-3px',
      width: '450px'
    },
    warningMessageBox: {
      backgroundColor: theme.palette.error.lighter,
      width: '460px',
      height: '40px',
      borderRadius: '8px',
      paddingLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center'
    },
    warningMessageText: {
      color: theme.palette.error.main,
      ml: '10px',
      fontSize: theme.typography.pxToRem(14),
      fontFamily: theme.typography.fontFamilyPrimaryRegular
    }
  });
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        sx={{ p: 2, pb: 1, width: '520px' }}>
        <DialogTitle className={classes.dialogTitle}>{text}</DialogTitle>
        <CloseIcon onClick={() => setIsOpen(false)} className={classes.closeIcon} />
      </Grid>
      <Box className={classes.warningMessageBox}>
        <Icons iconName={'deleteExclamationMark'} />
        <Typography className={classes.warningMessageText}>
          {t('irreversibleActionWarning')}
        </Typography>
      </Box>
    </>
  );
};

DialogWarning.propTypes = {
  setIsOpen: PropTypes.func,
  text: PropTypes.string
};
