import React from 'react';

import { Avatar, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

export const MAvatar = ({ colorIndex = -1, sx, src, children, opacity, hover, ...other }) => {
  const theme = useTheme();

  if (colorIndex === -1) {
    return (
      <Avatar src={src} sx={sx} {...other}>
        {children}
      </Avatar>
    );
  }

  return (
    <Avatar
      src={src}
      sx={{
        color: theme.palette.gray.white,
        backgroundColor: opacity
          ? theme.palette.lightAvatarColors[colorIndex]
          : theme.palette.avatarColors[colorIndex],
        '&:hover': { backgroundColor: hover && theme.palette.avatarColors[colorIndex] },
        ...sx
      }}
      {...other}>
      {children}
    </Avatar>
  );
};

MAvatar.propTypes = {
  colorIndex: PropTypes.number,
  children: PropTypes.node,
  sx: PropTypes.object,
  src: PropTypes.string,
  opacity: PropTypes.bool,
  hover: PropTypes.bool
};

MAvatar.displayName = 'MAvatar';
