import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  closeIcon: props => ({
    width: '25px',
    height: '25px',
    backgroundColor: 'white',
    cursor: 'pointer',
    color: props.palette.gray.medium
  }),
  buttonStyle: props => ({
    borderRadius: 0,
    fontSize: props.typography.pxToRem(16),
    fontFamily: props.typography.fontFamilyPrimarySemibold,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'initial'
    }
  }),
  headerStyle: props => ({
    color: props.palette.gray.dark,
    fontFamily: props.typography.fontFamilyPrimarySemibold,
    fontSize: props.typography.pxToRem(14)
  }),
  textStyle: props => ({
    color: props.palette.gray.main,
    fontFamily: props.typography.fontFamilyPrimaryRegular
  }),
  containerStyle: {
    display: 'flex',
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto'
  },
  boxTextStyle: props => ({
    color: props.palette.gray.dark,
    fontFamily: props.typography.fontFamilyPrimarySemibold,
    fontSize: props.typography.pxToRem(14)
  }),
  textPositionStyle: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
